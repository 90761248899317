import React from 'react';
import { Form, Input,message,Card,Result, Button,Tooltip,Select,Switch,Table,DatePicker,Spin,Modal,Collapse,Popconfirm,Popover,Divider } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { primarycolor } from '../primarycolor';

import { 
  DeleteOutlined,
  FundViewOutlined,
  LoadingOutlined,
  PlusCircleFilled,
  PrinterOutlined
  
} from '@ant-design/icons';
import uuid from 'react-uuid'
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import { number } from 'prop-types';

var CryptoJS = require("crypto-js");
var converter = require('number-to-words');
const { RangePicker } = DatePicker;


const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Panel } = Collapse;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;

var username=''
var token=''


class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    if(localStorage.getItem("username")){
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
   }else{
      username=''
   }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })
 

  }

  //clactulate totla of items
  calculateTotalofItems=()=>{
    var total=0
    JSON.parse(this.props.selected_voucher.items_list).map(
      (item)=>{
        total+=Number(item.amount)
      })

    return  total.toFixed(2);  

  }


  render() {
    return (
      <div style={{padding:20}}>
      <div style={{display:'flex', flexDirection:'',justifyContent: 'space-between', margin:3}}>
        
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly'}}>
        <img
            height="100"
            width="120"
            alt="Logo"
            src={this.state.companyprofile.company_logo}
          />

          <div style={{display:'flex',flexDirection:'column'}}>
            <h3 style={{ fontWeight: "bolder" }}>
              {this.state.companyprofile.system_name}
            </h3>
            <h6 style={{ fontWeight: "bolder" }}>
              Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
            </h6>
            <h5 style={{ fontWeight: "bolder" }}>
              Email: {this.state.companyprofile.company_email}
            </h5>

          </div>

        </div>
                      
          <h2
            style={{
              display: "flex",
              justifyContent: 'flex-end',
              alignItems: "center",
              fontWeight: "bolder",
            }}
          >
            PAY VOUCHER 
          </h2>
      </div>  

      <Divider></Divider>  

      <div style={{display:'flex', justifyContent: 'space-between', margin:3}}>
      <div>
      <h4 style={{fontWeight:'bolder'}}align='left'>{'VOUCHER NUMBER: '+this.props.selected_voucher.voucher_no}</h4>
      <h4 style={{fontWeight:'bolder'}}align='left'>{'DATE: '+this.props.selected_voucher.date}</h4>
      
      <h4 style={{fontWeight:'bolder'}}align='left'>{'PAY CHANNEL: '+this.props.selected_voucher.payment_channel}</h4>

      </div>
      </div>

      <reactstrp.Table bordered>
          <thead>
            <tr>
              <th>NO</th>
              <th>ITEM </th>
              <th>DESCRIPTION</th>
              <th>AMOUNT</th>

            </tr>
          </thead>
          <tbody>
          { JSON.parse(this.props.selected_voucher.items_list).map(
            (item)=>(
              <tr >
                <td>{item.item_no}</td>
                <td>{item.item_type}</td>
                <td>{item.item_name}</td>

                <td className="align-right">
                  {
                    <CurrencyFormat
                      value={Number(item.total)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                </td>

              </tr>
            ))}
                <tr >
                <td></td>
                <td></td>
                <td></td>

                <th>
                  <h2>
                  {<CurrencyFormat value={this.calculateTotalofItems()} displayType={'text'} thousandSeparator={true}/>}
                  </h2>
                  </th>
              </tr>


              <tr >
                <td>
                <h3 style={{fontWeight:'bolder'}}align='left'>{' RECEIVED BY: '+this.props.selected_voucher.received_by}</h3>
                <h3 style={{fontWeight:'bolder'}}align='left'>{' PAY TOTAL: '} <CurrencyFormat value={this.props.selected_voucher.totalAmount} displayType={'text'} thousandSeparator={true}/> </h3>
                <h3 style={{fontWeight:'bolder'}}align='left'>{' PAY TOTAL (IN WORDS): '} {converter.toWords(Number(this.props.selected_voucher.totalAmount))} </h3>

                <h3 style={{fontWeight:'bolder'}}align='left'> APPROVERS: 
                {JSON.parse(this.props.selected_voucher.approvers_list)
                .map((item)=>
                {
                  return (item.approver_name+" ,")
                
                }
                
                )
                }
                
                
                </h3>                        

                </td>


              </tr>
            
        </tbody>
        </reactstrp.Table>


        <br></br>
          <h3 style={{fontWeight:'bolder'}}>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
          <h3 style={{fontWeight:'bolder'}}>Powered by: www.pitech.co.ug</h3>   

      </div>

    );
  }
}


class VoucherCreateForm extends React.Component {

  state = {
    item_type:'Other' ,  
    item_name:'' ,  
    unit:'',
    amount:0,

    items_list:[],
    itemupdate:false,
    selectedupdateitem:{},
    requestsent:true,
    itemnumber:0,
    service_product:false,

    approvers_list:[],
    rider_id:'',
    approver_name:'',
    date:moment().format(dateFormat).toString(),
    selected_voucher:{},
    status_message:'',
    datasubmittedsuccessfully:false,
    cashflow_accounts_list:[],
    payment_channel:'',
    riders:[],
    selected_expense_ledger:'',
    companyprofile:{},
    guarantee_fees:0,

    dateone:moment().format(dateFormat).toString(),
    datetwo:moment().format(dateFormat).toString(),
    voucher_details:''
  


  }


  componentDidMount(){
    this.compon_loaded()
  }


  compon_loaded=()=>{
    this.setState({datarequested:true})

    if(localStorage.getItem("username")){
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
   }else{
      username=''
   }


   axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
   .then(res => {  
   this.setState({cashflow_accounts_list:res.data.cashflow_accounts_list!=null?JSON.parse(res.data.cashflow_accounts_list):[]})
   this.setState({requestsent:false})

   this.setState({
    companyprofile: res.data
    })

   })



   axios.get(serverconfig.backendserverurl+`/api/riders/`)
   .then(res => {
       this.setState({
           riders:res.data
       })
       this.setState({datarequested:false})
   })

   this.setState({datasubmittedsuccessfully:false})


  }




  //remove item
  removeItem=(id)=>{
    console.log(id)
    this.setState({ items_list:  [...this.state.items_list.filter(todo => todo.key
      !==id)]});
  }


    //clactulate totla of items
    calculateTotalofItems=()=>{
      var total=0
        this.state.items_list.map(
        (item)=>{
          total+=Number(item.amount)
        })
  
      return  total.toFixed(2);  
  
    }



 onBlur=()=> {
  console.log('blur');
}

 onFocus=()=>{
  console.log('focus');
}

onSearch=(val)=> {
  console.log('search:', val);
}


//handle data submission
handleDatasubmission=()=>{
  var period_range="From "+String(this.state.dateone)+" to "+String(this.state.datetwo)
  
  if (this.state.items_list.length===0){
    message.error("There are no items in the list")
  }else if (this.state.approvers_list.length===0){
    message.error("There are no approvers")
  }else if (this.state.rider_id===""){
    message.error("Rider missing")
  } else if (this.state.payment_channel==="") {
      message.error("Payment channel missing")
  } else{

    this.setState({requestsent:true})

    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('items_list', JSON.stringify(this.state.items_list));
    form_data.append('rider_id', this.state.rider_id);
    form_data.append('approvers_list', JSON.stringify(this.state.approvers_list));
    form_data.append('username', username);
    form_data.append('totalAmount', this.calculate_Total());
    form_data.append('payment_channel', this.state.payment_channel);

    form_data.append('period_range', period_range);
    form_data.append('dateone', this.state.dateone);
    form_data.append('datetwo', this.state.datetwo);
    form_data.append('voucher_details', this.state.voucher_details);

    axios.post(serverconfig.backendserverurl+'/customqueries/createpayment_voucher', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then(res =>{
      this.setState({requestsent:false})
      this.setState({status_message:res.data.message})
      console.log(res.data.v_diction)
      this.setState({selected_voucher:res.data.v_diction})

      this.setState({datasubmittedsuccessfully:true})
      this.setState({items_list:[]})

      this.compon_loaded()

      //refresh here
      this.props.onRefresh()

    })
  .catch(error => console.log(error))

  }

}



  //calculate total bill
  calculate_Total=()=>{
    var total =0
    this.state.items_list.map((item) => {
      total += Number(item.total);
    });

    return total;

  }


  render(){

    if(this.state.requestsent===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} size={200} />
        </div>
      );

    }else{

      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReportToPrint
            selected_voucher={this.state.selected_voucher} 
            date={moment().format(dateFormat).toString()}
            ref={el => (this.componentRef = el)} 
            /> 
            </div>

          <Card>
          <Result
          status="success"
          subTitle={this.state.status_message}
          extra={[

            <a>
            <ReactToPrint
              trigger={() =>                     
                <div style={{}}>
                  <Tooltip title="Click to print" placement="top">
                  <p ><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                  <h6 >Print Voucher</h6>
                  </Tooltip>
                </div>                          
                  }
                  content={() => this.componentRef}
                />  
                </a>,
            <Button  type='primary' 
            onClick={()=>{
            this.compon_loaded()
            //refresh here
            this.props.onRefresh()

            }}>Finish</Button>,
          ]}
          />

          </Card>
          </div>
        )

      }else{

      return (
          <div style={{overflowX:'auto'}}>
           
            <Form layout='inline'>
            <FormItem label="Select Receiving Rider">
                <Select 
                placeholder="Rider" 
                value={this.state.rider_id} 
                onChange={(val)=>{
                  this.setState({rider_id:val})
                
                }} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}    
                style={{width:400}}
                >
                {this.state.riders.map(
                (rder)=>(
                  <Option value={rder.id}>{rder.name} | {rder.phone}</Option>
                ))}
                </Select>
            </FormItem>

            <FormItem label="Date Range">
              <RangePicker onChange={(date, dateString) =>{
              this.setState({ dateone: dateString[0]});
              this.setState({ datetwo: dateString[1]});
            }} format={dateFormat}
             placeholder={['Start Date', 'End Date']}

            />
            </FormItem>


            <FormItem label="">
              <Button  type="primary" htmlType='button' 
                onClick={()=>{
  
                    let form_data = new FormData();
                    form_data.append("dateone", this.state.dateone);
                    form_data.append("datetwo", this.state.datetwo);
                    form_data.append("order_status", "Delivered");
                    form_data.append("rider_id", this.state.rider_id);
    
                    if (this.state.dateone === "" ||this.state.datetwo === "") {
                      message.error("Please dates are missing");
                    } else if (this.state.rider_id===""){
                      message.error("Please select rider")
                    }else if (Number(this.state.companyprofile.rider_deduction_fromdelivery)<=0){
                      message.error("Rider deduction percentage not set")
                   
                    }else {
                      this.setState({ datarequested: true });
                      this.setState({ items_list: [] });
    
                      //Now submit sale data to database
                      axios
                        .post(
                          serverconfig.backendserverurl +
                            "/customqueries/get_delivered_orderforinvoice_riders",
                          form_data,
                          {
                            headers: {
                              "content-type": "multipart/form-data",
                            },
                          }
                        )
                        .then((res) => {
                          this.setState({ datarequested: false });
                          this.setState({
                            items_list: JSON.parse(res.data.report),
                          });
                          
                        })
                        .catch((error) => console.log(error));
                  }
  
              }}>GENERATE VOUCHER 
              
              </Button>
 
              </FormItem>
            </Form>

           <Divider></Divider>
          <reactstrp.Table bordered>
          <thead>
            <tr>
      
            <th>
                {/**Approvers list */}
                <FormItem label=  {"Click To Add Approver"}>
                <PlusCircleFilled 
                style={{fontSize: '35px',margin:2,color:primarycolor}}
                onClick={()=>{
                  this.setState({approver_model:true})
                }}
                />
                </FormItem>

                {
                this.state.approvers_list.length>0?
                <div >
                  {
                    this.state.approvers_list.map((item)=>{
                      return (
                      <p>{item.key} | {item.approver_name} &nbsp;&nbsp;
                      <Popover content={<p>Click To Delete Item</p>} title="Delete">
                      <Popconfirm title="Sure to delete?" onConfirm={() => {
                        this.setState({ approvers_list:  [...this.state.approvers_list.filter(todo => todo.key
                          !==item.key)]});
                      }}>
                        <DeleteOutlined style={{color:'red'}}/>
                      </Popconfirm>
                      </Popover>
                      
                      </p>)
                    })
                  }

                </div>
                :
                null
              }

            <Divider></Divider>
            {/**Guarante fees */}

            <Form layout='inline'>

            <FormItem label="Guarantee Fees">
              <Input name="guarantee_fees" 
                type="number" 
                placeholder="Amount"
                value={this.state.guarantee_fees}
                onChange={(val)=>{this.setState({guarantee_fees:val.target.value})}}
              
              />     
            </FormItem>

            <FormItem label="">
            < Button
            type='primary'  
            
                onClick={()=>{
                  if (this.state.guarantee_fees===0){
                    message.error("Please enter amount")
                  }else{

                    const newreceiptitem={
                      id:(Number(this.state.items_list.length)+1),
                      key:(Number(this.state.items_list.length)+1),
                      item_name:"Gurantee Fees",
                      total:this.state.guarantee_fees,
                      item_type:'Guarantee Fees',
                      item_no:(Number(this.state.items_list.length)+1),
                      }
                
                    //add to the receipt item list
                    this.setState({
                      items_list: [...this.state.items_list, newreceiptitem]
                    });

                }


              //rest
              this.setState({guarantee_fees:0})

            }}
            
            >
              ADD TO LIST
              </Button>

           </FormItem>

           </Form>
            </th>


            {/**voucher creation section */}
            {
              this.state.items_list.length>0?
              <th>

              <FormItem label={"Date ("+this.state.date+")"}>
                 <DatePicker onChange={(date, dateString)=>{this.setState({ date: dateString})}} format={dateFormat} />
              </FormItem>
  
              <FormItem label="Payment Channel">
              <Select  name="Paymentchannel"
                placeholder="Payment Channel" 
                value={this.state.payment_channel} 
                onChange={(val)=>{this.setState({payment_channel:val})}} >
  
                {
                this.state.cashflow_accounts_list.map((item)=>{
                  return (<Option  value={item.account}>{item.account}</Option>)
                })
              }
  
                </Select>
              </FormItem>
  
             
              <h6 style={{fontWeight:'bolder'}}>
                  TOTAL:{" "}
                  {
                    <CurrencyFormat
                      value={this.calculate_Total()}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
              </h6>

              <FormItem label="Voucher Details">
                <Input name="voucher_details" 
                placeholder="Voucher Details Or Week Number"
                value={this.state.voucher_details} 
                onChange={(val)=>{this.setState({voucher_details:val.target.value})}} />
              </FormItem>
    
              <Button  type="primary" htmlType='button'  onClick={()=>{
                this.handleDatasubmission()
              }}>SAVE VOUCHER
              </Button>
              </th>
              :
              null
            }
          



            </tr>
          </thead>
           </reactstrp.Table>

            <Divider></Divider>
           
           {
             this.state.items_list.length>0 ?
            <reactstrp.Table bordered>
            <thead>
              <tr>
                <th>NO</th>
                <th>ITEM </th>
                <th>DESCRIPTION</th>
                <th>AMOUNT</th>
                <th>DELETE</th>

              </tr>
            </thead>
            <tbody>
              {this.state.items_list.map((item) => (
                <tr>
                  <td>{item.item_no}</td>
                  <td>{item.item_type}</td>
                  <td>{item.item_name}</td>

                  <td className="align-right">
                    {
                      <CurrencyFormat
                        value={Number(item.total)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                  </td>

                  <td>
                  <Popover content={<p>Click here to delete item</p>} title="Delete">

                  <Popconfirm 
                  title="Sure to delete?" 
                  onConfirm={() => {
                    this.setState({ items_list:  [...this.state.items_list.filter(todo => todo.key
                      !==item.key)]});
                  }}>
                    <DeleteOutlined style={{color:'red'}}/>
                  </Popconfirm>

                  </Popover>
                 </td>

                </tr>
              ))}
              <tr>
                <td style={{ fontWeight: "bolder" }}>TOTAL ( {this.state.items_list.length} )</td>
                <td></td>
                <td></td>

                <td className="align-right" style={{ fontWeight: "bolder" }}>
                  {
                    <CurrencyFormat
                      value={this.calculate_Total()}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                </td>
                <td></td>

              </tr>
            </tbody>
          </reactstrp.Table>
          :
          null
          }


         <Modal              
          visible={this.state.approver_model}
          title={"Approver Addition Form"}
          onCancel={()=>{this.setState({approver_model:false})}}
          style={{width:1000}}
          footer={[
            <Button key="back" onClick={()=>{this.setState({approver_model:false})}}>
              Cancel
            </Button>,
              <Button key="ok" type='primary' 
                onClick={()=>{  

                  var intobj={
                    'key':(Number(this.state.approvers_list.length)+1),
                    'approver_name':this.state.approver_name,
                  }

                  this.setState({approvers_list: [...this.state.approvers_list, intobj]});
                  this.setState({approver_name:''})
                  this.setState({approver_model:false})
                  message.info("Approver added")

                }}>
              Add Approver
            </Button>
            ]}
            >

              <FormItem label="Approver Name " >
                  <Input name="approver_name"  placeholder="Approver Name" value={this.state.approver_name} onChange={(val)=>{this.setState({approver_name:val.target.value})}} />
              </FormItem>                  

            </Modal> 

        </div>
      );
            }

    }

  }

}


export default VoucherCreateForm;



