import React from 'react';
import DishExtraForm from '../components/DishExtraForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import DishExtraDetails from './DishExtraDetails'
import CurrencyFormat from 'react-currency-format';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}
const { TabPane } = Tabs;

var token= ''

class DishExtrasList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    dish_extras: [],
    datarequested:true,
  };


  componentDidMount(){
    this.comp_loaded()
  }



comp_loaded=()=>{
  this.setState({datarequested:true})

  var dishID=this.props.dishID
    
  if(localStorage.getItem("token")){
     token= localStorage.getItem("token")
  }else{
     token= ''
  }

  axios.defaults.headers={
    "Content-Type":"application/json",
    Authorization:`Token ${token}`
  }

  axios.get(serverconfig.backendserverurl+`/api/dish_extras/?dish=${dishID}`)
  .then(res => {
      this.setState({
          dish_extras:res.data
      })
      this.setState({datarequested:false})

  })



}


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'id',
        ...this.getColumnSearchProps('name'),
      },

      {
        title: 'Price',
        dataIndex: 'price',
        key: 'id',
        render: (text,record) =>
        <CurrencyFormat value={record.price} displayType={'text'} thousandSeparator={true}/>

      },

      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>

        <Popover content={<p>Click here to delete </p>} title="Delete">
         <Popconfirm title="Sure to delete?" onConfirm={
          () => {
            axios.delete(`${serverconfig.backendserverurl}/api/dish_extras/${text}`).then(res =>{
              window.location.reload(false)
              message.info("successfully deleted") 
            }
            );
          }
        }>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>      
         </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="DISH EXTRAS" key="1">
            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.dish_extras}
            bordered
            
            expandable={{
              expandedRowRender: (record) =>{
                  return (
                    <Tabs defaultActiveKey="1" >
                    <TabPane tab="Exta Ddetails" key="1" >
                      <DishExtraDetails extraID={record.id} onrefresh={this.comp_loaded}  />
                    </TabPane>
                    </Tabs>
                   );
              } ,
              rowExpandable: (record) => true,
              onExpand:(condition,record)=>{
              },
            }}
            />

             </Panel>
             </Collapse>
            <br />

            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Create New Menu Extra" key="1">
              <DishExtraForm dishID={this.props.dishID} onrefresh={this.comp_loaded}  /> 
            </Panel>
            </Collapse>

        </div>
    )

    }

   
  }
}

export default DishExtrasList; 
