import React from "react";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Spin,
  Image,
  Card,
  Divider,
  Result,
  message,
  Table,
} from "antd";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import moment from "moment";
import * as serverconfig from "../serverconn";
import { Col, Row } from "reactstrap";
import {
  UserOutlined,
  LockOutlined,
  LoadingOutlined,
  MailOutlined,
} from "@ant-design/icons";
import * as XLSX from "xlsx";

var CryptoJS = require("crypto-js");

const FormItem = Form.Item;
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token = "";
var sacco = "";
var username = "";

class DataUploadForm_Json extends React.Component {
  state = {
    date: moment().format(dateFormat).toString(),
    datalist: [],
    uploadtype: "",
    columns: [],
    message: "",
    companyprofile: {},
  };

  componentDidMount() {
    if (localStorage.getItem("sacco")) {
      sacco = CryptoJS.AES.decrypt(
        localStorage.getItem("sacco"),
        "my-secret-key@12345"
      ).toString(CryptoJS.enc.Utf8);
      username = CryptoJS.AES.decrypt(
        localStorage.getItem("username"),
        "my-secret-key@123"
      ).toString(CryptoJS.enc.Utf8);
    } else {
      sacco = "";
      username = "";
    }

    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      token = "";
    }

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    axios
      .get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
      .then((res) => {
        this.setState({
          companyprofile: res.data,
        });
        this.setState({ datarequested: false });
      });
  }

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e.target.result;
        try {
          const jsonData = JSON.parse(fileContent);
          //console.log(JSON.stringify(jsonData))
          //chekc if areasr data
          if (this.state.uploadtype === "areas") {
            this.setState({ datalist: jsonData["areas"] });
          }

          if (this.state.uploadtype === "hubs") {
            jsonData["hubs"].map((item) => {
              console.log(JSON.stringify(item));
              var name = item.name;
              var short = item.short;
              var short = item.short;
              var location = item.location;
              var updated = item.updated;
              var zones = JSON.stringify(item.zones);
              var user = item.user;

              var intobj = {
                key: Number(this.state.datalist.length) + 1,
                name: name,
                short: short,
                location: location,
                updated: updated,
                zones: zones,
                user: user,
              };

              this.setState({ datalist: [...this.state.datalist, intobj] });
            });

            //this.setState({datalist:jsonData['hubs']});
          }

          if (this.state.uploadtype === "restaurants") {
            jsonData["stores"].map((item) => {
              console.log(JSON.stringify(item));
              var name = item.name;
              var category = item.category;
              var location = item.location;
              var updated = item.updated;
              var menus = JSON.stringify(item.menus);
              var user = item.user;
              var about = item.about;
              var logo = item.logo;
              var email = item.email;
              var phone = item.phone;
              var area = item.area;
              var hub = item.hub;
              var address = item.address;

              var intobj = {
                key: Number(this.state.datalist.length) + 1,
                name: name,
                category: category,
                about: about,
                logo: logo,
                location: location,
                updated: updated,
                menus: menus,
                user: user,
                email: email,
                phone: phone,
                area: area,
                hub: hub,
                address: address,
                maps: item.maps,
                opens: item.opens,
                closes: item.closes,
                pickup: item.pickup,
                delivery: item.delivery,
                added: item.added,
                updated: item.updated,
                presys_secret: item.secret,

              };

              this.setState({ datalist: [...this.state.datalist, intobj] });
            });

            //this.setState({datalist:jsonData['hubs']});
          }

          if (this.state.uploadtype === "promos") {
            this.setState({ datalist: jsonData["promos"] });
          }

          if (this.state.uploadtype === "restaurant_user") {
            this.setState({ datalist: jsonData["partners"] });
          }

          if (this.state.uploadtype === "admin_user") {
            this.setState({ datalist: jsonData["users"] });
          }

          if (this.state.uploadtype === "dishes") {
            jsonData["dishes"].map((item) => {
              // console.log(JSON.stringify(item))

              var intobj = {
                key: Number(this.state.datalist.length) + 1,
                available: item.available === "on" ? "on" : "off",
                name: item.name,
                summary: item.summary === undefined ? "" : item.summary,
                price: item.price,
                menu: item.menu,
                image: item.image,
                store: item.store,
                user: item.user,
                added: item.added,
                updated: item.updated,
                extras: JSON.stringify(item.extras),
                sSecret:item.sSecret
              };

              this.setState({ datalist: [...this.state.datalist, intobj] });
            });

            //this.setState({datalist:jsonData['hubs']});
          }

          if (this.state.uploadtype === "riders") {
            jsonData["riders"].map((item) => {
              // console.log(JSON.stringify(item))

              var intobj = {
                key: Number(this.state.datalist.length) + 1,
                available: item.available === "on" ? "on" : "off",
                name: item.name,
                phone: item.phones[0].phone,
                user: item.user,
                added: item.added,
                updated: item.updated,
              };

              this.setState({ datalist: [...this.state.datalist, intobj] });
            });
            //this.setState({datalist:jsonData['hubs']});
          }

          if (this.state.uploadtype === "clients") {
            jsonData["customers"].map((item) => {
              // console.log(JSON.stringify(item))

              var intobj = {
                key: Number(this.state.datalist.length) + 1,
                googleId: item.googleId,
                full_name: item.displayName,
                firstName: item.firstName,
                lastName: item.lastName,
                image: item.image,
                email: item.email,
                address: JSON.stringify(item.address),
                phone: item.phone,
                created: item.createdAt,
              };

              this.setState({ datalist: [...this.state.datalist, intobj] });
            });

            //this.setState({datalist:jsonData['hubs']});
          }

          if (this.state.uploadtype === "orders") {
            jsonData["orders"].map((item) => {
              // console.log(JSON.stringify(item))

              var intobj = {
                key: Number(this.state.datalist.length) + 1,
                date: item.date,
                updated: item.updated,
                area: item.area,
                phone: item.phone,
                cName: item.cName,
                storeName: item.storeName,
                notes: item.notes,
                dFees: item.dFees,
                paid: item.paid,
                riderName: item.riderName,
                status: item.status,
                orderType: item.orderType,
                pMethod: item.pMethod,
                items: JSON.stringify(item.items),
                pickupTime: item.pickupTime,
                deliverTime: item.deliverTime,
                user: item.user,
                storeId:item.storeId
              };
              this.setState({ datalist: [...this.state.datalist, intobj] });
            });
            //this.setState({datalist:jsonData['hubs']});
          }

          if (this.state.uploadtype === "invoices") {
            //this.setState({datalist:jsonData['invoices']});

            jsonData["invoices"].map((item) => {
              var intobj = {
                key: Number(this.state.datalist.length) + 1,
                added: item.added,
                updated: item.updated,
                storeName: item.storeName,
                pStart: item.pStart,
                pEnd: item.pEnd,
                range: item.range,
                delivery: item.delivery,
                service: item.service,
                status: item.status,
                user: item.user,
                recieved: item.recieved,
                paidWith: item.paidWith,
                singleFee: item.singleFee,
                storeSecret:item.storeSecret
              };
              this.setState({ datalist: [...this.state.datalist, intobj] });
            });
          }

          message.success(`${file.name} file uploaded successfully.`);
        } catch (parseError) {
          console.error("Error parsing JSON:", parseError);
          message.error("Invalid JSON file. Please upload a valid JSON file.");
        }
      };

      reader.readAsText(file);
    } catch (error) {
      console.error("Error reading file:", error);
      message.error("Error reading file. Please try again.");
    }
  };

  //submit button pressed
  handleFormSubmit = () => {
    if (this.state.datalist.length <= 0) {
      message.error("Datalist cant be empty.");
    } else {
      const dataSizeBytes = JSON.stringify(this.state.datalist).length;
      // Convert bytes to gigabytes
      const dataSizeGB = dataSizeBytes / (1024 * 1024 * 1024); // 1 GB = 1024 MB = 1024*1024 KB = 1024*1024*1024 Bytes
      message.info("Datasize: " + String(dataSizeGB) + " GBs");

      this.setState({ datarequested: true });

      let form_data = new FormData();

      form_data.append("sacco", sacco);
      form_data.append("datalist", JSON.stringify(this.state.datalist));
      form_data.append("username", username);
      form_data.append("uploadtype", this.state.uploadtype);

      axios
        .post(
          serverconfig.backendserverurl + "/customqueries/final_dataupload",
          form_data,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.setState({ message: res.data.message });
          console.log(res.data);
          this.setState({ datarequested: false });
          this.setState({ datasubmittedsuccessfully: true });
        })
        .catch((error) => console.log(error));
    }
  };

  render() {
    if (this.state.datarequested === true) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin indicator={antIcon} />
        </div>
      );
    } else {
      if (this.state.datasubmittedsuccessfully === true) {
        return (
          <Card>
            <Result
              status="success"
              title="Successful"
              subTitle={this.state.message}
              extra={[
                <Button
                  type="primary"
                  onClick={() => {
                    window.location.reload(false);
                  }}
                >
                  Finish
                </Button>,
              ]}
            />
          </Card>
        );
      } else {
        /// Generate columns dynamically based on keys of the first item
        const columns =
          this.state.datalist.length > 0
            ? Object.keys(this.state.datalist[0]).map((key) => ({
                title: key,
                dataIndex: key,
                key: key,
              }))
            : [];

        return (
          <div>
            <h3>Data upload section</h3>
            <h6 style={{ color: "red" }}>
              Note: Do not change excel template headings && maintain date
              formats. Records with wrong formats will not be added.
            </h6>
            <Card>
              <Form>
                <FormItem label="Upload type">
                  <Select
                    value={this.state.uploadtype}
                    onChange={(val) => {
                      this.setState({ uploadtype: val });
                    }}
                    placeholder="Upload type"
                  >
                    <Option value="areas">Areas upload</Option>
                    <Option value="hubs">Hubs upload</Option>
                    <Option value="restaurants">Restaurants upload</Option>
                    <Option value="promos">Promos upload</Option>
                    <Option value="dishes">Dishes upload</Option>
                    <Option value="riders">Riders upload</Option>
                    <Option value="clients">Clients upload</Option>
                    <Option value="orders">Orders upload</Option>

                    <Option value="invoices">Invoices upload</Option>
                    <Option value="restaurant_user">
                      Restaurant users upload
                    </Option>

                    <Option value="admin_user">Admin users upload</Option>
                  </Select>
                </FormItem>

                <FormItem label="Excel data template">
                  <Input
                    type="file"
                    accept=".json"
                    onChange={this.handleFileChange}
                  />
                </FormItem>
              </Form>
            </Card>

            <br></br>
            {this.state.datalist.length > 0 && (
              <div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h2>Processed Data</h2>
                  &nbsp;&nbsp;
                  <Button
                    type="primary"
                    onClick={() => {
                      this.handleFormSubmit();
                    }}
                  >
                    Upload data
                  </Button>
                </div>
                <div style={{ overflowX: "auto" }}>
                  <Table
                    dataSource={this.state.datalist}
                    columns={columns}
                    scroll={{ x: 1000 }}
                    bordered
                    pagination={{
                      showQuickJumper: true,
                      showSizeChanger: true,
                    }}
                    size="small"
                  />{" "}
                  {/* Display the data in an Ant Design Table */}
                </div>
              </div>
            )}
          </div>
        );
      }
    }
  }
}

export default DataUploadForm_Json;
