import React from 'react';
import { Form, Input, Button,Spin,Select } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;

var token= ''
var sacco= ''


class ChartofAccountssubcategoryForm extends React.Component {

  state = {
    description:'' ,  
    category_name:'',
    datarequested:false,
    chartofaccount_category:''
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('description', this.state.description);
    form_data.append('category_name', this.state.category_name);
    form_data.append('chartofaccount_category', this.state.chartofaccount_category);

      axios.post(serverconfig.backendserverurl+'/api/chartofaccounts_subcategories/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))
  }


  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Subcategory Name"
        name="category_name"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="category_name"  placeholder="Put name here." value={this.state.category_name} onChange={(val)=>{this.setState({category_name:val.target.value})}} />
      </FormItem>

      <FormItem label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: 'Please input desc',
          },
        ]}    
      >
        <Input name="description"  placeholder="Put description here" value={this.state.description} onChange={(val)=>{this.setState({description:val.target.value})}} />
      </FormItem>


      <FormItem label="Chart Of Accounts Category">
          <Select  name="chartofaccount_category"
           placeholder="Chart Of Accounts Category" 
            value={this.state.chartofaccount_category} 
            onChange={(val)=>{this.setState({chartofaccount_category:val})}} >
                <Option  value={"Assets"}>Assets</Option>
                <Option  value={"Liabilities"}>Liabilities</Option>
                <Option  value={'Equity'}>Equity</Option>
                <Option  value={'Income'}>Income</Option>
                <Option  value={'Expense'}>{"Expense"}</Option>
{ /*               <Option  value={'Other'}>{"Other"}</Option>
*/}          </Select>
      </FormItem>

     
      <FormItem>
        <Button  type="primary" htmlType="submit">Create Subcategory</Button>
      </FormItem>
    </Form>
        </div>
      );

    }

   
  }

}


export default ChartofAccountssubcategoryForm;



