import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Input,message,Spin,Select} from 'antd';
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';
const FormItem=Form.Item;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;

var token= ''

class AreaDetail extends React.Component{

    state={
        name:'' ,  
        datarequested:true,
        ugdistricts:[],
        district:''
    }


    componentDidMount(){
      this.comp_loaded()
    }

    comp_loaded=()=>{
      this.setState({datarequested:true})

        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        axios.defaults.headers={
          "Content-Type":"application/json",
          Authorization:`Token ${token}`
        }

        const areaID=this.props.areaID;
        axios.get(`${serverconfig.backendserverurl}/api/areas/${areaID}`)
        .then(res => {  
          
            this.setState({name:res.data.name})
            this.setState({district:res.data.district})

            this.setState({datarequested:false})
        })

        axios.get(serverconfig.backendserverurl+`/api/ugdistricts/`)
        .then(res => {
            this.setState({
              ugdistricts:res.data
            })
    
            this.setState({datarequested:false})
        })


    }


  
    //submit button pressed
  handleFormSubmit=() =>{
    this.setState({datarequested:true})

    const areaID=this.props.areaID;

    let form_data = new FormData();
    form_data.append('name', this.state.name);
    form_data.append('district', this.state.district);

    axios.put(`${serverconfig.backendserverurl}/api/areas/${areaID}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
    })
    .then(res =>{
      this.setState({datarequested:false})
      message.info('Successfully updated ID')

      this.comp_loaded()
      this.props.onRefresh()

    })
      
      
    .catch(error => console.log(error))
  }


    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>                 </div>
            )
      
          }else{
            return(
                <div>
                    
                    <Card 
                    style={{padding:10}}
                    hoverable>
                        <h4>Area Update</h4>
                        <Form>
                        <FormItem label="Name"
                        >
                          <Input name="name"  placeholder="Name." value={this.state.name} onChange={(val)=>{this.setState({name:val.target.value})}} />
                        </FormItem>
  

                        <FormItem label="District">
                          <Select 
                          placeholder="district" 
                          value={this.state.district} 
                          onChange={(val)=>{this.setState({district:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}    
                          >
                          {this.state.ugdistricts.map(
                          (cat)=>(
                            <Option value={cat.district_name}>{cat.district_name}</Option>
                          ))}
                          </Select>
                        </FormItem>
                    
                        <FormItem>
                        <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update</Button>
                        </FormItem>
                        </Form>
    
                    </Card>
    
                </div>
            )
        }
    }
}

export default AreaDetail; 