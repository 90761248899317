import React,{ useRef } from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Popconfirm,Typography,Divider,Switch,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,DownloadOutlined,LoadingOutlined,DeleteOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import '../tablestyle.css'; // Create this CSS file for custom styles

var CryptoJS = require("crypto-js");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Search } = Input;

var bizuserid= ''
var token= ''
var username=''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){

      if(localStorage.getItem("bizuserid")){
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
         username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
 
      }else{
         bizuserid= ''
         username=''

      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })

    }

    calculateTotalDebit=()=>{
      var total=0
  
      this.props.sheetitems.map((item)=>{
        total+=Number(item.Debit)
  
      })
      return total;
  
    }
  
    calculateTotalCredit=()=>{
      var total=0
  
      this.props.sheetitems.map((item)=>{
        total+=Number(item.Credit)
  
      })
      return total;
  
    }
  
    render() {
      return (
        <div style={{padding:20}}>
        <Row> 
                <Col xs="2" sm="2" lg="2">
                  <img height="100" width="100"  alt="Logo" src={this.state.companyprofile.company_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.system_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                
                  </Col>

       </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>JOURNALS  REPORT FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>

             <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Desc</th>
                          <th>Journal No</th>
                          <th>Voucher No</th>

                          <th>User</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Restaurant name</th>

                        </tr>
                      </thead>
                      <tbody>
                      {this.props.sheetitems.map(
                        (item)=>(
                          <tr>
                          <td>{item.item_no}</td>
                          <td>{item.date}</td>
                          <td>{item.account}</td>
                          <td>{item.description}</td>
                          <td>{item.journal_no}</td>
                          <td>{item.voucher_no}</td>

                          <td>{item.user}</td>
                          <td className="align-right">{<CurrencyFormat value={item.Debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.Credit} displayType={'text'} thousandSeparator={true}/>}</td>
                          
                          <td>{item.restaurantname}</td>

                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalDebit()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalCredit()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td></td>

                        </tr>

                    </tbody>
                </reactstrp.Table> 

        </div>
      );
    }
  }


class TillSheetReport_General extends React.Component {

  state = {
    users:[],
    sheetitems:[],
    sheetitems_placeholders:[],

    dateone:moment().format(dateFormat).toString(),
    datetwo:moment().format(dateFormat).toString(),
    entrant:'',
    entrantname:'',
    totaldebit:0,
    totalcredit:0,
    datarequested:false,
    searchbyjno:false,
    journalno:0,

    selected_jounal:'',
    selected_jounal_obj:{},
    jounalform_visible:false,
    journalcredit_amount:0,
    journaldebit_amount:0,
    journal_description:'',
    journal_date:'',
    companyprofile:{},
    searchbybranch:false,
    selected_branch:'',
    companyaccounts: [],
    journal_account:'',
    userrights:{},
    action:'all',

   };

   componentDidMount(){
    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)

   }else{
      bizuserid= ''
      username=''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accounts/`)
    .then(res => {
        this.setState({
          users:res.data
        })
      })


      axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
      })

    

  


      axios.get(serverconfig.backendserverurl+`/api/companyaccounts/`)
      .then(res => {
          this.setState({
              companyaccounts:res.data
          })
          this.setState({datarequested:false})
      })
  

      axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
      .then(res => {
          this.setState({
            userrights:res.data[0]
          })
      })

  }
 
   handledatechange= (date, dateString) =>{
     this.setState({ dateone: dateString[0]});
     this.setState({ datetwo: dateString[1]});
   } 

   handleentrantChange= (e) => this.setState({ entrant: e});

   //search sales method
   searchSales=()=>{
    
      let form_data = new FormData();
      form_data.append('dateone', this.state.dateone);
      form_data.append('datetwo', this.state.datetwo);
      form_data.append('action', this.state.action);
      form_data.append('journalno', this.state.journalno);

      if(this.state.action===''||this.state.dateone===''||this.state.datetwo===''){
        message.error("Please select action or dates missing")
      }
    
       
      else if (this.state.action==='journal_no' && this.state.journalno===0){
        message.error("Please enter journal no")
      }

      else{
          this.setState({datarequested:true})
  
          //Now submit sale data to database
          axios.post(serverconfig.backendserverurl+'/customqueries/getTillSheetReport_General', form_data,{
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
          .then(res =>{
            this.setState({datarequested:false})
  
              this.setState({sheetitems:JSON.parse(res.data.report)})
              this.setState({sheetitems_placeholders:JSON.parse(res.data.report)})

              this.setState({totaldebit:res.data.totalDebit})
              this.setState({totalcredit:res.data.totalCredit})

          } 
          )
          .catch(error => console.log(error))   
      
    }
  }

   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }
 
  calculateTotalDebit=()=>{
    var total=0

    this.state.sheetitems.map((item)=>{
      total+=Number(item.Debit)

    })
    return total.toFixed(2);

  }

  calculateTotalCredit=()=>{
    var total=0

    this.state.sheetitems.map((item)=>{
      total+=Number(item.Credit)

    })
    return total.toFixed(2);

  }



   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
     
       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
  
      }else{

        return(
          <div>
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              totaldebit={this.state.totaldebit} 
              totalcredit={this.state.totalcredit} 
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              sheetitems={this.state.sheetitems}
              entrantname={this.state.entrantname}
              searchbyjno={this.state.searchbyjno}
              ref={el => (this.componentRef = el)} /> 
            </div>
  
              <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="JOURNAL REPORT" key="1">


              <div style={{display:'flex',justifyContent:'flex-start',flexDirection:'row'}}>
                <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />
                &nbsp;&nbsp;

              <ExcelFile filename={"eats.biz - Tillsheet report from "+this.state.dateone+" to "+this.state.datetwo}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download (Excel)</Button>}>
                <ExcelSheet data={this.state.sheetitems} name={"Journal entries report"}>
                    <ExcelColumn label="No" value="item_no"/>
                    <ExcelColumn label="Date" value="date"/>
                    <ExcelColumn label="Account" value="account"/>
                    <ExcelColumn label="Description" value="description"/>
                    <ExcelColumn label="Journal no" value="journal_no"/>
                    <ExcelColumn label="Voucher no" value="voucher_no"/>

                    <ExcelColumn label="User" value="user"/>
                    <ExcelColumn label="Debit" value="Debit"/>
                    <ExcelColumn label="Credit" value="Credit"/>

                    <ExcelColumn label="Property_owner_name" value="property_owner_name"/>

                </ExcelSheet>
              </ExcelFile>
               </div>
               <br></br>
               <br></br>
               <Form  layout="inline" >

               <FormItem label="Search by">
                    <Select 
                    placeholder="Action" 
                    style={{ width:300 }} 
                    value={this.state.action}
                    onChange={(val)=>{this.setState({action:val})}} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}                  
                    >
                          <Option value={"all"}>All journals </Option>
                          <Option value={"journal_no"}>By Journal number</Option>

                    </Select>
                </FormItem>


            {
                this.state.action==="journal_no"?
                <FormItem label="Journal no">
                      <Input type='number' 
                      name="journalno" 
                      placeholder="Journal no"
                      value={this.state.journalno} 
                      onChange={(val)=>{this.setState({journalno:val.target.value})}}/>
                </FormItem>
                :
                null
                }


              <FormItem label="Date Range">
                  <RangePicker onChange={this.handledatechange} format={dateFormat} />
              </FormItem>


              <FormItem>
              <Button onClick={()=>{
                  this.searchSales()

              }} type="primary" htmlType="button">Search</Button>
              </FormItem> 
              </Form>
    
             <br></br>

             <Form  layout="inline" >

            <FormItem label="Filter by user">
            <Search
                  placeholder="Filter by user"
                  onChange={(value) => {
                    var val=value.target.value
                    console.log(val)
                    if (val !== '' || val !== undefined) {
                      //set one is by NAME
                      const results_1 = this.state.sheetitems.filter((request) => {
                        return String(request.user).toLowerCase().startsWith(val.toLowerCase());
                        // Use the toLowerCase() method to make it case-insensitive
                      });
                
                      if (results_1.length === 0) {
                       this.setState({sheetitems:this.state.sheetitems_placeholders});

                      } else {
                        this.setState({sheetitems:results_1});
                        console.log(results_1)
                      }
                
                    } else {
                      this.setState({sheetitems:this.state.sheetitems_placeholders});
                    }
                
                  }}
                  style={{
                    width: 400,
                    margin:5
                  }}
                  />
              </FormItem>
              </Form>


          <br></br>
             <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Desc</th>
                          <th>Journal No</th>
                          <th>Voucher No</th>

                          <th>User</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th> Restaurant</th>

                        </tr>
                      </thead>
                      <tbody>
                      {this.state.sheetitems.map(
                        (item)=>(
                          <tr>
                          <td>{item.item_no}</td>
                          <td>{item.date}</td>
                          <td>{item.account}</td>
                          <td>{item.description}</td>
                          <td>{item.journal_no}</td>
                          <td>{item.voucher_no}</td>

                          <td>{item.user}</td>
                          <td className="align-right">{<CurrencyFormat value={item.Debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.Credit} displayType={'text'} thousandSeparator={true}/>}</td>
                          
                          <td>{item.restaurantname}</td>

                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalDebit()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalCredit()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td></td>

                        </tr>

                    </tbody>
                </reactstrp.Table> 

              </Panel>
              </Collapse>

          </div>
      ) }

   }
}

export default TillSheetReport_General; 
