import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Tabs,Input,message,Spin,Select,Table,Popover,Popconfirm} from 'antd';
import { UserOutlined,LoadingOutlined,DeleteOutlined,DownloadOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import HubZoneDetails from './HubZoneDetails'
import * as primarycolor from '../primarycolor'
import ReactExport from "@ibrahimrahmani/react-export-excel";


var CryptoJS = require("crypto-js");
 
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const FormItem=Form.Item;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;

var token= ''
var username=''
const { TabPane } = Tabs;


class HubZones extends React.Component{

    state={
        price:0,
        datarequested:true,
        areas:[],
        district:'',
        zones:[],
        selected_area:'',
        hub_zones:[]
    }

    componentDidMount(){
      this.compo_reloaded()
    }


    compo_reloaded=()=>{
      this.setState({datarequested:true})

      if(localStorage.getItem("username")){
        username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
     
     }else{
        username=''
     }

        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        axios.defaults.headers={
          "Content-Type":"application/json",
          Authorization:`Token ${token}`
        }

        const hubID=this.props.hubID;
        axios.get(`${serverconfig.backendserverurl}/api/hub_zones/?hub=${hubID}`)
        .then(res => {  
            this.setState({datarequested:false})
            //set zones
            //.setState({zones:JSON.parse(res.data.zones)})
            this.setState({hub_zones:res.data})

        })

        axios.get(serverconfig.backendserverurl+`/api/areas/`)
        .then(res => {
            this.setState({
              areas:res.data
            })
    
            this.setState({datarequested:false})
        })



    }




    //check if area already added
    isareaAlreadyExists = (area, list) => {
      return list.some((item) => item === area);
    };



    //capitalize fistrt letter
    capitalizeFirstLetter=(word)=> {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }

  
    //submit button pressed
  handleFormSubmit=() =>{
    this.setState({datarequested:true})

    const hubID=this.props.hubID;
    let form_data = new FormData();
    form_data.append('zone_list', JSON.stringify(this.state.zones));
    form_data.append('hub', hubID);
    form_data.append('user', username);
    form_data.append('price', this.state.price);

    axios.post(`${serverconfig.backendserverurl}/api/hub_zones/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
    })
    .then(res =>{
      this.setState({datarequested:false})
      message.info('Successfully updated ID')

      this.compo_reloaded()
      //this.props.onRefresh()
      this.setState({zones:[]})
      this.setState({price:0})

    })  
    .catch(error => console.log(error))
  }


    render(){

      const columns = [
        {
          title: 'Price',
          dataIndex: 'price',
          key: 'key',
          render: (text) =>
          <p>
            <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}/>
          </p>

        },
        {
          title: 'Zone ID',
          dataIndex: 'zone_id',
          key: 'key',
        },

        {
          title: 'User',
          dataIndex: 'user',
          key: 'key',
        },

        {
          title: 'Zone List',
          dataIndex: 'zone_list',
          key: 'key',
         
        }
        ];
  

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>          
               </div>
            )
      
          }else{
            return(
                <div>
                    
                    <Card 
                    style={{padding:10}}
                    hoverable>

                        <h4> Zone List</h4>
                        <ExcelFile filename={"eats.biz - Hubzones report for "+this.props.hubobj.name}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download (Excel)</Button>}>
                          <ExcelSheet data={this.state.hub_zones} name={"HubZones"}>
                              <ExcelColumn label="Price" value="price"/>
                              <ExcelColumn label="Areas" value="zone_list"/>
                          </ExcelSheet>
                        </ExcelFile>
                        <br></br>
                        <br></br>

                        <Table 
                          columns={columns} 
                          scroll={{ x: 1000 }}
                          pagination={{showQuickJumper:true,showSizeChanger:true }}
                          dataSource={this.state.hub_zones}
                          bordered

                          expandable={{
                            expandedRowRender: (record) =>{
                            return (
                              <Tabs defaultActiveKey="1" >
                              <TabPane tab="Zone Details" key="1" >
                                  <HubZoneDetails  onRefresh={this.compo_reloaded} zone_obj={record} />
                              </TabPane>
                              </Tabs>
                              );
                            } ,
                            rowExpandable: (record) => true,
                            onExpand:(condition,record)=>{
                            },
                          }}

                          />
                        <br></br>
                        <br></br>

                        <h4>Create New Zone</h4>
                        <Form>
                       
                        <FormItem label="Price" >
                          <Input name="price"
                           type='number' 
                           placeholder="Price" 
                           value={this.state.price} 
                           onChange={(val)=>{this.setState({price:val.target.value})}} />
                        </FormItem>


                      {/**Created selected areas list */}
                      {
                          this.state.areas.length>0?
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignSelf: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                        
                            {
                                this.state.areas.map((ar)=>{
                                  return (
                                    <span style={{display:'flex',
                                    justifyContent:'center',
                                    width:200,
                                    border: `1px solid ${primarycolor.primarycolor}`,
                                    margin:1,
                                    backgroundColor:this.isareaAlreadyExists(ar.name,this.state.zones)===true?'yellow':'#fff'

                                    }}

                                    onClick={()=>{
                                      if (this.isareaAlreadyExists(ar.name,this.state.zones)===true){
                                        this.setState({ zones:  [...this.state.zones.filter(todo => todo
                                          !==ar.name)]});
                                        
                                        message.info("Removed")
                                      }else{
                                        this.setState({zones: [...this.state.zones,ar.name]});
                                        message.info("Added")
                                      }

                                    }}
                                    >
                                      {this.capitalizeFirstLetter(ar.name)}

                                    </span>

                                  )
                                })
                          }

                          </div>

                        :
                        null
                        }

                        <br></br>
                        <br></br>


                          <div style={{display:'flex',justifyContent:'flex-end',alignItems:'flex-end',alignSelf:'flex-end'}}>
                          <FormItem >
                          <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Add Zone</Button>
                          </FormItem>

                          </div>
                        </Form>
    
                    </Card>
    
                </div>
            )
        }
    }
}

export default HubZones; 