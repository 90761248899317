import React, { useRef } from "react";
import axios from "axios";
import {
  Table,
  Input,
  Button,
  Collapse,
  Avatar,
  Modal,
  Form,
  DatePicker,
  Select,
  Space,
  Typography,
  Divider,
  Switch,
  InputNumber,
  message,
  Spin,
} from "antd";
import Highlighter from "react-highlight-words";
import Icon, {
  PrinterOutlined,
  MinusOutlined,
  CloseCircleFilled,
  CheckCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  SearchOutlined,
  CameraOutlined,
  PlusOutlined,
  LoadingOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import QrReader from "react-qr-reader";
import uuid from "react-uuid";
import PhoneInput from "react-phone-number-input";
import moment from "moment";
import PrintTemplate from "react-print";
import ReactToPrint from "react-to-print";
import * as serverconfig from "../serverconn";
import CurrencyFormat from "react-currency-format";
import * as reactstrp from "reactstrap";

import { Col, Row } from "reactstrap";
import ReactExport from "@ibrahimrahmani/react-export-excel";
import "../tablestyle.css"; // Create this CSS file for custom styles

var CryptoJS = require("crypto-js");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const FormItem = Form.Item;
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Search } = Input;

var sacco = "";
var bizuserid = "";
var token = "";

class ReportToPrint extends React.Component {
  state = {
    companyprofile: {},
  };

  componentDidMount() {
    if (localStorage.getItem("sacco")) {
      sacco = CryptoJS.AES.decrypt(
        localStorage.getItem("sacco"),
        "my-secret-key@12345"
      ).toString(CryptoJS.enc.Utf8);
      bizuserid = CryptoJS.AES.decrypt(
        localStorage.getItem("bizuserid"),
        "my-secret-key@1234"
      ).toString(CryptoJS.enc.Utf8);
    } else {
      sacco = "";
      bizuserid = "";
    }

    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      token = "";
    }

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    axios
      .get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
      .then((res) => {
        this.setState({
          companyprofile: res.data,
        });
      });
  }

  //calculate total savings
  calculatedebittotal = () => {
    var Total = 0;
    this.props.sheetitems.map((item) => {
      Total += Number(item.Debit);
    });

    return Total.toFixed(2);
  };

  calculatecredittotal = () => {
    var Total = 0;
    this.props.sheetitems.map((item) => {
      Total += Number(item.Credit);
    });

    return Total.toFixed(2);
  };

  //calculate balances
  calculate_Balance = () => {
    var total = {};
    var balance = 0;

    if (this.calculatedebittotal() > this.calculatecredittotal()) {
      balance = this.calculatedebittotal() - this.calculatecredittotal();
      total = {
        debit: balance.toFixed(2),
        credit: 0,
      };
    }

    if (this.calculatecredittotal() > this.calculatedebittotal()) {
      balance = this.calculatecredittotal() - this.calculatedebittotal();
      total = {
        debit: 0,
        credit: balance.toFixed(2),
      };
    }

    return total;
  };

  render() {
    return (
      <div style={{ padding: 20 }}>
        <Row>
          <Col xs="2" sm="2" lg="2">
            <img
              height="100"
              width="100"
              alt="Logo"
              src={this.state.companyprofile.company_logo}
            />
          </Col>
          <Col xs="6" sm="6" lg="6">
            <h3>{this.state.companyprofile.system_name}</h3>
            <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}</h6>
            <h5>Email: {this.state.companyprofile.company_email}</h5>
            <h5>Wesite: {this.state.companyprofile.website}</h5>
            <h5>{this.state.companyprofile.box_number}</h5>
          </Col>
        </Row>

        <h3
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bolder",
          }}
        >
          GENERAL LEDGER FOR {this.props.account} FROM: {this.props.dateone} TO:{" "}
          {this.props.datetwo}
        </h3>

        <reactstrp.Table bordered>
          <thead>
            <tr>
              <th>No</th>
              <th>Date</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Desc</th>
              <th>Journal no</th>
              <th>Voucher no</th>
              <th>User</th>
              <th>Restaurant</th>
            </tr>
          </thead>
          <tbody>
            {this.props.sheetitems.map((item) => (
              <tr>
                <td>{item.item_no}</td>
                <td>{item.date}</td>
                <td className="align-right">
                  {
                    <CurrencyFormat
                      value={item.Debit}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                </td>
                <td className="align-right">
                  {
                    <CurrencyFormat
                      value={item.Credit}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                </td>
                <td>{item.description}</td>
                <td>{item.journal_no}</td>
                <td>{item.voucher_no}</td>

                <td>{item.user}</td>
                <td>{item.restaurantname}</td>
              </tr>
            ))}
            <tr>
              <td style={{ fontWeight: "bolder" }}>Total</td>
              <td></td>

              <td className="align-right" style={{ fontWeight: "bolder" }}>
                {
                  <CurrencyFormat
                    value={this.calculatedebittotal()}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
              </td>
              <td className="align-right" style={{ fontWeight: "bolder" }}>
                {
                  <CurrencyFormat
                    value={this.calculatecredittotal()}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
              </td>
              <td></td>
              <td></td>

              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td style={{ fontWeight: "bolder" }}>Balance</td>
              <td></td>

              <td className="align-right" style={{ fontWeight: "bolder" }}>
                {
                  <CurrencyFormat
                    value={this.calculate_Balance().debit}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
              </td>
              <td className="align-right" style={{ fontWeight: "bolder" }}>
                {
                  <CurrencyFormat
                    value={this.calculate_Balance().credit}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
              </td>
              <td></td>
              <td></td>

              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </reactstrp.Table>
      </div>
    );
  }
}

class LedgerReport extends React.Component {
  state = {
    ledgeraccounts: [],
    sheetitems: [],
    sheetitems_placeholders: [],

    dateone: "",
    datetwo: "",
    account: "",
    totaldebit: 0,
    totalcredit: 0,
    datarequested: false,
    searchbyjno: false,
    journalno: 0,

    members: [],
    selectedaccount: "",
  };

  componentDidMount() {
    if (localStorage.getItem("sacco")) {
      sacco = CryptoJS.AES.decrypt(
        localStorage.getItem("sacco"),
        "my-secret-key@12345"
      ).toString(CryptoJS.enc.Utf8);
      bizuserid = CryptoJS.AES.decrypt(
        localStorage.getItem("bizuserid"),
        "my-secret-key@1234"
      ).toString(CryptoJS.enc.Utf8);
    } else {
      sacco = "";
      bizuserid = "";
    }

    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      token = "";
    }

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    /*
    let form_data = new FormData();
    form_data.append('sacco', sacco);
    axios.post(serverconfig.backendserverurl+`/customqueries/getjournalaccounts`, form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          ledgeraccounts:JSON.parse(res.data.report)
        })
      }) */

    axios
      .get(serverconfig.backendserverurl + `/api/companyaccounts/`)
      .then((res) => {
        this.setState({
          ledgeraccounts: res.data,
        });
      });
  }

  handledatechange = (date, dateString) => {
    this.setState({ dateone: dateString[0] });
    this.setState({ datetwo: dateString[1] });
  };

  //calculate total savings
  calculatedebittotal = () => {
    var Total = 0;
    this.state.sheetitems.map((item) => {
      Total += Number(item.Debit);
    });

    return Total.toFixed(2);
  };

  calculatecredittotal = () => {
    var Total = 0;
    this.state.sheetitems.map((item) => {
      Total += Number(item.Credit);
    });

    return Total.toFixed(2);
  };

  //calculate balances
  calculate_Balance = () => {
    var total = {};

    if (
      Number(this.calculatedebittotal()) > Number(this.calculatecredittotal())
    ) {
      var balance = 0;

      balance =
        Number(this.calculatedebittotal()) -
        Number(this.calculatecredittotal());
      total = {
        debit: balance.toFixed(2),
        credit: 0,
      };
      console.log(JSON.stringify(total) + " wen deb > cred ");
    } else {
      var balance = 0;
      balance =
        Number(this.calculatecredittotal()) -
        Number(this.calculatedebittotal());
      total = {
        debit: 0,
        credit: balance.toFixed(2),
      };
      console.log(JSON.stringify(total) + " wen crd > deb ");
    }

    return total;
  };

  //search sales method
  searchSales = () => {
    let form_data = new FormData();
    form_data.append("dateone", this.state.dateone);
    form_data.append("datetwo", this.state.datetwo);
    form_data.append("account", this.state.account);
    form_data.append("selectedaccount", this.state.selectedaccount);

    console.log(this.state.account);

    if (
      this.state.account === "" ||
      this.state.dateone === "" ||
      this.state.datetwo === ""
    ) {
      alert("Please select account or dates missing");
    } else {
      this.setState({ datarequested: true });

      //Now submit sale data to database
      axios
        .post(
          serverconfig.backendserverurl + "/customqueries/getLedgerReport",
          form_data,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.setState({ datarequested: false });

          this.setState({ sheetitems: JSON.parse(res.data.report) });
          this.setState({
            sheetitems_placeholders: JSON.parse(res.data.report),
          });
        })
        .catch((error) => console.log(error));
    }
  };
  ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {
    if (this.state.datarequested === true) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin indicator={antIcon} />
        </div>
      );
    } else {
      return (
        <div>
          <div style={{ display: "none" }}>
            &nbsp;&nbsp;
            <ReportToPrint
              totaldebit={this.calculatedebittotal()}
              totalcredit={this.calculatecredittotal()}
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              sheetitems={this.state.sheetitems}
              account={this.state.account}
              ref={(el) => (this.componentRef = el)}
            />
          </div>

          <Collapse defaultActiveKey={["1"]} onChange={this.callback}>
            <Panel header="GENERAL LEDGER" key="1">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                }}
              >
                <ReactToPrint
                  trigger={() => (
                    <Button
                      type="primary"
                      shape="round"
                      icon={<PrinterOutlined />}
                      size={this.state.size}
                    >
                      Print
                    </Button>
                  )}
                  content={() => this.componentRef}
                />
                &nbsp;&nbsp;
                <ExcelFile
                  filename={
                    "eats.biz - Generalledger report for " +
                    this.state.account +
                    " from " +
                    this.state.dateone +
                    " to " +
                    this.state.datetwo
                  }
                  element={
                    <Button type="primary" icon={<DownloadOutlined />}>
                      Download (Excel)
                    </Button>
                  }
                >
                  <ExcelSheet
                    data={this.state.sheetitems}
                    name={"Journal entries report"}
                  >
                    <ExcelColumn label="No" value="item_no" />

                    <ExcelColumn label="Date" value="date" />
                    <ExcelColumn
                      label="Property_owner_name"
                      value="restaurantname"
                    />

                    <ExcelColumn label="Description" value="description" />
                    <ExcelColumn label="Journal no" value="journal_no" />
                    <ExcelColumn label="Voucher no" value="voucher_no" />

                    <ExcelColumn label="Debit" value="Debit" />
                    <ExcelColumn label="Credit" value="Credit" />
                    <ExcelColumn label="User" value="user" />
                  </ExcelSheet>
                </ExcelFile>
              </div>
              <br></br>
              <br></br>

              <Form layout="inline">
                <FormItem label="Select Account">
                  <Select
                    placeholder="Account"
                    style={{ width: 200 }}
                    value={this.state.account}
                    onChange={(val) => {
                      this.setState({ account: val });
                    }}
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.ledgeraccounts.map((user) => (
                      <Option value={user.account_name}>
                        {user.account_name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>

                <FormItem label="Date Range">
                  <RangePicker
                    defaultValue={moment()}
                    onChange={this.handledatechange}
                    format={dateFormat}
                  />
                </FormItem>

                <FormItem>
                  <Button
                    onClick={this.searchSales}
                    type="primary"
                    htmlType="button"
                  >
                    Search
                  </Button>
                </FormItem>
              </Form>
              <br></br>

              <Form layout="inline">
                <FormItem label="Filter by user">
                  <Search
                    placeholder="Filter by user"
                    onChange={(value) => {
                      var val = value.target.value;
                      console.log(val);
                      if (val !== "" || val !== undefined) {
                        //set one is by NAME
                        const results_1 = this.state.sheetitems.filter(
                          (request) => {
                            return String(request.user)
                              .toLowerCase()
                              .startsWith(val.toLowerCase());
                            // Use the toLowerCase() method to make it case-insensitive
                          }
                        );

                        if (results_1.length === 0) {
                          this.setState({
                            sheetitems: this.state.sheetitems_placeholders,
                          });
                        } else {
                          this.setState({ sheetitems: results_1 });
                          console.log(results_1);
                        }
                      } else {
                        this.setState({
                          sheetitems: this.state.sheetitems_placeholders,
                        });
                      }
                    }}
                    style={{
                      width: 400,
                      margin: 5,
                    }}
                  />
                </FormItem>
              </Form>

              <br></br>

              <reactstrp.Table bordered>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Date</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Desc</th>
                    <th>Journal no</th>
                    <th>Voucher no</th>
                    <th>User</th>
                    <th>Restaurant name</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.sheetitems.map((item) => (
                    <tr>
                      <td>{item.item_no}</td>
                      <td>{item.date}</td>
                      <td className="align-right">
                        {
                          <CurrencyFormat
                            value={item.Debit}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        }
                      </td>
                      <td className="align-right">
                        {
                          <CurrencyFormat
                            value={item.Credit}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        }
                      </td>
                      <td>{item.description}</td>
                      <td>{item.journal_no}</td>
                      <td>{item.voucher_no}</td>

                      <td>{item.user}</td>
                      <td>{item.restaurantname}</td>
                    </tr>
                  ))}
                  <tr>
                    <td style={{ fontWeight: "bolder" }}>Total</td>
                    <td></td>

                    <td
                      className="align-right"
                      style={{ fontWeight: "bolder" }}
                    >
                      {
                        <CurrencyFormat
                          value={this.calculatedebittotal()}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      }
                    </td>
                    <td
                      className="align-right"
                      style={{ fontWeight: "bolder" }}
                    >
                      {
                        <CurrencyFormat
                          value={this.calculatecredittotal()}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      }
                    </td>
                    <td></td>
                    <td></td>

                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td style={{ fontWeight: "bolder" }}>Balance</td>
                    <td></td>

                    <td
                      className="align-right"
                      style={{ fontWeight: "bolder" }}
                    >
                      {
                        <CurrencyFormat
                          value={this.calculate_Balance().debit}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      }
                    </td>
                    <td
                      className="align-right"
                      style={{ fontWeight: "bolder" }}
                    >
                      {
                        <CurrencyFormat
                          value={this.calculate_Balance().credit}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      }
                    </td>
                    <td></td>
                    <td></td>

                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </reactstrp.Table>
            </Panel>
          </Collapse>
        </div>
      );
    }
  }
}
export default LedgerReport;
