import React,{ useRef } from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined,DownloadOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


var token= ''
var sacco= ''
var bizuserid= ''



class LedgerAccountDetails extends React.Component {

  state = {
   expenses:[],
   incomes:[],
   dateone:'',
   datetwo:'',
   totalincomes:0,
   totalexpenses:0,
   surplus:0,
   datarequested:true,
   extra_data:{},

   expenses_list:[],
   incomes_list:[],
   deposts_list:[],
   shares_list:[],
   withdraws_list:[],
   loan_issuances_list:[],
   principle_list:[],
   newmbers_list:[],

   detailsmodel_visible:false,
   show_expenses:false,
   show_incomes:false,
   show_deposits:false,
   show_shares:false,
   show_withdraws:false,
   showloan_issuances:false,
   showprinciple_pay:false,
   show_newmebers:false,
   current_selected_account:'',

   extra_list:[],
   sheetitems:[],
   sheetitems_placeholders:[]

  };

  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
        sacco= ''
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({sheetitems:[]})
    this.setState({sheetitems_placeholders:[]})


    //get lsit here
    this.getstatement()
  }



  //search incomes method
  getstatement=()=>{
    this.setState({datarequested:true})

   let form_data = new FormData();
   form_data.append('dateone', this.props.dateone);
   form_data.append('datetwo', this.props.datetwo);
   form_data.append('account', this.props.account);

   if(this.props.dateone===''||this.props.datetwo===''){
    alert("Please are dates missing")
   }else{


    //Now submit sale data to database
    axios.post(serverconfig.backendserverurl+'/customqueries/getledgeraccount_txndetails_periodic', form_data,{
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
    .then(res =>{
      this.setState({datarequested:false})
        console.log(JSON.parse(res.data.report))

        this.setState({sheetitems:JSON.parse(res.data.report)})
        this.setState({sheetitems_placeholders:JSON.parse(res.data.report)})

    } 
    )
    .catch(error => console.log(error))   

    }

   }


     //calculate total savings
  calculatedebittotal=()=>{
    var Total=0
    this.state.sheetitems.map(
      (item)=>{
        Total+=Number(item.Debit)
      })

    return Total.toFixed(2);

  }

  calculatecredittotal=()=>{
    var Total=0
    this.state.sheetitems.map(
      (item)=>{
        Total+=Number(item.Credit)
      })

    return Total.toFixed(2);

  }

  //calculate balances
  calculate_Balance=()=>{
    var total={}

    if (Number(this.calculatedebittotal())>Number(this.calculatecredittotal())){
          var balance=0

      balance=Number(this.calculatedebittotal())-Number(this.calculatecredittotal())
      total={
        'debit':balance.toFixed(2),
        'credit':0
      }
      console.log(JSON.stringify(total)+" wen deb > cred ")


    }else{
      var balance=0
      balance=Number(this.calculatecredittotal())-Number(this.calculatedebittotal())
      total={
        'debit':0,
        'credit':balance.toFixed(2)
      }
      console.log(JSON.stringify(total)+" wen crd > deb ")

    }

    return total;

  }
  



  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {
      
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(

        <div>

          <h3>{"eats.biz - General ledger report for "+this.props.account+" from "+this.props.dateone+" to "+this.props.datetwo}</h3>

      <ExcelFile filename={"eats.biz - General ledger report for "+this.props.account+" from "+this.props.dateone+" to "+this.props.datetwo}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download (Excel)</Button>}>
      <ExcelSheet data={this.state.sheetitems} name={"Journal entries report"}>
          <ExcelColumn label="No" value="item_no"/>

          <ExcelColumn label="Date" value="date"/>
          <ExcelColumn label="Account" value="account"/>
          <ExcelColumn label="Member" value="member_name"/>

          <ExcelColumn label="Description" value="description"/>
          <ExcelColumn label="Journal no" value="journal_no"/>
          <ExcelColumn label="Debit" value="Debit"/>
          <ExcelColumn label="Credit" value="Credit"/>
          <ExcelColumn label="User" value="user"/>

      </ExcelSheet>
    </ExcelFile>

        <br></br>
        <br></br>
        <br></br>

<reactstrp.Table bordered>
         <thead>
           <tr>
             <th>No</th>

             <th>Date</th>
             <th>Debit</th>
             <th>Credit</th>
             <th>Desc</th>
             <th>Journal no</th>
             <th>User</th>

           </tr>
         </thead>
         <tbody>
         {this.state.sheetitems.map(
           (item)=>(
             <tr>
             <td>{item.item_no}</td>
             <td>{item.date}</td>
             <td className="align-right">{<CurrencyFormat value={item.Debit} displayType={'text'} thousandSeparator={true}/>}</td>
             <td className="align-right">{<CurrencyFormat value={item.Credit} displayType={'text'} thousandSeparator={true}/>}</td>
             <td>{item.description}</td>
             <td>{item.journal_no}</td>
             <td>{item.user}</td>

             </tr>
           ))}
           <tr>
           <td style={{fontWeight:'bolder'}}>Total</td>
           <td></td>

           <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculatedebittotal()} displayType={'text'} thousandSeparator={true}/>}</td>
           <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculatecredittotal()} displayType={'text'} thousandSeparator={true}/>}</td>
           <td></td>
           <td></td>
           <td></td>

           </tr>

           <tr>
           <td style={{fontWeight:'bolder'}}>Balance</td>
           <td></td>

           <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculate_Balance().debit} displayType={'text'} thousandSeparator={true}/>}</td>
           <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculate_Balance().credit} displayType={'text'} thousandSeparator={true}/>}</td>
           <td></td>
           <td></td>
           <td></td>

           </tr>
       </tbody>
   </reactstrp.Table> 


        </div>
                    
    )

    }

  }
}

export default LedgerAccountDetails; 
