
import React from 'react'
import { connect } from 'react-redux'
import { Layout,Avatar, Menu, Breadcrumb,Button,Row, Col, Divider,Card,Dropdown,Switch,
    message, Tooltip,InputNumber,Table,Popover,Modal,Image,Form,Select,Spin,notification} from 'antd';
import { Drawer, List,Alert,Affix } from 'antd';

import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LaptopOutlined,
  NotificationOutlined,
  SettingOutlined,
  AuditOutlined,
  IdcardOutlined,
  BarsOutlined,
  ShopOutlined,
  DollarOutlined,
  PicLeftOutlined,
  LogoutOutlined,
  EditOutlined, 
  EllipsisOutlined,
  HomeOutlined,
  SnippetsOutlined,
  KeyOutlined,
  FilePdfFilled,
  RightCircleFilled,
  DollarCircleOutlined,
  AccountBookOutlined,
  FilePptOutlined,
  PayCircleOutlined,
  BarChartOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  BankFilled,
  AndroidOutlined,
  DeploymentUnitOutlined,
  GroupOutlined,
  AccountBookFilled,
  CalculatorFilled,
  FileFilled,
  MessageFilled,
  LoadingOutlined,
  FundViewOutlined,
  ArrowLeftOutlined,
  
} from '@ant-design/icons';
import { Badge, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem } from 'reactstrap';
import UIfx from 'uifx';

import { NavLink } from 'react-router-dom'
import { PageHeader, Descriptions } from 'antd';
import { Link,withRouter} from 'react-router-dom';
import * as actions from '../../store/actions/auth'
import axios from 'axios'
import UserAccountUpdateForm from '../components/UserAccountUpdateForm'
import WrappedPasswordResetForm from './PasswordResetForm'
import * as serverconfig from '../serverconn'
import * as serversocketconfig from '../serversocketconfig'

import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import ReconnectingWebSocket from 'reconnecting-websocket';
import tickAudio from '../../assets/sounds/that-was-quick.mp3';
import WrappedPasswordResetForcedForm from './PasswordResetForcedForm'

var CryptoJS = require("crypto-js");

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const style = { background: '#0092ff', padding: '8px 0' };
const { Meta } = Card;
const dateFormat = 'DD/MM/YYYY';
const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const tick = new UIfx(tickAudio,  {
  volume: 1.0, // number between 0.0 ~ 1.0
  throttleMs: 100
});

const pStyle = {
  fontSize: 16,
  lineHeight: '24px',
  display: 'block',
  marginBottom: 16,
};
var admin_notificationsocket = new ReconnectingWebSocket(serversocketconfig.socketurl+'/ws/notificationsocket/');
var noteresetsocket=new ReconnectingWebSocket(serversocketconfig.socketurl+'/ws/noteresetsocket/');


const DescriptionItem = ({ title, content }) => (
  <div
    className="site-description-item-profile-wrapper"
    style={{
      fontSize: 14,
      lineHeight: '22px',
      marginBottom: 7,
    }}
  >
    <p
      className="site-description-item-profile-p"
      style={{
        marginRight: 8,
        display: 'inline-block',
      }}
    >
      {title}:
    </p>
    {content}
  </div>
);

var bizuserid= ''
var instance_id= ''
var token= ''


class SaccoLayoutCollection extends React.Component{

  state = {
    collapsed: false,
    theme: 'dark',
    current: '1',
    titlecolor: '#fff',
    titlevisibility:"visible",
    userprofile:{},
    companyprofile:{},
    isToggle: null,
    visible: false,
    resetmodalvisible:false,
    updateformvisible:'none',
    userrights:{},
    date_today:moment().format(dateFormat).toString(),
    dashboardlocked:false,
    userbalancemodalvisible:false,
    userto:'',
    transferamount:0,
    datarequested:false,
    transfermode:'',
    safemanagertransfermode:'',
    pendingtransfers:[],
    numberofnotifications:0,
    notificationinfo:'',
    balanceacceptancemodalvisible:false,
    selectedtransferobject:{},
    acceptancestatus:'',


    shownotificationmodal:false,
    notifications:[],
    passwordchange_modal_visible:false,


  };

  // show the profile modal
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  // update user profile
  showUpdateForm=()=>{
    this.state.updateformvisible=="none"?
    this.setState({
      updateformvisible: "block",
    })
    :
    this.setState({
      updateformvisible: "none",
    })    
  }


  /////////////////////////////////////////////
  //PASSWORD RESET MODAL METHODS BEGIN HERE

  showpasswordresetModal = () => {
    this.setState({
      resetmodalvisible: true,
    });
  };

  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ resetmodalvisible: false });

  };


  //show user balance modal
  showuserbalancemodalvisible=()=>{
    this.setState({ userbalancemodalvisible: true });
  }

  hideuserbalancemodalvisible=()=>{
    this.setState({ userbalancemodalvisible: false });
  }

  //show hide balance acceptance modal
  openbalanceacceptancemodalmodal=(record)=>{
    this.setState({ acceptancestatus: ''})
    //console.log(record)
    this.setState({balanceacceptancemodalvisible: true });
    this.setState({selectedtransferobject:record})

  }

  closebalanceacceptancemodalmodal=()=>{
    this.setState({balanceacceptancemodalvisible: false });

  }


//component did mount
  componentDidMount(){
    if(localStorage.getItem("instance_id")){
      instance_id= localStorage.getItem("instance_id")
    }else{
      instance_id= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    //component mount method
    this.componentmountmethod();
    
  }
  

//component mount method
componentmountmethod=()=>{

  //get the user profile here
  axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}/`)
  .then(res => {
      this.setState({
        userprofile:res.data
      })

       //password change by force
       if(res.data.change_password===true){
        this.setState({passwordchange_modal_visible:true})
      }

   
  })

  axios.get(serverconfig.backendserverurl+`/api/app_profile/${1}`)
  .then(res => {
      this.setState({
        companyprofile:res.data
      })

  })


  axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
  .then(res => {
     console.log("data: "+res.data[0] )
      this.setState({
        userrights:res.data[0]
      })
  })

    //destroy all previous notifications
    notification.destroy()
    //check if admin user


      axios.get(`${serverconfig.backendserverurl}/api/adminnotification?read=${false}`)
      .then(res => {
  
          console.log(res.data)
        //now loop through the tasks
        res.data.map(
          (ntfy)=>{

            if (ntfy.title==="Online Order placing alert"){
              notification.open({
                top:60,
                duration:0,
                message:  String(ntfy.title),
                description:String(ntfy.message),
                icon: <MessageFilled style={{ color: "red"}} />,
                style:{color:'red'},
                type:'error'

              })

            }else{
              notification.open({
                top:60,
                duration:0,
                message:  String(ntfy.title),
                description:String(ntfy.message),
                icon: <MessageFilled style={{ color: "green" }} />,
                
              })

            }

        
            
            this.setState({numberofnotifications:Number(this.state.numberofnotifications)+1})
          })
  
          this.setState({notifications:res.data})
      })
  
  
      //observer the socket changes now
      admin_notificationsocket.onopen = () => {
        console.log("onopen for admin notification");
      // connection opened
      };

      admin_notificationsocket.onmessage = async (e) => {
          notification.destroy()
  
          // a message was received
          console.log("onmessage for admin:",e.data);
          const data = JSON.parse(e.data);
  
          this.setState({numberofnotifications:Number(this.state.numberofnotifications)+1})
  
          //play notification
          tick.play();
 
          if (data.title==="Online Order placing alert"){
            notification.open({
              top:60,
              duration:0,
              message:  data.title,
              description:data.message,
              icon: <MessageFilled style={{ color: "red"}} />,
              style:{color:'red'},
              type:'error'

            })

          }else{
            notification.open({
              top:60,
              duration:0,
              message:  data.title,
              description:data.message,
              icon: <MessageFilled style={{ color: "green" }} />,
              
            })

          }



      };


      //reset notes
       //observer the socket changes now
       noteresetsocket.onopen = () => {
        console.log("onopen for reset note");
      // connection opened
      };
  
     
  
      noteresetsocket.onmessage = async (e) => {
        notification.destroy()
        this.setState({notifications:[]})

        //get new notifications after reset
        axios.get(`${serverconfig.backendserverurl}/api/adminnotification?read=${false}`)
        .then(res => {
    
            console.log(res.data)
          //now loop through the tasks
          res.data.map(
            (ntfy)=>{



            if (ntfy.title==="Online Order placing alert"){
              notification.open({
                top:60,
                duration:0,
                message:  String(ntfy.title),
                description:String(ntfy.message),
                icon: <MessageFilled style={{ color: "red"}} />,
                style:{color:'red'},
                type:'error'
                
              })

            }else{
              notification.open({
                top:60,
                duration:0,
                message:  String(ntfy.title),
                description:String(ntfy.message),
                icon: <MessageFilled style={{ color: "green" }} />,
                
              })

            }

              
            })








    
            this.setState({notifications:res.data})
            this.setState({numberofnotifications:res.data.length})

        })

  
      };



}

  //method for changing color theme 
  changeTheme = value => {
    this.setState({
      theme: value ? 'dark' : 'light',
    });

    this.setState({
      titlecolor: value ? '#fff' : '#A9A9A9',
    });
    
  };

  //method for collupsing of the menu
  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
    this.setState({titlevisibility: collapsed ? "hidden" :"visible"});
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });

    this.setState({titlevisibility:"hidden"});

  };


  handleusertoChange= (e) => this.setState({ userto: e});
  handletransferamountChange= (e) => this.setState({ transferamount: e});
  handletransfermodeChange= (e) => this.setState({ transfermode: e});
  handlesafemanagertransfermodeChange= (e) => this.setState({ safemanagertransfermode: e});
  handleacceptancestatusChange= (e) => this.setState({ acceptancestatus: e});

   render(){

    return (
      <div>
        {
         this.props.isAuthenticated ?
         <Layout className="site-layout" >
            <PageHeader
              style={{backgroundColor: "white"}}
              ghost={true}
              tags={[
                <Image
                 width={100}
                 height={100}
                 src={this.state.companyprofile.company_logo}   
                 preview={false} 
                 />
              ]}
              extra={[
                <Badge  onClick={()=>{
                  this.setState({shownotificationmodal:true})

                }}  pill color="danger">{this.state.numberofnotifications}</Badge>,
                <Avatar size={30} icon={<UserOutlined />} alt="User Pic" src={serverconfig.backendserverurl+this.state.userprofile.profile_pic} />,
                <Dropdown.Button overlay={(    <Menu >
                  <Menu.Item key="1" onClick={this.props.logout}>
                    <LogoutOutlined />
                    Log out
                  </Menu.Item>
                  <Menu.Item key="2" onClick={this.showDrawer}>
                    <UserOutlined />
                    View Profile
                  </Menu.Item>
                  <Menu.Item key="3" onClick={this.showpasswordresetModal}>
                    <KeyOutlined />
                    Change Password
                  </Menu.Item>
            
                </Menu>)}>
                Hi, {this.state.userprofile.username}
                </Dropdown.Button>
              ]}
              >
          </PageHeader>
        

           <Content style={{ margin: '0 3px' }}>
             {
               this.state.notificationinfo===""?
               null
               :
               <NavLink >
                <Alert showIcon message={this.state.notificationinfo} type="info" onClick={()=>{this.showuserbalancemodalvisible()}} />
               </NavLink>
             }

             <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

             <div style={{display:'flex',flexDirection:'row'}}>
              {
              this.props.location.pathname==='/'?
              null:
              <Affix>

              <Tooltip title="Go to home">
              <HomeOutlined style={{display:'flex',fontSize: 30,marginRight:30}} onClick={()=>{
                this.props.history.push('/')      
                }}/>
              </Tooltip>
              </Affix>
               }

                {
                  this.props.location.pathname==='/'?
                  null:
                  <Affix>
                  <Tooltip title="Go back">
                  <ArrowLeftOutlined style={{display:'flex',fontSize: 30,marginRight:5}} onClick={()=>{
                    this.props.history.goBack()      
                    }}/>
                  </Tooltip>
                  </Affix>
                }

              </div>
              <br></br>
             
             {this.props.children}
             </div>
           </Content>
           <Footer style={{ textAlign: 'center' }}>Copyright © 2024 eats.biz</Footer>
         </Layout>
         :
         null
        }

        <Drawer
          title="Account Profile"
          width={520}
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <p style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>      
             <Avatar
              size={100}
              icon={<UserOutlined />}
              alt="client logo"
              src={serverconfig.backendserverurl+this.state.userprofile.profile_pic}
              />
          </p>
          <Row>
            <Col span={12}>
              <DescriptionItem title="User Name" content={this.state.userprofile.username} />
            </Col>
            <Col span={12}>
              <DescriptionItem title="Email" content={this.state.userprofile.email} />
            </Col>
          </Row>         
          <p style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>      
             <Button type="primary" onClick={this.showUpdateForm}>
               {
                 this.state.updateformvisible=="none"?
                 "Open Update Form"
                 :
                 "Close Update Form"
               }
               
               </Button>
          </p>
          
          <div style={{display:this.state.updateformvisible}}>
           <UserAccountUpdateForm accountID={this.state.userprofile.id} username={this.state.userprofile.username} email={this.state.userprofile.email}/>       

          </div>
        </Drawer>
        

        <Drawer
          title="Password Change Form"
          width={520}
          placement="right"
          closable={false}
          onClose={this.onresetdrawerClose}
          visible={this.state.resetmodalvisible}
        >
            <WrappedPasswordResetForm />

        </Drawer>


        <Modal            
          visible={this.state.shownotificationmodal}
          title="Notifications"
          onCancel={()=>{
            this.setState({shownotificationmodal:false})

            //mark them as read
            this.state.notifications.map((item)=>{
              let form_data = new FormData();
              form_data.append('read', true);

              axios.put(serverconfig.backendserverurl+`/api/adminnotification/${item.id}/`, form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
              })
              .then(res => {
                console.log(res.data)
              })
            })

          }}
      
          footer={[
            <Button key="back" onClick={()=>{
              this.setState({shownotificationmodal:false})

              console.log(this.state.notifications)
              //mark them as read
              this.state.notifications.map((item)=>{
                console.log(item.id)
                let form_data = new FormData();
                form_data.append('read', true);

                axios.put(serverconfig.backendserverurl+`/api/adminnotification/${Number(item.id)}`, form_data, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then(res => {
                  console.log(res.data)
                })

              }) 

            }}>
              Cancel
            </Button>
            ]}
            >

          {this.state.notifications.map(
            (note)=>{
              return(<Alert closable style={{margin:6}} showIcon message={note.title} description={note.message} type="success" />);
              
            }

          )}
          
        </Modal>  


        <Modal   
          visible={this.state.passwordchange_modal_visible}
          title="Password update form"
      
          footer={[
          
            ]}
            >
              <h4 style={{color:'red'}}>Please update your password before you proceed any further</h4>
              <WrappedPasswordResetForcedForm />

        </Modal>  
 


  
    </div>
  
  );

  }
}


const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(
      actions.logout(),
      )
  }
}


export default withRouter(connect(null,mapDispatchToProps)(SaccoLayoutCollection)) ;
