import React,{ useRef } from 'react';
import axios from 'axios'
import { Table,Tooltip,Popconfirm,Switch,Popover,Input, Spin,Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Descriptions } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled,DeleteOutlined, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,SaveOutlined,
  MessageOutlined,PlusSquareFilled,EditOutlined,PlusCircleOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import * as primarycolor from '../primarycolor'

var CryptoJS = require("crypto-js");
const { RangePicker } = DatePicker;

const { TextArea } = Input;
const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;

var token= ''
var bizuserid=''
var username=''


message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});




class InvoiceAdjustmentForm extends React.Component {

  state = {
    restaurants: [],
    restaurant:'',
    property:'',
    quantity:0,
    bill_description:'',
    invoice_items_list:[],
    company_profile:{},
    property_obj:{},
    restaurant_obj:{},
    date:moment().format(dateFormat).toString(),

    dateone:moment().format(dateFormat).toString(),
    datetwo:moment().format(dateFormat).toString(),
   
    reason_for_adjustment:'',
    item_amount:0

  };

  callback(key) {
    console.log(key);
  }


  componentDidMount(){
    var invoiceID=this.props.invoiceID
    var payment_object=this.props.payment_object

    this.setState({item_amount:Number(payment_object.total)})

    this.comp_loaded()
   
  }

  comp_loaded=()=>{

    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
      bizuserid=localStorage.getItem("bizuserid")
   }else{
      token= ''
      bizuserid=''
   }

   if(localStorage.getItem("username")){
    username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
 
 }else{
    username=''
 }

   axios.defaults.headers={
     "Content-Type":"application/json",
     Authorization:`Token ${token}`
   }

  

    axios.get(`${serverconfig.backendserverurl}/api/accounts/${bizuserid}`)
    .then(res => {  
        this.setState({
          userprofile: res.data
        })
    })
    
  
    axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
        this.setState({vatpercentage:res.data.vatpercentage})
    })

    axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
    .then(res => {  
    this.setState({
      company_profile: res.data
    })
    })

  }

  

  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {
    return(
        <div style={{display:'flex',overflowX:'auto'}}>

          {this.state.datarequested === true?
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin size="large">
                <div className="content">
                  <h3>eats.biz</h3>
                </div>
              </Spin>
            </div>
          :

              <Card>

                <FormItem label="Reason for Adjustment">
                <TextArea
                    placeholder="Description."
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    value={this.state.reason_for_adjustment}
                    onChange={(val)=>{this.setState({reason_for_adjustment:val.target.value})}}
                  />
                  
                </FormItem>


                <FormItem label="Amount">
                  <Input name="amount" 
                  type="number" 
                  placeholder="Amount"
                  value={this.state.item_amount}
                  onChange={(val)=>{this.setState({item_amount:val.target.value})}}
                  />

                            
                </FormItem>

                <FormItem label="">

               < Button
               type='primary'  
                //style={{ color: primarycolor.primarycolor, fontSize: '30px',marginLeft:3 }}
                
                onClick={()=>{
                  if (this.state.reason_for_adjustment===""){
                    message.error("Please enter reason for adjustment")
                  }else if (this.state.item_amount<=0){
                    message.error("Please enter amount")
                  }else{
                    this.setState({datarequested:true})
               
                    let form_data = new FormData();
                    form_data.append("reason_for_adjustment", this.state.reason_for_adjustment);
                    form_data.append("item_amount", this.state.item_amount);
                    form_data.append("invoiceID", this.props.invoiceID);
                    form_data.append("item_id", this.props.payment_object.key);
    
                    //Now submit sale data to database
                    axios
                      .post(
                        serverconfig.backendserverurl +
                          "/customqueries/update_invoiceItem",
                        form_data,
                        {
                          headers: {
                            "content-type": "multipart/form-data",
                          },
                        }
                      )
                      .then((res) => 
                        {
                          message.info(res.data.message)
                        this.setState({ datarequested: false });
                         //resfresh 
                        this.props.onRefresh()
                     
                      })
                      .catch((error) => console.log(error));

                  }

                  //rest
                  this.setState({reason_for_adjustment:''})
                  this.setState({item_amount:0})

                }}
                
                >
                  UPDATE ITEM
                 </Button>
               
                </FormItem>
          
          </Card>
         
           } 
        </div>
    )
  }
}

export default InvoiceAdjustmentForm; 
