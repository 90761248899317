import React,{ useRef } from 'react';
import axios from 'axios'
import { Table, Input,Card, Button,Collapse,Spin,Avatar,Tooltip,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined,DownloadOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
import '../tablestyle.css'; // Create this CSS file for custom styles
import ReactExport from "@ibrahimrahmani/react-export-excel";
import TillSheetReport_General from "./TillSheetReportView_General";
import LedgerReport from "./LedgerReportView";
import TrialBalanceReport from "./TrialBalanceView";
import IncomeStatementReport from "./IncomeStatementView";
import CashFlowReport from "./CashFlowReportView";
import BalanceSheetReport from "./BalanceSheetView";


var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { TabPane } = Tabs;


const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


var token= ''
var sacco= ''
var bizuserid= ''


class FinancialReportsView extends React.Component {

  state = {
   datarequested:true,
   date:moment().format(dateFormat).toString(),
   balancesheet_report_assets:[],

   balancesheet_report_liability:[],
   balancesheet_report_equity:[],
   AllTotals:{},

   ledeger_details_model_visible:false,
   selected_record:{},
   userrights:{},


  };


  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        sacco= ''
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }



    //datarequested
    //this.setState({datarequested:false})

    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
       console.log("data: "+res.data[0] )
        this.setState({
          userrights:res.data[0]
        })
 
        this.setState({datarequested:false})
 
    })
  
}




  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {
    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
             <Divider></Divider>

            <Tabs defaultActiveKey="1" onChange={()=>{}}>
            <TabPane tab="Journals" key="001" >
              <TillSheetReport_General />

            </TabPane>


            <TabPane tab="Ledger" key="002" >
              <LedgerReport />

            </TabPane>

            <TabPane tab="Trial Balance" key="003" >
              <TrialBalanceReport />
              
              </TabPane>


            <TabPane tab="Income Statement" key="004" >
              <IncomeStatementReport />
              
              </TabPane>


            <TabPane tab="Cashflow / Cashbook Report" key="090805" >
              <CashFlowReport />
            
            </TabPane>

            <TabPane tab="Balance Sheet" key="005" >
              <BalanceSheetReport />
            
            </TabPane>

            </Tabs>

        </div>

    )

    }

  }
}

export default FinancialReportsView; 
