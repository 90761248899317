import React from 'react';
import { Form, Input, Button,Spin,message } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class RestaurantCategoryDetailView extends React.Component {

  state = {
    category_name:'' ,  
    description:'',
    datarequested:false,
  }

  componentDidMount(){
    this.compo_loaded()
  
  }


  compo_loaded=()=>{
    this.setState({datarequested:true})

    var categoryID=this.props.categoryID

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    var categoryID=this.props.categoryID
    axios.get(`${serverconfig.backendserverurl}/api/restaurant_categories/${categoryID}`)
    .then(res => {  
        this.setState({category_name:res.data.category_name})
        this.setState({description:res.data.description})
        this.setState({datarequested:false})
    })


  }




  //submit button pressed
  handleFormSubmit=(event) =>{
    var categoryID=this.props.categoryID
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('category_name', this.state.category_name);
    form_data.append('description', this.state.description);

      axios.put(serverconfig.backendserverurl+`/api/restaurant_categories/${categoryID}/`, form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>  {
      message.info("Updated successfully")
      this.setState({datarequested:false})
      this.compo_loaded()

      this.props.onrefresh()


    }   

    )
    .catch(error => console.log(error))

  }

  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Category name"
       
      >
        <Input name="category_name" 
         placeholder="Put a name here."
         value={this.state.category_name} 
         onChange={(val)=>{this.setState({category_name:val.target.value})}} />
      </FormItem>


{/*      <FormItem label="Description"
      >
        <Input name="description" 
         placeholder="Put a description here."
         value={this.state.description} 
         onChange={(val)=>{this.setState({description:val.target.value})}} />
      </FormItem> */}
          
     
      <FormItem>
        <Button  type="primary" htmlType="submit">Update</Button>
      </FormItem>
       </Form>
        </div>
      );
    }

  }

}


export default RestaurantCategoryDetailView;



