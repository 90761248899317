import React from 'react';
import { Form, Input, Button,Spin,Image,Switch,Select, message, Divider,Popover,Popconfirm,DatePicker } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined,DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'
import * as primarycolor from '../primarycolor'

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;

var token= ''
var username=''


class RiderDetails extends React.Component {

  state = {
    name:'' ,  
    about:'',
    datarequested:false,
    restaurants:[],
    exclusive_restaurant:'',
    category:'',

    logo:null,
    logo_file:'',
    email:'',
    phone:'',

    area:'',
    assigned_hub:'',

    hubs:[],

    address:'',
    map_url:'',

    opening_time:0,
    closing_time:0,

    available:false,
    pickup_on:false,
    date_ofcreation:moment().format(dateFormat).toString(),
    scheduled_offdays:[],

    all_weeklydays:[
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ]


  }


  componentDidMount(){
    this.compo_reloaded()

  }


  compo_reloaded=()=>{

    this.setState({datarequested:true})


    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("username")){
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
   }else{
      username=''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/restaurants/`)
    .then(res => {
        this.setState({
          restaurants:res.data
        })
       // this.setState({datarequested:false})
    })

   
    axios.get(serverconfig.backendserverurl+`/api/hubs/`)
    .then(res => {
        this.setState({
          hubs:res.data
        })
       // this.setState({datarequested:false})

    })


    var riderID=this.props.riderID
    axios.get(`${serverconfig.backendserverurl}/api/riders/${riderID}`)
    .then(res => {  

        this.setState({name:res.data.name})
        this.setState({exclusive_restaurant:res.data.exclusive_restaurant})
        //this.setState({date_ofcreation:res.data.date_ofcreation})
        this.setState({phone:res.data.phone})
        this.setState({available:res.data.available})

        this.setState({assigned_hub:res.data.assigned_hub})
        this.setState({scheduled_offdays:res.data.scheduled_offdays!=null?JSON.parse(res.data.scheduled_offdays):[]} )

        this.setState({datarequested:false})

    })




  }

      //check if account already added
      isdateAlreadyExists = (my_date, account_list) => {
        return account_list.some((item) => item === my_date);
      };


      disabledDate = (current) => {
      // Can not select days before today and today
      return current && current < moment().startOf('day');
      };




    //capitalize fistrt letter
    capitalizeFirstLetter=(word)=> {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    if (this.state.phone===""){
      message.info("Please enter contact")
    }else{
      console.log("EXhub: "+this.state.exclusive_restaurant)
      console.log("Hub : "+this.state.assigned_hub)

       var riderID=this.props.riderID

        let form_data = new FormData();
        form_data.append('name', this.state.name);

        if (this.state.exclusive_restaurant!="" && this.state.exclusive_restaurant!=null && this.state.exclusive_restaurant!="null"){
          form_data.append('exclusive_restaurant', this.state.exclusive_restaurant);
        }

        form_data.append('date_updated',this.state.date_ofcreation);
        form_data.append('phone', this.state.phone);
        form_data.append('created_by', username);
        form_data.append('available', this.state.available);

        if (this.state.assigned_hub!="" && this.state.assigned_hub!=null && this.state.assigned_hub!="null"){
          form_data.append('assigned_hub', this.state.assigned_hub);
        }else{
          form_data.append('assigned_hub', null);
        }

        form_data.append('scheduled_offdays', JSON.stringify(this.state.scheduled_offdays));

        axios.put(serverconfig.backendserverurl+`/api/riders/${riderID}/`, form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(res =>{

          //update exclusive
          if (this.state.exclusive_restaurant!="" && this.state.exclusive_restaurant!=null && this.state.exclusive_restaurant!="null"){
            console.log("Updated")
          }else{

            let form_data_1 = new FormData();
            form_data_1.append('rider_id', riderID);
            axios.post(serverconfig.backendserverurl+`/customqueries/update_exclusive_res_non`, form_data_1, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
            .then(res =>{
              console.log("Updated no exclusive")
            })
          }

          //data requeted
          this.setState({datarequested:false})
          //refrsh
          this.props.onRefresh()



        })
        .catch(error => console.log(error))

    }


  }
  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Rider Name" >
        <Input name="name" 
         placeholder="Put a name here."
         value={this.state.name} 
         onChange={(val)=>{this.setState({name:val.target.value})}} />
      </FormItem>



      <FormItem label="Phone">
          <PhoneInput
            countrySelectProps={{ unicodeFlags: true }}
            defaultCountry="UG"
            placeholder="Enter phone number"
            value={this.state.phone} onChange={(val)=>{this.setState({phone:val})}}/>
      </FormItem> 


      <FormItem label="Assigned Hub" >
            <Select 
            placeholder="Assigned Hub" 
            value={this.state.assigned_hub} 
            onChange={(val)=>{this.setState({assigned_hub:val})}} 
            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}    
            >
            {this.state.hubs.map(
            (cat)=>(
              <Option value={cat.id}>{cat.name}</Option>
            ))}
            </Select>
      </FormItem>


      <FormItem 
        label="Exclusive Restaurant">
        <Select 
        placeholder="Exclusive Restaurant" 
        value={this.state.exclusive_restaurant} 
        onChange={(val)=>{this.setState({exclusive_restaurant:val})}} 
        showSearch
        optionFilterProp="children"
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onSearch={this.onSearch}    
        >
        <Option value={''}>No exclusive restaurant</Option>
        {this.state.restaurants.map(
        (cat)=>(
          <Option value={cat.id}>{cat.name}</Option>
        ))}
        </Select>
      </FormItem>

      <FormItem name="available" label="Available" >
              <Switch
                  checked={this.state.available}
                  onChange={val=>{this.setState({available:!this.state.available})}}
                  checkedChildren="YES"
                  unCheckedChildren="NO"
                  />
      </FormItem>
      <Divider></Divider>
      <h5>Rider Off Days</h5>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignSelf: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
          {
              this.state.all_weeklydays.map((day)=>{
                return (
                  <span style={{display:'flex',
                  justifyContent:'center',
                  width:200,
                  border: `1px solid ${primarycolor.primarycolor}`,
                  margin:1,
                  backgroundColor:this.isdateAlreadyExists(day,this.state.scheduled_offdays)===true?'yellow':'#fff'

                  }}

                  onClick={()=>{
                    if (this.isdateAlreadyExists(day,this.state.scheduled_offdays)===true){
                      this.setState({ scheduled_offdays:  [...this.state.scheduled_offdays.filter(todo => todo
                        !==day)]});
                      message.info("Removed")
                    }else{
                      this.setState({scheduled_offdays: [...this.state.scheduled_offdays,day]});
                      message.info("Added")
                    }

                  }}
                  >
                    {this.capitalizeFirstLetter(day)}

                  </span>

                )
              })
        }

     </div>
      

      <Divider></Divider>

      <FormItem>
        <Button  type="primary" htmlType="submit">Update</Button>
      </FormItem>
       </Form>
        </div>
      );
    }

  }

}


export default RiderDetails;



