import React, { useState, useEffect, lazy } from "react";
import axios from "axios";
import { Slider, Statistic, Tabs, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { Line, Pie, Bar } from "react-chartjs-2";
import {
  Input,
  Collapse,
  Card,
  Form,
  DatePicker,
  Select,
  Space,
  Typography,
  Divider,
  InputNumber,
  message,
  Spin,
} from "antd";
import { Col, Row } from "reactstrap";
import { CustomTooltips } from "@coreui/coreui-plugin-chartjs-custom-tooltips";
import { getStyle, hexToRgba } from "@coreui/coreui/dist/js/coreui-utilities";
import * as serverconfig from "../serverconn";
import * as serversocketconfig from "../serversocketconfig";
import { MdShoppingCart } from "react-icons/md";

import CurrencyFormat from "react-currency-format";
import Highlighter from "react-highlight-words";
import Websocket from "react-websocket";
import { Link } from "react-router-dom";
import ReconnectingWebSocket from "reconnecting-websocket";
import { NavLink } from "react-router-dom";
import * as primarycolor from "../primarycolor";

import FinancialReportsView from "./FinancialReportsView";
import SmsUsageStatementReport from "./smsusagestatementreport";
import Payments from "./PaymentsView";
import All_settings from "./All_settings";
import Statistics_page from "./Statistics_page";
import Restaurants_Tab from "./Restaurants_Tab";
import Areas_Tab from "./Areas_Tab";
import RiderList from "./RiderListView";
import ClientList from "./ClientListView";
import Orders_Tab from "./Orders_Tab";
import SentEmailsList from './SentEmailsListView' 

import InvoiceList from "./InvoicesListView";
import { withGetScreen } from "react-getscreen";

var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const style = {
  background: "#0092ff",
  padding: "8px 0",
  borderRadius: "7px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};
const dateFormat = "DD/MM/YYYY";
const yeardateFormat = "YYYY";
const monthdateFormat = "MM/YYYY";

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem = Form.Item;
const { Option } = Select;

var bizuserid = "";
var instance_id = "";
var token = "";

class MainBranch_Dashboard extends React.Component {
  state = {
    companyprofile: {},
    userprofile: {},
    userrights:{},

  };

  callback = (key) => {
    console.log(key);
    console.log(this.state.yearlymonthlysales);
  };

  componentDidMount() {
    if (localStorage.getItem("instance_id")) {
      instance_id = localStorage.getItem("instance_id");
    } else {
      instance_id = "";
    }

    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      token = "";
    }

    if (localStorage.getItem("bizuserid")) {
      bizuserid = CryptoJS.AES.decrypt(
        localStorage.getItem("bizuserid"),
        "my-secret-key@1234"
      ).toString(CryptoJS.enc.Utf8);
    } else {
      bizuserid = "";
    }

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    axios
      .get(serverconfig.backendserverurl + `/api/accounts/${bizuserid}`)
      .then((res) => {
        this.setState({
          userprofile: res.data,
        });

        this.setState({ datarequested: false });
      });

    axios
      .get(serverconfig.backendserverurl + `/api/app_profile/${1}`)
      .then((res) => {
        this.setState({
          companyprofile: res.data,
        });
      });


      axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
      .then(res => {
         console.log("data: "+res.data[0] )
          this.setState({
            userrights:res.data[0]
          })
      })

  }

  render() {
    if (this.state.datarequested === true) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          >
            <Spin indicator={antIcon} />
          </div>
        );
      } else {
        return (
        <div className="animated fadeIn">
          <Tabs
            defaultActiveKey="1"
            onChange={() => {}}
            tabPosition={this.props.isMobile() ? "bottom" : "top"}
          >
           
           {
            this.state.userrights.view_orders===true?
            <TabPane tab="Orders " key="001121">
            <Orders_Tab />
            </TabPane>
            :
            null
           }
         
         {
            this.state.userrights.view_restaurants===true?
            <TabPane tab="Restaurants" key="00001123">
              <Restaurants_Tab />
            </TabPane>
              :
              null
             }


            {
             this.state.userrights.view_areas===true?
            <TabPane tab="Areas" key="00636001123">
              <Areas_Tab />
            </TabPane>
              :
              null
             }

            { 
              this.state.userrights.view_riders===true?
            <TabPane tab="Riders" key="0063608901123">
              <RiderList />
            </TabPane>
               :
               null
              }

             { 
              this.state.userrights.view_clients===true?
              <TabPane tab="Customers" key="00693933608901123">
              <ClientList />
              </TabPane>
              :
              null
             }


            {/**compulsory */}

            { 
              this.state.userrights.view_invoices===true?
            <TabPane tab="Billing" key="00005551123">
              <InvoiceList />
            </TabPane>
               :
               null
              }


            { 
              this.state.userrights.view_otherpayments===true?
              <TabPane tab="Payments" key="003">
                <Payments />
              </TabPane>
              :
              null
             }
            


            { 
              this.state.userrights.view_financialreports===true?
              <TabPane tab="Financial Reports" key="0022004">
                <FinancialReportsView />
              </TabPane>
               :
               null
              }



              {
                this.state.userprofile.branch_manager===true?
                <TabPane tab="All Settings " key="004">
                <All_settings />
                </TabPane>
                :
                null
              }


              {   
              this.state.userrights.view_sentemails===true?
               <TabPane tab="Sent Emails " key="012201">
                <SentEmailsList />
                </TabPane>
                :
                null
              }

              {  
              this.state.userrights.view_statistics===true?
            <TabPane tab="Statistics " key="001">
              <Statistics_page />
            </TabPane>
              :
              null
            }

            
          </Tabs>
        </div>
      );
    }
  }
}

export default withGetScreen(MainBranch_Dashboard);
