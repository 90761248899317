import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
//import 'antd/dist/antd.css'; 
import 'antd/dist/result.css';

import { connect } from 'react-redux'
import * as actions from './store/actions/auth'
import { BrowserRouter as Router } from 'react-router-dom'

import LoginLayout from './views/LoginLayout'
import Login from './views/Login';
import SaccoLayoutCollection from './views/containers/SaccoslayoutCollectionBranch'

import SaccoBaseRouter from './saccoroutes'
import MyProvider from './MyProvider'

// Containers

class App extends Component {

  componentDidMount(){
    this.props.onTryAutoSignup();
  }
  

  render() {
    return (
      this.props.isAuthenticated ?
      <Router>
       <MyProvider>
          <SaccoLayoutCollection {...this.props}>
            <SaccoBaseRouter/>
          </SaccoLayoutCollection>
      </MyProvider>
      </Router>
         
      :
      <Router>
      <LoginLayout>
        <Login />
      </LoginLayout>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.token !== null
  }
}

const mapDispatchToProps = dispatch =>{
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
