import React from 'react';
import { Form, Input, Button,Spin,message } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class RestaurantCategoryForm extends React.Component {

  state = {
    category_name:'' ,  
    description:'',
    datarequested:false,
  }

  componentDidMount(){
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('category_name', this.state.category_name);
    form_data.append('description', this.state.description);

      axios.post(serverconfig.backendserverurl+'/api/restaurant_categories/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>  {
      message.info("Success")
      this.setState({datarequested:false})
      this.props.onrefresh()

    }   
,    
    )
    .catch(error => console.log(error))

  }

  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Category Name"
        name="category_name"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="category_name" 
         placeholder="Category Name"
         value={this.state.category_name} 
         onChange={(val)=>{this.setState({category_name:val.target.value})}} />
      </FormItem>


  {  /*    <FormItem label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: 'Please input description',
          },
        ]}
      >
        <Input name="description" 
         placeholder="Put a description here."
         value={this.state.description} 
         onChange={(val)=>{this.setState({description:val.target.value})}} />
      </FormItem>
      */
      }          
     
      <FormItem>
        <Button  type="primary" htmlType="submit">Create</Button>
      </FormItem>
       </Form>
        </div>
      );
    }

  }

}


export default RestaurantCategoryForm;



