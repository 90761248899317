import React from 'react';
import { Form, Input, Button,Spin,Image,Switch,Select, message } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;

var token= ''
var username=''


class RestaurantMultiBranchForm extends React.Component {

  state = {
    name:'' ,  
    about:'',
    datarequested:false,
    ugdistricts:[],
    district:'',
    category:'',
    restaurant_categories:[],

    logo:null,
    logo_file:'',


    email:'',
    phone:'',

    area:'',
    hub:'',

    hubs:[],
    areas:[],

    address:'',
    map_url:'',

    opening_time:0,
    closing_time:0,

    delivery_on:false,
    pickup_on:false,
    date_ofcreation:moment().format(dateFormat).toString(),

    service_fee_perorder:0,
    weekly_subscription:0,
    rider_management_fee:0,

    restaurant_banner:null,
    restaurant_banner_file:'',

    //new
    active:false,
    online_order_link:''


  }


  componentDidMount(){
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("username")){
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
   }else{
      username=''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    this.setState({datarequested:false})

  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    if (this.state.phone===""){
      message.info("Please enter contact")
    }else{

        let form_data = new FormData();
        form_data.append('name', this.state.name);
        form_data.append('about', this.state.about);

        form_data.append('email', this.state.email);
        form_data.append('phone', this.state.phone);

        this.state.logo==null?
        console.log("No logo file")
        :
        form_data.append('logo', this.state.logo,this.state.logo?this.state.logo.name:"");

        this.state.restaurant_banner==null?
        console.log("No restaurant_banner file")
        :
        form_data.append('restaurant_banner', this.state.restaurant_banner,this.state.restaurant_banner?this.state.restaurant_banner.name:"");

        form_data.append('active', this.state.active);
        form_data.append('online_order_link', this.state.online_order_link);

        axios.post(serverconfig.backendserverurl+'/api/restaurant_multibranch/', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(res =>     this.setState({datarequested:false})
      ,    window.location.reload(false)
        )
        .catch(error => console.log(error))

    }

  }
  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Restaurant Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="name" 
         placeholder="Put a name here."
         value={this.state.name} 
         onChange={(val)=>{this.setState({name:val.target.value})}} />
      </FormItem>


      <FormItem label="About"
        name="about"
        rules={[
          {
            required: true,
            message: 'Please input about',
          },
        ]}
      >
        <TextArea 
         name="about" 
         placeholder="Put a about here."
         value={this.state.about} 
         onChange={(val)=>{this.setState({about:val.target.value})}}
         autoSize={{ minRows: 2, maxRows: 6 }}
         />
      </FormItem>

      <div style={{display:'flex',flexDirection:'row'}}>

      <FormItem label="Logo">
          <Input name="companylogo" type="file" accept="image/png, image/jpeg" placeholder="Company logo" 
          
          onChange={(e) =>{
          if(e.target.files[0]){
            this.setState({
              logo_file: URL.createObjectURL(e.target.files[0])
            })
          }
          this.setState({ logo: e.target.files[0]})

          } }/>
      </FormItem> 

      {this.state.logo!=null || this.state.logo_file!=""?
        <Image
        width={50}
        height={50}
        src={this.state.logo_file}    
        />
        :
        null
        }

      </div>


      <div style={{display:'flex',flexDirection:'row'}}>

        <FormItem label="Banner">
            <Input name="banner" type="file" accept="image/png, image/jpeg" placeholder="Banner" 
            
            onChange={(e) =>{
            if(e.target.files[0]){
              this.setState({
                restaurant_banner_file: URL.createObjectURL(e.target.files[0])
              })
            }
            this.setState({ restaurant_banner: e.target.files[0]})

            } }/>
        </FormItem> 

        {this.state.restaurant_banner!=null || this.state.restaurant_banner_file!=""?
          <Image
          width={50}
          height={50}
          src={this.state.restaurant_banner_file}    
          />
          :
          null
          }

        </div>


      <FormItem
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input email',
          },
        ]}
      
      label="Company Email">
          <Input name="companyemail" type="email"  placeholder="company@email.com" value={this.state.email} onChange={(val)=>{this.setState({email:val.target.value})}} />
      </FormItem> 


      <FormItem label="Phone">
          <PhoneInput
            countrySelectProps={{ unicodeFlags: true }}
            defaultCountry="UG"
            placeholder="Enter Phone"
            value={this.state.phone} onChange={(val)=>{this.setState({phone:val})}}/>
      </FormItem> 

      <h3>Online presence</h3>
        <FormItem name="active" label="Online Ordering Active" >
                <Switch
                    checked={this.state.active}
                    onChange={val=>{this.setState({active:!this.state.active})}}
                    checkedChildren="YES"
                    unCheckedChildren="NO"
                    />
        </FormItem>

        <FormItem label="Online order link">
        <Input name="online_order_link" 
         placeholder="online_order_link"
         value={this.state.online_order_link} 
         onChange={(val)=>{this.setState({online_order_link:val.target.value})}} />
       </FormItem>


      <FormItem>
        <Button  type="primary" htmlType="submit">Create</Button>
      </FormItem>
       </Form>
        </div>
      );
    }

  }

}


export default RestaurantMultiBranchForm;



