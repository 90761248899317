import React,{ useRef } from 'react';
import axios from 'axios'
import { Table,Tooltip,Popconfirm,Switch,Popover,Input, Spin,Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Descriptions } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled,DeleteOutlined, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,SaveOutlined,
  MessageOutlined,PlusSquareFilled,EditOutlined,PlusCircleOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import * as primarycolor from '../primarycolor'

var CryptoJS = require("crypto-js");
const { RangePicker } = DatePicker;

const { TextArea } = Input;
const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;

var token= ''
var bizuserid=''
var username=''


message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


class ReceiptToPrint extends React.Component {


  state = {
    companyprofile:{},
    date:moment().format(dateFormat).toString(),

  };

  componentDidMount(){

    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
      bizuserid=localStorage.getItem("token")
   }else{
      token= ''
      bizuserid=''
   }

   axios.defaults.headers={
     "Content-Type":"application/json",
     Authorization:`Token ${token}`
   }


    axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }


  render() {
    return (
    <div style={{padding:20}}>
        
     </div>
    );
  }
}

class InvoiceForm extends React.Component {



  state = {
    restaurants: [],
    restaurant:'',
    property:'',
    quantity:0,
    bill_description:'',
    invoice_items_list:[],
    company_profile:{},
    property_obj:{},
    restaurant_obj:{},
    date:moment().format(dateFormat).toString(),

    dateone:moment().format(dateFormat).toString(),
    datetwo:moment().format(dateFormat).toString(),

    invoice_type:'DeliveryFees Invoice',
    invoice_details:'',
    subscription_item:'',
    item_name:'',
    item_amount:0

  };

  callback(key) {
    console.log(key);
  }


  componentDidMount(){
    this.comp_loaded()
   
  }

  comp_loaded=()=>{

    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
      bizuserid=localStorage.getItem("bizuserid")
   }else{
      token= ''
      bizuserid=''
   }

   if(localStorage.getItem("username")){
    username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
 
 }else{
    username=''
 }

   axios.defaults.headers={
     "Content-Type":"application/json",
     Authorization:`Token ${token}`
   }

   axios.get(serverconfig.backendserverurl+`/api/restaurants/`)
   .then(res => {
       this.setState({
        restaurants:res.data
       })
   })

    axios.get(`${serverconfig.backendserverurl}/api/accounts/${bizuserid}`)
    .then(res => {  
        this.setState({
          userprofile: res.data
        })
    })
    
  
    axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
        this.setState({vatpercentage:res.data.vatpercentage})
    })

    axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
    .then(res => {  
    this.setState({
      company_profile: res.data
    })
    })

  }

  

  //handle data submission
  handleDatasubmission=()=>{
  if (this.state.invoice_items_list.length===0){
    message.error("There are no items in the list")
  }else if(this.state.invoice_details===''){
    message.error("Please enter details")

  }else{
    var period_range="From "+String(this.state.dateone)+" to "+String(this.state.datetwo)

    this.setState({requestsent:true})

    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('invoice_items_list', JSON.stringify(this.state.invoice_items_list));
    form_data.append('restaurant', this.state.restaurant);
    form_data.append('username', username);
    form_data.append('period_range', period_range);

    form_data.append('dateone', this.state.dateone);
    form_data.append('datetwo', this.state.datetwo);
    form_data.append('totalAmount', this.calculate_Total());
    form_data.append('invoice_details', this.state.invoice_details);
    form_data.append('invoice_type', this.state.invoice_type);

    axios.post(serverconfig.backendserverurl+'/customqueries/invoicecreation', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then(res =>{
      this.setState({requestsent:false})
      message.info(res.data.message)

      this.comp_loaded()
      this.setState({invoice_items_list:[]})

      //refresh here mother
      this.props.onRefresh()

    })
  .catch(error => console.log(error))

  }

}


  //calculate total bill
  calculate_Total=()=>{
    var total =0
    this.state.invoice_items_list.map((item) => {
      total += Number(item.total);
    });

    return total;

  }



  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {
    return(
        <div style={{display:'flex',overflowX:'scroll'}}>

          {this.state.datarequested === true?
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin size="large">
                <div className="content">
                  <h3>eats.biz</h3>
                </div>
              </Spin>
            </div>
          :

          <Collapse defaultActiveKey={['1']} onChange={()=>{}}>
          <Panel header="INVOICE GENERATION FORM" key="1">

          <div style={{display: "none"}}>
          <ReceiptToPrint
          ref={el => (this.componentRef = el)} /> 
          </div>
          <Card>

          <reactstrp.Table bordered>
          <thead>
          <tr>

            {/**Order genarteion column */}
            <th>
            <div style={{display:'flex',flexDirection:'column'}}>
            
            <Form layout="vertical">
           
            <FormItem label="Select Restaurant To Invoice">
                <Select 
                placeholder="Restaurant To Invoice" 
                value={this.state.restaurant} 
                onChange={(val)=>{
                  this.setState({restaurant:val})
                    //p ownser object
                  axios.get(`${serverconfig.backendserverurl}/api/restaurants/${val}`)
                  .then(res => {  
                    this.setState({
                      restaurant_obj: res.data
                    })
                  })

                }} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}    
                >
                {this.state.restaurants.map(
                (cat)=>(
                  <Option value={cat.id}>{cat.name} {cat.system_ID}</Option>
                ))}
                </Select>
             </FormItem>


                {
                  this.state.restaurant===""?
                  null:
                  <FormItem label="Invoice Type">
                  <Select 
                  placeholder="Invoice Type" 
                  value={this.state.invoice_type} 
                  onChange={(val)=>{
                    this.setState({invoice_type:val})
                  }} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}    
                  >
                    <Option value={'DeliveryFees Invoice'}>{'DeliveryFees Invoice'}</Option>
                    <Option value={'PerOrderServiceFees Invoice'}>{'PerOrderServiceFees Invoice'}</Option>
                    <Option value={'Subscriptions Invoice'}>{'Subscriptions Invoice (Weekly,Monthly,Rider Mgt)'}</Option>
                    <Option value={'OnlineOrdersCommission Invoice'}>{'OnlineOrdersCommission Invoice'}</Option>
                    <Option value={'FreeText Invoice'}>{'FreeText Invoice'}</Option>
  
                  </Select>
               </FormItem>
  
                }


          {
            this.state.invoice_type==="FreeText Invoice"?
            null:
            <FormItem label="Bill Date Range">
              <RangePicker onChange={(date, dateString) =>{
              this.setState({ dateone: dateString[0]});
              this.setState({ datetwo: dateString[1]});
              }} format={dateFormat} 
              placeholder={['Start Date', 'End Date']}
            />
            </FormItem>
          }



          {/**Subscription type */}
          {
            this.state.invoice_type==="Subscriptions Invoice"?
                <div style={{display:'flex',flexDirection:'row'}}>
                <FormItem label="Subscription item">
                  <Select
                    placeholder="Item"
                    style={{ width: 200 }}
                    value={this.state.subscription_item}
                    onChange={(val) => {
                      this.setState({ subscription_item: val });

                    }}
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value={"weekly_subscription"}>Weekly Subscription </Option>
                    <Option value={"monthly_subscription"}>Monthly Subscription</Option>
                    <Option value={"rider_management_fee"}>Rider Management Fee</Option>
                  </Select>
                </FormItem>

                <FormItem label="Add To Invoice">

               < PlusCircleOutlined  
                style={{ color: primarycolor.primarycolor, fontSize: '30px',marginLeft:3 }}
                
                onClick={()=>{
                  if (this.state.subscription_item===""){
                    message.error("Please select subscription")
                  }else if (this.state.subscription_item==="weekly_subscription" && Number(this.state.restaurant_obj.weekly_subscription)<=0){
                    message.error("Weekly subscription not set")

                  }else if (this.state.subscription_item==="monthly_subscription" && Number(this.state.restaurant_obj.monthly_subscription)<=0){
                    message.error("Monthly subscription not set")

                  }else if (this.state.subscription_item==="rider_management_fee" && Number(this.state.restaurant_obj.rider_management_fee)<=0){
                    message.error("Monthly subscription not set")

                  }else  if (this.state.dateone === "" ||this.state.datetwo === "") {
                    message.error("Date range are missing");
                  
                  }else{
                    var period_range="From "+String(this.state.dateone)+" to "+String(this.state.datetwo)
                   
                    var item_name=''
                    var amount=0

                    if (this.state.subscription_item==="weekly_subscription"){
                      item_name="Weekly Software Subscription Fees "+period_range
                      amount=Number(this.state.restaurant_obj.weekly_subscription)
                    }else if (this.state.subscription_item==="monthly_subscription"){
                      item_name="Monthly Software Subscription Fees "+period_range
                      amount=Number(this.state.restaurant_obj.monthly_subscription)
                    }else if (this.state.subscription_item==="rider_management_fee"){
                      item_name="Rider Management Fees "+period_range
                      amount=Number(this.state.restaurant_obj.rider_management_fee)
                    }

                    const newreceiptitem={
                      key:(Number(this.state.invoice_items_list.length)+1),
                      item_name:item_name,
                      total:amount,
                     }
                
                    //add to the receipt item list
                    this.setState({
                      invoice_items_list: [...this.state.invoice_items_list, newreceiptitem]
                    });

                  }

                }}
                
                />
                </FormItem>
             </div>
          :
          null
          }


          {/**Free text invoice */}
          {
            this.state.invoice_type==="FreeText Invoice"?
                <div style={{display:'flex',flexDirection:'column'}}>
               
                <FormItem label="Item">
                <TextArea
                    placeholder="Item."
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    value={this.state.item_name}
                    onChange={(val)=>{this.setState({item_name:val.target.value})}}
                  />
                  
                </FormItem>

                <FormItem label="Amount">
                  <Input name="amount" 
                  type="number" 
                  placeholder="Amount"
                  value={this.state.item_amount}
                  onChange={(val)=>{this.setState({item_amount:val.target.value})}}
                  
                  />

                            
                </FormItem>


                <FormItem label="Add To Invoice">

               < Button
               type='primary'  
                //style={{ color: primarycolor.primarycolor, fontSize: '30px',marginLeft:3 }}
                
                onClick={()=>{
                  if (this.state.item_name===""){
                    message.error("Please enter item")
                  }else if (this.state.item_amount===0){
                    message.error("Please enter amount")
                  }else{

                    const newreceiptitem={
                      key:(Number(this.state.invoice_items_list.length)+1),
                      item_name:this.state.item_name,
                      total:this.state.item_amount,
                     }
                
                    //add to the receipt item list
                    this.setState({
                      invoice_items_list: [...this.state.invoice_items_list, newreceiptitem]
                    });

                  }


                  //rest
                  this.setState({item_name:''})
                  this.setState({item_amount:0})

                }}
                
                >
                  ADD
                 </Button>
               
                </FormItem>
             </div>
          :
          null
          }


          

          {/**Delivery fees invoice */}
          {
            this.state.invoice_type==="DeliveryFees Invoice"?
            <FormItem label="">
              <Button  type="primary" htmlType='button' 
                onClick={()=>{
  
                    let form_data = new FormData();
                    form_data.append("dateone", this.state.dateone);
                    form_data.append("datetwo", this.state.datetwo);
                    form_data.append("order_status", "Delivered");
                    form_data.append("restaurant_id", this.state.restaurant);
    
                    if (this.state.dateone === "" ||this.state.datetwo === "") {
                      message.error("Please dates are missing");
                    } else if (this.state.restaurant===""){
    
                      message.error("Please select restaurant")
                    }else {
                      this.setState({ datarequested: true });
                      this.setState({ clientorders: [] });
                      this.setState({ clientorders_placeholders: [] });
    
                      //Now submit sale data to database
                      axios
                        .post(
                          serverconfig.backendserverurl +
                            "/customqueries/get_delivered_orderforinvoice",
                          form_data,
                          {
                            headers: {
                              "content-type": "multipart/form-data",
                            },
                          }
                        )
                        .then((res) => {
                          this.setState({ datarequested: false });
                          this.setState({
                            invoice_items_list: JSON.parse(res.data.report),
                          });
                          
                        })
                        .catch((error) => console.log(error));
                  }
  
              }}>GENERATE INVOICE 
              
              </Button>
  
              </FormItem>
            :
            null
          }


          {/**Service fee invoice */}
          {
            this.state.invoice_type==="PerOrderServiceFees Invoice"?
            <FormItem label="">
              <Button  type="primary" htmlType='button' 
                onClick={()=>{

                  //CHECK IF PERORDER SERVICE FEE IS SET
                  if (Number(this.state.restaurant_obj.service_fee_perorder)<=0  ){
                    message.info("Perorder servicefee not set.")
                  }else{

                    let form_data = new FormData();
                    form_data.append("dateone", this.state.dateone);
                    form_data.append("datetwo", this.state.datetwo);
                    form_data.append("order_status", "Delivered");
                    form_data.append("restaurant_id", this.state.restaurant);
    
                    if (this.state.dateone === "" ||this.state.datetwo === "") {
                      message.error("Please dates are missing");
                    } else if (this.state.restaurant===""){
    
                      message.error("Please select restaurant")
                    }else {
                      this.setState({ datarequested: true });
                      this.setState({ clientorders: [] });
                      this.setState({ clientorders_placeholders: [] });
    
                      //Now submit sale data to database
                      axios
                        .post(
                          serverconfig.backendserverurl +
                            "/customqueries/get_delivered_orderforinvoice_servicefee",
                          form_data,
                          {
                            headers: {
                              "content-type": "multipart/form-data",
                            },
                          }
                        )
                        .then((res) => {
                          this.setState({ datarequested: false });
                          this.setState({
                            invoice_items_list: JSON.parse(res.data.report),
                          });
                          
                        })
                        .catch((error) => console.log(error));
                  }

                  }
  
  
              }}>GENERATE INVOICE 
              
              </Button>
  
              </FormItem>
            :
            null
          }


          {/**ONLINE ORDER COMMISSION */}
          {
            this.state.invoice_type==="OnlineOrdersCommission Invoice"?
            <FormItem label="">
              <Button  type="primary" htmlType='button' 
                onClick={()=>{

                  //CHECK IF ORDER PERECNTAGE IS SET
                  if (Number(this.state.restaurant_obj.onlineorder_commissionpercentage)<=0  ){
                    message.info("Online order commission percentage not set.")
                  }else{

                    let form_data = new FormData();
                    form_data.append("dateone", this.state.dateone);
                    form_data.append("datetwo", this.state.datetwo);
                    form_data.append("order_status", "Delivered");
                    form_data.append("restaurant_id", this.state.restaurant);
    
                    if (this.state.dateone === "" ||this.state.datetwo === "") {
                      message.error("Please dates are missing");
                    } else if (this.state.restaurant===""){
    
                      message.error("Please select restaurant")
                    }else {
                      this.setState({ datarequested: true });
                      this.setState({ clientorders: [] });
                      this.setState({ clientorders_placeholders: [] });
    
                      //Now submit sale data to database
                      axios
                        .post(
                          serverconfig.backendserverurl +
                            "/customqueries/get_delivered_orderforinvoice_onlineorder_commission",
                          form_data,
                          {
                            headers: {
                              "content-type": "multipart/form-data",
                            },
                          }
                        )
                        .then((res) => {
                          this.setState({ datarequested: false });
                          this.setState({
                            invoice_items_list: JSON.parse(res.data.report),
                          });
                          
                        })
                        .catch((error) => console.log(error));
                    }

                  }
  
  
              }}>GENERATE INVOICE 
              
              </Button>
  
              </FormItem>
            :
            null
          }

        
            </Form>
            
            <br></br>
            </div>

            </th>

            {/**Order summary column */}

            <th>
            <div style={{display:'flex',flexDirection:'column'}}>

              <Divider></Divider>
              <h3>Invoice Summary</h3>
            
              <h6 style={{fontWeight:'bolder'}}>
                TOTAL:{" "}
                {
                  <CurrencyFormat
                    value={this.calculate_Total()}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
              </h6>

              <FormItem label="Invoice Details">
                <Input name="invoice_details" 
                placeholder="Invoice Details or Week Number"
                value={this.state.invoice_details} 
                onChange={(val)=>{this.setState({invoice_details:val.target.value})}} />

              </FormItem>

               {
                this.state.invoice_items_list.length>0?
                  <FormItem label="">
                  <Button  type="primary" htmlType='button' 
                  onClick={()=>{
                    this.handleDatasubmission()
                  }}>SAVE INVOICE 
                  
                  </Button>
                  </FormItem>
                  :
                  null
                }

            </div>

            </th>
          </tr>
          </thead>
          </reactstrp.Table>

          <Divider></Divider>

          {/**Delivery fees invoice */}
          {
            this.state.invoice_type==="DeliveryFees Invoice" && this.state.invoice_items_list.length>0 ?
            <reactstrp.Table bordered>
            <thead>
              <tr>
                <th>NO</th>
                <th>DATE</th>
                <th>ID</th>
                <th>PHONE</th>
                <th>AREA</th>
                <th>STATUS</th>
                <th>DELIVERY FEE</th>
              </tr>
            </thead>
            <tbody>
              {this.state.invoice_items_list.map((item) => (
                <tr>
                  <td>{item.item_no}</td>
                  <td>{item.date}</td>
                  <td>{item.ordernumber}</td>
                  <td>{item.client_phone}</td>
                  <td>{item.area}</td>
                  <td>{item.order_status}</td>
                  <td className="align-right">
                    {
                      <CurrencyFormat
                        value={Number(item.total)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                  </td>
                  
                </tr>
              ))}
              <tr>
                <td style={{ fontWeight: "bolder" }}>TOTAL ( {this.state.invoice_items_list.length} )</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td className="align-right" style={{ fontWeight: "bolder" }}>
                  {
                    <CurrencyFormat
                      value={this.calculate_Total()}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                </td>
              </tr>
            </tbody>
          </reactstrp.Table>
          :
          null
          } 


          {/**Perorder Service fee invoice  */}   
          {
            this.state.invoice_type==="PerOrderServiceFees Invoice" && this.state.invoice_items_list.length>0 ?
            <reactstrp.Table bordered>
            <thead>
              <tr>
                <th>NO</th>
                <th>DATE</th>
                <th>ID</th>
                <th>PHONE</th>
                <th>SERVICE FEE</th>
                <th>AREA</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {this.state.invoice_items_list.map((item) => (
                <tr>
                  <td>{item.item_no}</td>
                  <td>{item.date}</td>
                  <td>{item.ordernumber}</td>
                  <td>{item.client_phone}</td>
                  <td className="align-right">
                    {
                      <CurrencyFormat
                        value={Number(item.total)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                  </td>
                  <td>{item.area}</td>
                  <td>{item.order_status}</td>
                </tr>
              ))}
              <tr>
                <td style={{ fontWeight: "bolder" }}>TOTAL ( {this.state.invoice_items_list.length} )</td>
                <td></td>
                <td></td>
                <td></td>
                <td className="align-right" style={{ fontWeight: "bolder" }}>
                  {
                    <CurrencyFormat
                      value={this.calculate_Total()}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </reactstrp.Table>
          :
          null
          } 


          {/**Online order commission */} 
          {
            this.state.invoice_type==="OnlineOrdersCommission Invoice" && this.state.invoice_items_list.length>0 ?
            <reactstrp.Table bordered>
            <thead>
              <tr>
                <th>NO</th>
                <th>DATE</th>
                <th>ID</th>
                <th>PHONE</th>
                <th>COMMISSION</th>
                <th>AREA</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {this.state.invoice_items_list.map((item) => (
                <tr>
                  <td>{item.item_no}</td>
                  <td>{item.date}</td>
                  <td>{item.ordernumber}</td>
                  <td>{item.client_phone}</td>
                  <td className="align-right">
                    {
                      <CurrencyFormat
                        value={Number(item.total)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                  </td>
                  <td>{item.area}</td>
                  <td>{item.order_status}</td>
                </tr>
              ))}
              <tr>
                <td style={{ fontWeight: "bolder" }}>TOTAL ( {this.state.invoice_items_list.length} )</td>
                <td></td>
                <td></td>
                <td></td>
                <td className="align-right" style={{ fontWeight: "bolder" }}>
                  {
                    <CurrencyFormat
                      value={this.calculate_Total()}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </reactstrp.Table>
          :
          null
          } 



           {/**Subscriptions Invoice  */}   

           {
            this.state.invoice_type==="Subscriptions Invoice" && this.state.invoice_items_list.length>0 ?
            <reactstrp.Table bordered>
            <thead>
              <tr>
                <th>NO</th>
                <th>DESCRIPTION</th>
                <th>AMOUNT</th>
                <th>DELETE</th>
              </tr>
            </thead>
            <tbody>

              {this.state.invoice_items_list.map((item) => (
                <tr>
                  <td>{item.key}</td>
                  <td>{item.item_name}</td>
                  <td className="align-right">
                    {
                      <CurrencyFormat
                        value={Number(item.total)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                  </td>
                  <td>
                  <Popover content={<p>Click here to delete </p>} title="Delete">
                    <Popconfirm title="Sure to delete?" onConfirm={() => {
                    this.setState({ invoice_items_list:  [...this.state.invoice_items_list.filter(todo => todo.key
                      !==item.key)]});
                    }}>
                      <DeleteOutlined style={{color:'red'}} />
                    </Popconfirm>
                    </Popover>
                  </td>
                </tr>
              ))}
              <tr>
                <td style={{ fontWeight: "bolder" }}>TOTAL ( {this.state.invoice_items_list.length} )</td>
                <td></td>
                <td className="align-right" style={{ fontWeight: "bolder" }}>
                  {
                    <CurrencyFormat
                      value={this.calculate_Total()}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                </td>
                <td></td>
              </tr>
            </tbody>
            </reactstrp.Table>
            :
            null
            } 



            {/**Free text invoice */}
            {
            this.state.invoice_type==="FreeText Invoice" && this.state.invoice_items_list.length>0 ?
            <reactstrp.Table bordered>
            <thead>
              <tr>
                <th>NO</th>
                <th>DESCRIPTION</th>
                <th>AMOUNT</th>
                <th>DELETE</th>

              </tr>
            </thead>
            <tbody>

              {this.state.invoice_items_list.map((item) => (
                <tr>
                  <td>{item.key}</td>
                  <td>{item.item_name}</td>
                  <td className="align-right">
                    {
                      <CurrencyFormat
                        value={Number(item.total)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                  </td>
                  <td>
                  <Popover content={<p>Click here to delete </p>} title="Delete">
                    <Popconfirm title="Sure to delete?" onConfirm={() => {
                    this.setState({ invoice_items_list:  [...this.state.invoice_items_list.filter(todo => todo.key
                      !==item.key)]});
                    }}>
                      <DeleteOutlined style={{color:'red'}} />
                    </Popconfirm>
                    </Popover>
                  </td>
                </tr>
              ))}
              <tr>
                <td style={{ fontWeight: "bolder" }}>TOTAL ( {this.state.invoice_items_list.length} )</td>
                <td></td>
                <td className="align-right" style={{ fontWeight: "bolder" }}>
                  {
                    <CurrencyFormat
                      value={this.calculate_Total()}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                </td>
                <td></td>
              </tr>
            </tbody>
            </reactstrp.Table>
            :
            null
            } 

          </Card>
          </Panel>

          </Collapse>

           } 
        </div>
    )
  }
}

export default InvoiceForm; 
