import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,Tabs,Spin,message,DatePicker,Modal,Image,Form,Select,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,PrinterOutlined,LoadingOutlined } from '@ant-design/icons';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import Resizer from "react-image-file-resizer";
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import '../tablestyle.css'; // Create this CSS file for custom styles
import VouchersList from './VouchersListView'
import JournalEntryView from './JournalEntryView'
import VoucherCreateForm from '../components/VoucherCreateForm'


var CryptoJS = require("crypto-js");
var converter = require('number-to-words');
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;


var sacco= ''
var username=''
var token=''
var bizuserid = "";



function callback(key) {
  console.log(key);
}



class Payments extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    sheetitems: [],
    productunits:[],
    currenteditablecolumn: {},
    showeditableform:false,

    product_name:0,
    unit:'',
    sellingprice:0,
    buyingprice:0,
    discount_percentage:0,
    product_online:false,
    description:'',
    Photo:null,
    photofile:null,

    dateone:moment().format(dateFormat).toString(),
    datetwo:moment().format(dateFormat).toString(),
    userrights:{},


  };

  componentDidMount(){
    this.component_loaded()
   }


   component_loaded=()=>{
    this.setState({datarequested:true})

    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      username=''
   }



   if (localStorage.getItem("bizuserid")) {
    bizuserid = CryptoJS.AES.decrypt(
      localStorage.getItem("bizuserid"),
      "my-secret-key@1234"
    ).toString(CryptoJS.enc.Utf8);
  } else {
    bizuserid = "";
  }

   //set dataloading 

   axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
   .then(res => {
      console.log("data: "+res.data[0] )
       this.setState({
         userrights:res.data[0]
       })

       this.setState({datarequested:false})

   })

   }



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      
      return(
          <div>
            
            <Tabs defaultActiveKey="001" onChange={()=>{}}>
           
            {       
              this.state.userrights.create_otherpayments===true?
              <TabPane tab="Voucher Entry Form" key="001" >
              <VoucherCreateForm onRefresh={this.component_loaded} />
              </TabPane>
              :
              null
            }

          
            {       
             this.state.userrights.view_otherpayments===true?
            <TabPane tab="Payment Vouchers " key="002" >
            <VouchersList />
            </TabPane>
            :null
           }


            {       
              this.state.userrights.create_otherpayments===true?
              <TabPane tab="Journal Entry Form" key="003" >
              <JournalEntryView />
                </TabPane>
              :
              null
            }

            </Tabs>

          </div>
      )



    }


  }
}

export default Payments; 
