import React from 'react';
import RestaurantMenuCategoryForm from '../components/RestaurantMenuCategoryForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Tooltip,Popconfirm,message,Spin,Tabs,Switch,Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,PlusCircleOutlined,SwapOutlined,FundViewOutlined,LoadingOutlined,DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import RestaurantMenuCategoryDetail from './RestaurantMenuCategoryDetailView'
import RestaurantDishesList from './RestaurantDishesListView'
import * as primarycolor from '../primarycolor'
import ReactExport from "@ibrahimrahmani/react-export-excel";

var CryptoJS = require("crypto-js");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}
const { TabPane } = Tabs;

var token= ''

class RestaurantMenuCategoryList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    restaurant_menu_categories: [],
    datarequested:true,
    all_dishes:[],

    position_swap_model:false,
    current_menu:{},
    next_position:0

  };


  componentDidMount(){
    this.component_loaded()
    
}


component_loaded=()=>{
  this.setState({datarequested:true})

  var restaurantID=this.props.restaurantID

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/restaurant_menu_categories/?restaurant=${restaurantID}`)
    .then(res => {
        this.setState({
            restaurant_menu_categories:res.data
        })
        this.setState({datarequested:false})

    })



      let form_data = new FormData();
      form_data.append("restaurant_id", this.props.restaurantID);
      //Now submit sale data to database
      axios
        .post(
          serverconfig.backendserverurl +
            "/customqueries/getrestaurant_dishes",
          form_data,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.setState({ all_dishes: JSON.parse(res.data.report) });
        })
        .catch((error) => console.log(error));




}



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



  render() {
    const columns = [
      {
        title: 'Menu Position ',
        dataIndex: 'cat_no',
        key: 'id',
        render: (text,record) =>
          <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{fontSize:18,marginRight:3}}>{record.cat_no}</span>

            <Tooltip title="Click to swap position" placement="top">

            <SwapOutlined  
             style={{ color: primarycolor.primarycolor, fontSize: '30px' }}
             onClick={()=>{
              this.setState({current_menu:record})
              this.setState({position_swap_model:true})
          
            }} 
            
            />
            </Tooltip>

          </div>

      },
      {
        title: 'Name',
        dataIndex: 'category_name',
        key: 'id',
        ...this.getColumnSearchProps('category_name'),
      },
     
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'id',
      },

      {
        title: 'Online',
        dataIndex: 'online',
        key: 'id',
        render: (text,record) =>
          <p style={{display:'flex',flexDirection:'row'}}>
         <Switch
          checked={record.online}
          onChange={val=>{

            let form_data = new FormData();
            form_data.append('category_name', record.category_name);
            form_data.append('description', record.description);
            form_data.append('online',val);
                
            axios.put(serverconfig.backendserverurl+`/api/restaurant_menu_categories/${record.id}/`, form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
          .then(res => {
            this.component_loaded()
            message.info("Updated")
          })
          .catch(error => console.log(error))
        
          }}
          checkedChildren="Yes"
          unCheckedChildren="No"
          />

         </p>
  

      },
     
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p style={{display:'flex',flexDirection:'row'}}>

        <Popover content={<p>Click here to delete </p>} title="Delete">
         <Popconfirm title="Sure to delete?" onConfirm={
          () => {
            axios.delete(`${serverconfig.backendserverurl}/api/restaurant_menu_categories/${text}`).then(res =>{
              window.location.reload(false)
              message.info("successfully deleted") 
            }
            );
          }
        }>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>      
         </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="RESTAURANT MENU CATEGORIES" key="1">


            &nbsp;&nbsp;
                <ExcelFile
                  filename={
                    "eats.biz - Dishes for " +
                    this.props.restaurant_obj.name 
                  }
                  element={
                    <Button type="primary" icon={<DownloadOutlined />}>
                      Download All dishes (Excel)
                    </Button>
                  }
                >
                  <ExcelSheet
                    data={this.state.all_dishes}
                    name={"Dishes report"}
                  >
                    <ExcelColumn label="No" value="item_no" />
                    <ExcelColumn label="Name" value="name" />
                    <ExcelColumn
                      label="Description"
                      value="description"
                    />

                    <ExcelColumn label="Menu Category" value="menu_name" />

                    <ExcelColumn label="price" value="price" />
                    <ExcelColumn label="Discount" value="discount" />
                    <ExcelColumn label="Free choices" value="no_offreechoices" />
                    <ExcelColumn label="Packaging Fees" value="packaging_fees" />

                    <ExcelColumn label="Extras" value="extras" />
                    <ExcelColumn label="Choices" value="choices" />

                  </ExcelSheet>
                </ExcelFile>
                <br></br>
                <br></br>


            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.restaurant_menu_categories}
            bordered
            
            expandable={{
              expandedRowRender: (record) =>{
                  return (
                    <Tabs defaultActiveKey="2" >
                     <TabPane tab="Dishes" key="2" >
                      <RestaurantDishesList menuID={record.id}/>

                    </TabPane>
                  
                    <TabPane tab="category Details" key="1" >
                       <RestaurantMenuCategoryDetail onrefresh={this.component_loaded}   categoryID={record.id} />
                    </TabPane>


                    </Tabs>
                   );
              } ,
              rowExpandable: (record) => true,
              onExpand:(condition,record)=>{
              },

              expandIcon: (props) => {
                const { expanded, onExpand } = props;

                 return (<span style={{ color: primarycolor.primarycolor, fontSize: '30px' }}
                 onClick={(e) => {
                  onExpand(props.record, e);
                }}
                 
                 ><PlusCircleOutlined /></span>)
                
              }


            }}
        
            />

             </Panel>
             </Collapse>
            <br />
            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Create New category" key="1">
              <RestaurantMenuCategoryForm onrefresh={this.component_loaded}  restaurantID={this.props.restaurantID} /> 
            </Panel>
            </Collapse>


            <Modal
                visible={this.state.position_swap_model}
                title="Click to Swap to New Position"
                width={1000}
                onCancel={(val)=>{this.setState({position_swap_model:false})}}  
                footer={[
                  <Button key="back" onClick={(val)=>{this.setState({position_swap_model:false})}}>
                    Cancel
                  </Button>
                  ]}
                  >

                  {
                  this.state.restaurant_menu_categories.length>0?
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignSelf: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                
                    {
                        this.state.restaurant_menu_categories.map((menu)=>{

                          if (Number(this.state.current_menu.cat_no)!=Number(menu.cat_no)){
                            return (
                              <span style={{display:'flex',
                              justifyContent:'center',
                              width:200,
                              border: `1px solid ${primarycolor.primarycolor}`,
                              margin:1,
                              backgroundColor:'yellow'
  
                              }}
  
                              onClick={()=>{

                                let form_data = new FormData();
                                form_data.append("current_menu", this.state.current_menu.id);
                                form_data.append("menuto_changewith", menu.id);
                                //Now submit sale data to database
                                axios
                                  .post(
                                    serverconfig.backendserverurl +
                                      "/customqueries/swap_menuposition",
                                    form_data,
                                    {
                                      headers: {
                                        "content-type": "multipart/form-data",
                                      },
                                    }
                                  )
                                  .then((res) => {
                                    message.info("Changed position")
                                    this.component_loaded()
                                    this.setState({position_swap_model:false})
                                    
                                  })
                                  .catch((error) => console.log(error));
            
                              }}
                              >
                                {menu.cat_no}
  
                              </span>
  
                            )
                          }

                        })
                  }

                  </div>

                :
                null
                }                    


                
                    
              </Modal>


        </div>
    )

    }

   
  }
}

export default RestaurantMenuCategoryList; 
