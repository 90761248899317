import React from 'react';
import axios from 'axios';
import { Card, Button, Avatar,Modal,Form, Input,Select,Spin,Image,Result, message,Switch } from 'antd';
import { UserOutlined,LoadingOutlined,DeleteOutlined,MailOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { Drawer,Divider,Popover,Popconfirm } from 'antd';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import PhoneInput from 'react-phone-number-input'
import uuid from 'react-uuid'

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''
var bizuserid= ''

class CompanyProfile_autosettings extends React.Component{

    state={
        profile: {},
        datarequested:true,
        EmailAddress:'',
        subaccount:'',
        message:'',


        firstname:'',
        secondname:'',
        phonecontact:'',
        cashflow_accounts_list:[],
        companyaccounts:[],
        selected_account:'',
        usesms:false,
        invoice_receivable_asset_account:0,
        default_report_currency:'',
        currencies:[],
        deliveryfees_payable_account:0,
        delivery_fees_income:0,
        rider_deduction_fromdelivery:0,
        guarantee_feesexpense:0,
        support_whatsappno:''

    }


    componentDidMount(){
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
      .then(res => {  
      this.setState({
        profile: res.data
      })
      console.log(res.data)
      this.setState({usesms:res.data.usesms})
      this.setState({invoice_receivable_asset_account:res.data.invoice_receivable_asset_account})
      this.setState({deliveryfees_payable_account:res.data.deliveryfees_payable_account})
      this.setState({delivery_fees_income:res.data.delivery_fees_income})
      this.setState({rider_deduction_fromdelivery:res.data.rider_deduction_fromdelivery})
      this.setState({guarantee_feesexpense:res.data.guarantee_feesexpense})
      this.setState({support_whatsappno:res.data.support_whatsappno})


      

      
      this.setState({default_report_currency:res.data.default_report_currency})

      this.setState({cashflow_accounts_list:res.data.cashflow_accounts_list!=null?JSON.parse(res.data.cashflow_accounts_list):[]})

      
  })


        axios.get(serverconfig.backendserverurl+`/api/companyaccounts/`)
        .then(res => {
            this.setState({
                companyaccounts:res.data
            })

            this.setState({datarequested:false})

        })

        axios.get(serverconfig.backendserverurl+`/api/currencies/`)
        .then(res => {
            this.setState({
                currencies:res.data
            })
            this.setState({datarequested:false})
    
        })

    }


    handleFormSubmit=() =>{
      this.setState({datarequested:true})
    
      let form_data = new FormData();
      form_data.append('usesms', this.state.usesms);
      form_data.append('cashflow_accounts_list', JSON.stringify(this.state.cashflow_accounts_list) );
      form_data.append('invoice_receivable_asset_account', this.state.invoice_receivable_asset_account);
      form_data.append('deliveryfees_payable_account', this.state.deliveryfees_payable_account);
      form_data.append('delivery_fees_income', this.state.delivery_fees_income);

      form_data.append('default_report_currency', this.state.default_report_currency);
      form_data.append('rider_deduction_fromdelivery', this.state.rider_deduction_fromdelivery);
      form_data.append('guarantee_feesexpense', this.state.guarantee_feesexpense);
      form_data.append('support_whatsappno', this.state.support_whatsappno);

      axios.post(`${serverconfig.backendserverurl}/customqueries/editprofile_autolists`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
      })
      .then(res =>{
        console.log(res.data.message)
        this.setState({datarequested:false})
        this.setState({message:res.data.message})
        this.setState({datasubmittedsuccessfully:true})

      })       
     
      }



      //check if account already added
      isaccountAlreadyExists = (account, account_list) => {
        return account_list.some((item) => item.account === account);
      };



    render(){

      if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
              <div className="content">
                <h3>eats.biz</h3>
              </div>
               </Spin> 
          </div>
        )
  
      }else{
        return(
          <div>

          {
            this.state.datasubmittedsuccessfully===true?
            <Card>
            <Result
            status="success"
            subTitle={this.state.message}
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
            :
            <Card 
            style={{padding:10}}
            hoverable>
            <Form
                onFinish={(event) => {}}
                >


            <h3>Cashflow Ledger Settings</h3>

          <FormItem label={"Select To Add Cashflow Ledgers"}>
            <Select 
            placeholder="Account" 
            style={{  }} 
            value={this.state.selected_account}
            onChange={(val)=>{this.setState({selected_account:val})}} 
            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}                    
            >
              {this.state.companyaccounts.map(
                (accnt)=>(
                  <Option value={accnt.account_name}>{accnt.account_name} | {accnt.account_code}</Option>
                ))}
            </Select>
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType='button'
              onClick={()=>{

               if (this.isaccountAlreadyExists(this.state.selected_account,this.state.cashflow_accounts_list)===true){
                
                  message.error("Accounts exists")
                
                }else{

                  var intobj={
                    'key':this.state.cashflow_accounts_list.length+1,
                    'account':this.state.selected_account,
                  }
  
                  this.setState({cashflow_accounts_list: [...this.state.cashflow_accounts_list,intobj]});
                  message.info("item added")
                  this.setState({selected_account:''})

                }

              }}>
                Add to list 
              </Button>
              </FormItem>


              {
                this.state.cashflow_accounts_list.length>0?
                <div >
                  {
                    this.state.cashflow_accounts_list.map((item)=>{
                      return (
                      <p>{item.key} | {item.account} &nbsp;&nbsp;
                      <Popover content={<p>Click here to delete item</p>} title="Delete">
                      <Popconfirm title="Sure to delete?" onConfirm={() => {
                        this.setState({ cashflow_accounts_list:  [...this.state.cashflow_accounts_list.filter(todo => todo.key
                          !==item.key)]});
                      }}>
                        <DeleteOutlined style={{color:'red'}}/>
                      </Popconfirm>
                      </Popover>
                      
                      </p>)
                    })
                  }

                </div>
                :
                null
              }

          <Divider></Divider>

          <FormItem name="smson" label="SMS ON" >
            <Switch
                checked={this.state.usesms}
                onChange={val=>{this.setState({usesms:!this.state.usesms})}}
                checkedChildren="YES"
                unCheckedChildren="NO"
                />
          </FormItem>


        <FormItem label={"Invoice Receivable Asset Account"}>
                      <Select 
                      placeholder="Account" 
                      style={{  }} 
                      value={this.state.invoice_receivable_asset_account}
                      onChange={(val)=>{this.setState({invoice_receivable_asset_account:val})}} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}                    
                      >
                        {this.state.companyaccounts.map(
                          (accnt)=>(
                            <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                          ))}
                      </Select>
         </FormItem>


         <FormItem label={"Delivery Fees Payable Account"}>
              <Select 
              placeholder="Account" 
              style={{  }} 
              value={this.state.deliveryfees_payable_account}
              onChange={(val)=>{this.setState({deliveryfees_payable_account:val})}} 
              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}                    
              >
                {this.state.companyaccounts.map(
                  (accnt)=>(
                    <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                  ))}
              </Select>
         </FormItem>


         <FormItem label={"Delivery Fees Income"}>
              <Select 
              placeholder="Account" 
              style={{  }} 
              value={this.state.delivery_fees_income}
              onChange={(val)=>{this.setState({delivery_fees_income:val})}} 
              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}                    
              >
                {this.state.companyaccounts.map(
                  (accnt)=>(
                    <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                  ))}
              </Select>
         </FormItem>


         <FormItem label={"Guarantee Fees Expense"}>
              <Select 
              placeholder="Account" 
              style={{  }} 
              value={this.state.guarantee_feesexpense}
              onChange={(val)=>{this.setState({guarantee_feesexpense:val})}} 
              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}                    
              >
                {this.state.companyaccounts.map(
                  (accnt)=>(
                    <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                  ))}
              </Select>
         </FormItem>



         <h3>Default Reports Currency</h3>  
          <FormItem label="Default Currency">
            <Select  name="currency"
            placeholder="Currency" 
            value={this.state.default_report_currency} 
            onChange={(val)=>{this.setState({default_report_currency:val})}} >

            {
            this.state.currencies.map((item)=>{
              return (<Option  value={item.currency_name}>{item.currency_name}</Option>)
            })
          }

            </Select>
          </FormItem>

          <FormItem label="Rider Deduction Fee">
            <Input 
            type='number'
            name="rider_deduction_fromdelivery"  
            placeholder="Put a fee here."
            value={this.state.rider_deduction_fromdelivery} 
            onChange={(val)=>{this.setState({rider_deduction_fromdelivery:val.target.value})}} />
          </FormItem>
        
          <Divider></Divider>
          <FormItem label="Support Whatsapp Number">
            <Input 
            type='number'
            name="support_whatsappno"  
            placeholder="Support whatsapp number."
            value={this.state.support_whatsappno} 
            onChange={(val)=>{this.setState({support_whatsappno:val.target.value})}} />
          </FormItem>

          <FormItem>
              <Button type="primary" htmlType="submit"  
              onClick={()=>{
                this.handleFormSubmit()

              }}>Make Update </Button>
          </FormItem>

      </Form>

       </Card>
          }
      
     </div>
      )}
       
    }
}

export default CompanyProfile_autosettings; 