import React,{ useRef } from 'react';
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var token= ''
var sacco= ''
var username=''
var bizuserid= ''

class JournalEntryView extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    members:[],
    datarequested:true,
    totalamount:0,
    fixedrate:0,
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    fixedamount:0,
    companyprofile:{},
    accountchange:false,
    userprofile:{},
    fdperiod:0,
    fixedratetype:'simple',

    companyaccounts: [],
    selected_account:'',
    transaction_description:'',
    transaction_amount:0,
    transaction_type:'',
    journal_list:[],
    itemnumber:0,
    voucher_no:0

  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("username")){
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
     
    })


    axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })



    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

  }
  

  //submit the deposit now
  handleFormSubmit=(event) =>{

    if (this.state.journal_list<=0){
      message.info(" List of txns cant be zero ")
    }
    else{
      this.setState({datarequested:true})
  
      let form_data = new FormData();
      form_data.append('date',this.state.date);
      form_data.append('journal_list', JSON.stringify(this.state.journal_list));
      form_data.append('username', username);
      form_data.append('voucher_no', this.state.voucher_no);
      axios.post(serverconfig.backendserverurl+'/customqueries/createjournalentries', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)
        if (res.data.message==="success"){
          this.setState({datasubmittedsuccessfully:true})
        }
      })
      .catch(error => console.log(error))
    }
    
  }


  //calculate total debit and credit
  calculateTotalDebit=()=>{
    var total =0

    this.state.journal_list.map((item)=>{

      if (item.transaction_type==="Debit"){
        total+=Number(item.transaction_amount)
      }

    })

    return total;
  }

  calculateTotalCredit=()=>{
    var total =0

    this.state.journal_list.map((item)=>{

      if (item.transaction_type==="Credit"){
        total+=Number(item.transaction_amount)
      }

    })

    return total;
  }


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {


    const journal_columns = [
      {
        title: '#',
        dataIndex: 'itemnumber',
        key: 'key',
      },
      {
        title: 'Account',
        dataIndex: 'selected_account',
        key: 'key',
      },

      {
        title: 'Transaction type',
        dataIndex: 'transaction_type',
        key: 'key',
      },
      {
        title: 'Amount',
        dataIndex: 'transaction_amount',
        key: 'key',
      },
      {
        title: 'Details',
        dataIndex: 'transaction_description',
        key: 'key',
      },
      {
        title: 'Action',
        dataIndex: 'key',
        key: 'key',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => {
        this.setState({ journal_list:  [...this.state.journal_list.filter(todo => todo.key
          !==text)]});
        }}>
          <DeleteOutlined style={{color:'red'}} />
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>

            <Row >
                <Col xs="12" sm="6" lg="6">
                  <Card>
                  <Result
                  status="success"
                  title="Successful journal entries"
                  subTitle="Finish off transaction"
                  extra={[
                    <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                  ]}
                  />

                  </Card>

                  </Col>

            </Row>
          </div>

        )

      }else{
        return(
          <div>
        <Row >
        <Col xs="12" sm="6" lg="4">
        <Card>
        <h4>Journal entry form</h4>
        <Form 
          name="Ledger account"
          onFinish={(event) => {

            if (this.state.selected_account===""){
              message.error("Ledger account cant be missing")
            }else if(this.state.transaction_type===""){
              message.error("Transaction type cant be missing")

            }else if(this.state.transaction_amount===""){
              message.error("Transaction amount cant be missing")

            }else if(this.state.transaction_description===""){
              message.error("Transaction details cant be missing")

            }else{

              const newitem={
                key:uuid(),
                itemnumber:(Number(this.state.itemnumber)+1),
                selected_account:this.state.selected_account,
                transaction_type:this.state.transaction_type,
                transaction_amount:this.state.transaction_amount,
                transaction_description:this.state.transaction_description,
               }
          
              //add to the receipt item list
              this.setState({
                journal_list: [...this.state.journal_list, newitem]
              });
          
              this.setState({selected_account:''});
              this.setState({transaction_type:''})
              this.setState({transaction_amount:0})
              this.setState({transaction_description:''})
              
              this.setState({itemnumber:(Number(this.state.itemnumber)+1)})
              message.info("Added entry")

            }
            
          }}
          >
       
        <FormItem label={"Ledger account"}
       
        >
          <Select 
          placeholder="Account" 
          style={{  }} 
          value={this.state.selected_account}
          onChange={(val)=>{this.setState({selected_account:val})}} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}                    
          >
            {this.state.companyaccounts.map(
              (accnt)=>(
                <Option value={accnt.account_name}>{accnt.account_name} | {accnt.account_code} | {accnt.account_type}</Option>
              ))}
          </Select>
      </FormItem>

      <FormItem 
        label="Transaction type"
      
        >
            <Select 
            value={this.state.transaction_type} 
            onChange={(val)=>{this.setState({transaction_type:val})}}
            placeholder="Transaction type"
            showSearch
            optionFilterProp="children"
            >
                  <Option value='Debit'>Debit</Option>
                  <Option value='Credit'>Credit</Option>
            </Select>
        </FormItem>


       <FormItem label="Amount">
          <Input name="transaction_amount" type="number" placeholder="Transaction amount" value={this.state.transaction_amount} onChange={(val)=>{this.setState({transaction_amount:val.target.value})}} />
        </FormItem>

        <FormItem label="Transaction details">
              <TextArea
                    placeholder="Details."
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    value={this.state.transaction_description}
                    onChange={(val)=>{this.setState({transaction_description:val.target.value})}}
                  />
        </FormItem>

        <FormItem>
          <Button  type="primary" htmlType="submit">Add To List</Button>
        </FormItem>

        </Form>

        </Card>

                    </Col>

                    <Col xs="12" sm="6" lg="7">
                      <Card>
                        <h4>Journal List</h4>
                        <Table 
                          size='small'
                          scroll={{ x: 1000 }}
                          columns={journal_columns}
                          pagination={{showQuickJumper:true,showSizeChanger:true }}
                          dataSource={this.state.journal_list} bordered/>
                         
                          <br></br>
                          <h3>List Total Items: ( {this.state.journal_list.length} ) Entries</h3>

                          <h4>Total Debit:  {this.calculateTotalDebit()}</h4>
                          <h4>Total Credit: {this.calculateTotalCredit()}</h4>

                        <FormItem label="Date of entry"
                        name='date'
                        rules={[
                          {
                            required: true,
                            message: 'Please Select date',
                          },
                          ]}
                        >
                            <DatePicker value={moment()} onChange={(date, dateString) => this.setState({ date: dateString})} format={dateFormat} />
                        </FormItem>                 
               

                 {/*   <FormItem label="Voucher no">
                         <Input name="voucher_no"
                          type='number'
                           placeholder="voucher_no."
                            value={this.state.voucher_no}
                             onChange={(val)=>{this.setState({voucher_no:val.target.value})}} />
                  </FormItem> */}

                  <FormItem >
                          <Button type="primary" htmlType="button" onClick={()=>{
                            this.handleFormSubmit()

                          }}>Submit Entries </Button>
                        </FormItem>
                  </Card>

        </Col>

        </Row>

          </div>                       
        )
      }
    }
   
  }
}

export default JournalEntryView; 
