import React from 'react';
import { Form, Input, Button,Spin,Select, message,Popover,Switch,Popconfirm,Card,Divider,Table } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined,DeleteOutlined,PlusCircleFilled,PlusCircleOutlined,PrinterOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import ReactToPrint from "react-to-print";
import CurrencyFormat from "react-currency-format";

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var userother_email=''

const { Option } = Select;

class SendMultipleEmailForm extends React.Component {

  state = {
    other_email:'' ,  
    datarequested:true,
    sending_mode:'restaurant',
    restaurant_obj:{},
    restaurant_id:this.props.restaurant_id,
    receivers_list:[],

    restaurants: [],
    restaurant:'',

    unpaidinvoices_list: [],
    invoicestosend_list:[],


  }


  componentDidMount(){   

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/restaurants/`)
    .then(res => {
        this.setState({
         restaurants:res.data
        })

        this.setState({datarequested:false})
    })


  }


  //submit button pressed
  handleFormSubmit=(event) =>{

    if (this.state.receivers_list<=0){
      message.error("Email Receiver's list empty")

    }else if (this.state.invoicestosend_list<=0){
      message.error("Invoices' list empty")
    }else{

      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('receivers_list',JSON.stringify(this.state.receivers_list));
      form_data.append('invoicestosend_list',JSON.stringify(this.state.invoicestosend_list));
      form_data.append('restaurant_id',this.state.restaurant);

      axios.post(serverconfig.backendserverurl+'/customqueries/send_multipleinvoices', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
      this.setState({datarequested:false})
      message.info(res.data.message)

      //refesh parent
      this.props.onRefresh()
  
      })
      .catch(error => console.log(error))

    }

  }


   isValidEmail=(email)=> {
    // Regular expression pattern for validating email addresses
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }


    //check if account already added
    isemail_added = (account, account_list) => {
      return account_list.some((item) => item === account);
    };

    //chekc if invoice exists
    isinvoiceAdded=(account, inv_list) => {
      return inv_list.some((item) => item === account);
    };

  
  render(){

    const columns = [
      {
        title: "Add To List",
        dataIndex: "item_no",
        key: "id",
        render: (text,record) =>
        <p>
          <PlusCircleFilled 
          style={{color:this.isinvoiceAdded(record.invoice_no,this.state.invoicestosend_list)===true?"green":'grey',
          fontSize: '30px'
          ,margin:2}}
          
          onClick={()=>{
            if (this.isinvoiceAdded(record.invoice_no,this.state.invoicestosend_list)===true){
             
              this.setState({ invoicestosend_list:  [...this.state.invoicestosend_list.filter(todo => todo
                !==record.invoice_no)]});
            
              console.log("Invoice removed")
          
            }else{
              this.setState({invoicestosend_list: [...this.state.invoicestosend_list,record.invoice_no]});
              console.log("Invoice added")
            }



          }}
          
          
          />
        </p>
      },
      {
        title: "No",
        dataIndex: "item_no",
        key: "id",
      },
      {
        title: "InvoiceType",
        dataIndex: "invoice_type",
        key: "id",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "id",
      },

      {
        title: "Range",
        dataIndex: "period_range",
        key: "id",
      },
      {
        title: "Details",
        dataIndex: "details",
        key: "id",
      },

      {
        title: "InvoiceNo",
        dataIndex: "invoice_no",
        key: "id",
      },

      {
        title: "Total Amount",
        dataIndex: "totalAmount",
        key: "id",
        render: (text) => (
          <CurrencyFormat
            value={text}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
      },

      {
        title: "Paid Amount",
        dataIndex: "totalPaid",
        key: "id",
        render: (text) => (
          <CurrencyFormat
            value={text}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
      },
      {
        title: "Balance",
        dataIndex: "dueAmount",
        key: "id",
        render: (text) => (
          <CurrencyFormat
            value={text}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
      }

    ];



    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{
      return (
        <div>
          <Card>

          <Form layout="vertical">
           
           <FormItem label="Select Restaurant">
               <Select 
               placeholder="Restaurant" 
               value={this.state.restaurant} 
               onChange={(val)=>{
                 this.setState({restaurant:val})
                  //reser list now
                  this.setState({receivers_list:[]})
                  this.setState({invoicestosend_list:[]})

                   //p ownser object
                  axios.get(`${serverconfig.backendserverurl}/api/restaurants/${val}`)
                   .then(res => {  
                   this.setState({
                     restaurant_obj: res.data
                   })


                   ///get all invoices
                   if (this.isemail_added(res.data.email,this.state.receivers_list)===true){
                    console.log("Email already added exists")
                  }else if (this.isValidEmail(res.data.email)===false){
                    console.log("Invalid email address")
                  }else{
                    this.setState({receivers_list: [...this.state.receivers_list,res.data.email]});
                    console.log("Email added")
                  }

                  //get invoices
                  let form_data = new FormData();
                  form_data.append('restaurant_id', res.data.id);
              
                  axios
                  .post(
                    serverconfig.backendserverurl + `/customqueries/getunpaidinvoices_byrestaurant`,
                    form_data,
                    {
                      headers: {
                        "content-type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => {
                    this.setState({ unpaidinvoices_list: JSON.parse(res.data.unpaidinvoices) });
                  });


                 })

               }} 
               showSearch
               optionFilterProp="children"
               onFocus={this.onFocus}
               onBlur={this.onBlur}
               onSearch={this.onSearch}    
               >
               {this.state.restaurants.map(
               (cat)=>(
                 <Option value={cat.id}>{cat.name} {cat.system_ID}</Option>
               ))}
               </Select>
         </FormItem>

         </Form>

         <Divider></Divider>
         <h4>Select Invoices To Send</h4>

         {
          this.state.unpaidinvoices_list.length>0?
          <Table
          columns={columns}
          pagination={{ showQuickJumper: true, showSizeChanger: true }}
          dataSource={this.state.unpaidinvoices_list}
          scroll={{ x: 1000 }}
          bordered
          size='small'
        />

        :
        null
        }

        <Divider></Divider>

       <h4>Email Receivers List</h4>
        <div style={{display:'flex',flexDirection:'column'}}>
        <div style={{display:'flex',flexDirection:'row'}}>
        <Form layout='inline'>
        <FormItem label="Email">    
          <Input
          
          placeholder="" 
          value={this.state.other_email}
          onChange={(val)=>{this.setState({other_email:val.target.value})}}
          type="email"
          
          />

        </FormItem>


        <FormItem>
        <Button type="primary" htmlType='button'
          onClick={()=>{

            if (this.isemail_added(this.state.other_email,this.state.receivers_list)===true){
              message.error("Email already added exists")
            }else if (this.isValidEmail(this.state.other_email)===false){
              message.error("Invalid email address")
            }else{

              this.setState({receivers_list: [...this.state.receivers_list,this.state.other_email]});
              message.info("Email added")
              this.setState({other_email:''})
            }

          }}>
            Add to list 
          </Button>
          </FormItem>

        </Form>
          </div>



      {
        this.state.receivers_list.length>0?
        <div >
          {
            this.state.receivers_list.map((item)=>{
              return (
              <p>{item} &nbsp;&nbsp;
              <Popover content={<p>Click here to delete email</p>} title="Delete">
              <Popconfirm title="Sure to delete?" onConfirm={() => {
                this.setState({ receivers_list:  [...this.state.receivers_list.filter(todo => todo
                  !==item)]});
              }}>
                <DeleteOutlined style={{color:'red'}}/>
              </Popconfirm>
              </Popover>
              
              </p>)
            })
          }


        </div>
        :
        null
      }


      </div>
       

      <Divider></Divider>

      <FormItem>
        <Button  type="primary" 
        htmlType='button'
        onClick={(event) => this.handleFormSubmit(event)}
        
        >Send Email</Button>
      </FormItem>


       </Card>
        </div>
      );
    }

  }

}


export default SendMultipleEmailForm;



