import React from 'react';
import { Form, Input, Button,Spin,Select,Result,DatePicker,Image,Switch,Card, message } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  Col,
  Row,
} from 'reactstrap';

var CryptoJS = require("crypto-js");
const dateFormat = 'DD/MM/YYYY';

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var username=''
var instance_id = "";

const { Option } = Select;

const { TextArea } = Input;
const t_time="HH:mm:ss"

class OrderUpdateForm extends React.Component {

  state = {
    client_name:'' ,  
    client_phone:'',
    datarequested:true,
    active:false,
    image:null,
    image_file:'',
    date_created:'',
    date_of_expiry:'',
    order_total:0,
    area:'',
    restaurant_profile:{},
    hub_zones:[],
    delivery_price:0,
    hub_riders:[],
    selected_rider:'',
    exclusive_riders:[],
    support_riders:[],
    datasubmittedsuccessfully:false,
    areas:[],
    reason_ofupdate:'',
    companyprofile:{},
    restaurants: [],
    restaurant:'',
    order_status:''


  }

  componentDidMount(){
    this.component_load()
  }


  //component load
  component_load=()=>{
    this.data_loaded()
  
  }

  data_loaded=()=>{
    this.setState({datarequested:true})

    axios
      .get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
      .then((res) => {
        this.setState({
          companyprofile: res.data,
        });
      });


    axios.get(serverconfig.backendserverurl+`/api/restaurants/`)
    .then(res => {
    
        this.setState({
          restaurants:res.data
        })
    
    })


  //get oder object
  let order_obj=this.props.order_obj

  instance_id = this.props.restaurant_id;

  this.setState({area:order_obj.area})
  this.setState({delivery_price:order_obj.delivery_charges})

  this.setState({selected_rider:order_obj.assigned_rider})
  this.setState({date_created:order_obj.orig_date})

  this.setState({restaurant:order_obj.restaurant})
  this.setState({order_status:order_obj.order_status})


  if(localStorage.getItem("token")){
     token= localStorage.getItem("token")
  }else{
     token= ''
  }

  if(localStorage.getItem("username")){
    username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
  }else{
    username=''
  }

  axios.defaults.client_nameers={
    "Content-Type":"application/json",
    Authorization:`Token ${token}`
  }

  axios.get(serverconfig.backendserverurl+`/api/restaurants/${instance_id}`)
  .then(res => {
    this.setState({restaurant_profile:res.data}) 
    var hub=res.data.hub

    //now get hub zones and prices
    axios.get(serverconfig.backendserverurl+`/api/hub_zones/?hub=${hub}`)
    .then(res => {
      this.setState({hub_zones:res.data})
      ///console.log("zones: "+JSON.stringify(res.data[0]))
    })

    //get hub riders
    axios.get(serverconfig.backendserverurl+`/api/riders/`)
    .then(res => {
      this.setState({hub_riders:res.data})
      ///console.log("zones: "+JSON.stringify(res.data[0]))
    })

    //get exclusive rider
  })


  //get exclusive rider
  axios.get(serverconfig.backendserverurl+`/api/riders/?exclusive_restaurant=${instance_id}&available=${true}`)
  .then(res => {
    console.log("Exclusive riders: "+JSON.stringify(res.data))
    this.setState({exclusive_riders:res.data})
  })


  axios.get(serverconfig.backendserverurl+`/api/areas/`)
  .then(res => {
      this.setState({
        areas:res.data
      })

  })

  //get support riders
  axios.get(serverconfig.backendserverurl+`/api/riders/?support_rider=${true}`)
  .then(res => {
    this.setState({support_riders:res.data})
  })

  this.setState({datarequested:false})
  this.setState({datasubmittedsuccessfully:false})





  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    var order_id=this.props.order_obj.id

    if (this .state.area===""){
      message.error("Please enter area")
    }else if (this.state.delivery_price<=0){
      message.error("Delivery charge not calculated ")
    }
    else{

    this.setState({datarequested:true})
    let form_data = new FormData();
    form_data.append('area',this.state.area);
    form_data.append('delivery_price',this.state.delivery_price);
    form_data.append('selected_rider',this.state.selected_rider);
    form_data.append('order_id',order_id);
    form_data.append('reason_ofupdate',this.state.reason_ofupdate);
    form_data.append('date_created',this.state.date_created);
    form_data.append('restaurant',this.state.restaurant);
    form_data.append('order_status',this.state.order_status);

    axios.post(serverconfig.backendserverurl+'/customqueries/update_order', form_data, {
      client_nameers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => {
     this.setState({datarequested:false})
     //this.setState({datasubmittedsuccessfully:true})
     this.data_loaded()

     //update parent
     this.props.onRefresh()
     
    })
  .catch(error => console.log(error))


    }


  }

  //get the delivery zone

  
  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
              <Card>
              <Result
              status="success"
              title="Action successful"
              extra={[
                <Button  type='primary' 
                onClick={()=>{
                  this.component_load()
                }}>Finish</Button>,
              ]}
              />

              </Card>
          </div>
        )    

      }else{

      return (
        <div>
          <h3>ORDER UPDATE FORM</h3>
          <Row>

            <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
            <Card>
            <Form
                onFinish={(event) => this.handleFormSubmit(event)}
            >


            <FormItem label={"Date ("+this.state.date_created+")"}>
               <DatePicker onChange={(date, dateString)=>{this.setState({ date_created: dateString})}} format={'DD/MM/YYYY'} />
            </FormItem>


              <FormItem 
                  label="DELIVERY AREA">
                <Select 
                placeholder="DELIVERY AREA" 
                value={this.state.area} 
                onChange={(val)=>{
                  console.log(val)
                  this.setState({area:val})
                  this.setState({delivery_price:0})

                  //get delivery fees
                  this.state.hub_zones.map((zone)=>{
                    var zone_list=zone.zone_list
                    var price=zone.price
                   
                    if (zone_list.includes(val)){
                      this.setState({delivery_price:price})

                    }else{
                      console.log("No in zone")
                    }

                  })
                
                }} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}    
                >
                   {this.state.areas.map(
                    (cat)=>(
                      <Option value={cat.name}>{cat.name}</Option>
                    ))}
                </Select>
            </FormItem>


            <FormItem  label="RIDER" >
              <Select 
              placeholder="RIDER" 
              value={this.state.selected_rider} 
              onChange={(val)=>{
                this.setState({selected_rider:val})
                
              }} 
              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}    
              >
                 {this.state.hub_riders.map(
                  (cat)=>(
                    <Option value={cat.id}>{cat.name} {cat.phone}</Option>
                  ))}
              </Select>
             </FormItem>


             {
              this.state.companyprofile.editable_order_restaurant===true?
              <FormItem  label="RESTAURANT" >
              <Select 
              placeholder="RESTAURANT" 
              value={this.state.restaurant} 
              onChange={(val)=>{
                this.setState({restaurant:val})
                
              }} 
              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}    
              >
                 {this.state.restaurants.map(
                  (res)=>(
                    <Option value={res.id}>{res.name}</Option>
                  ))}
              </Select>
             </FormItem>
              :
              null
             }
             

             <FormItem label="Reason for update">
              <TextArea
                    placeholder="Reason."
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    value={this.state.reason_ofupdate}
                    onChange={(val)=>{this.setState({reason_ofupdate:val.target.value})}}
                  />
            </FormItem>


            {
              this.state.companyprofile.editable_order_deliveryfee===true?
              <FormItem label="Delivery Price">
              <Input name="delivery_price" 
                placeholder="0"
                value={this.state.delivery_price} 
                onChange={(val)=>{this.setState({delivery_price:val.target.value})}} />
              </FormItem>
              :
              <h3 style={{fontWeight:'bolder',justifyContent:'center',display:'flex',alignSelf:'center'}}> FEE: {this.state.delivery_price} UGX</h3>
            }


            <FormItem label="Order status">
              <Select
                placeholder="Order status"
                style={{ width: 200 }}
                value={this.state.order_status}
                onChange={(val) => {
                  this.setState({ order_status: val });
                }}
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={"Pending"}>Pending</Option>
                <Option value={"Delivered"}>Delivered</Option>
                <Option value={"Cancelled"}>Cancelled</Option>
                <Option value={"Delivering"}>Delivering</Option>
              </Select>
            </FormItem>


            <FormItem>
              <Button  type="primary" htmlType="submit">Update order</Button>
            </FormItem>
            </Form>

        </Card>
        </Col>
        </Row>

        </div>
      );
    }
    }

  }

}


export default OrderUpdateForm;



