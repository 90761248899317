import React,{useState,useEffect,lazy} from 'react';
import axios from 'axios'
import {  Slider,Statistic,Tabs,Modal } from 'antd';
import { ArrowUpOutlined,MinusCircleFilled,ToolOutlined, ArrowDownOutlined,FileFilled,WalletFilled,SearchOutlined,FundViewOutlined,
   UserOutlined, AccountBookOutlined,CloseOutlined,CalculatorFilled,SwapOutlined,GroupOutlined,AccountBookFilled, DollarCircleOutlined,EditFilled,MessageFilled,DollarOutlined,CarFilled,LoadingOutlined,PlusCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { Line,Pie,Bar } from 'react-chartjs-2';
import {withGetScreen} from 'react-getscreen'
import { Tooltip,Popover,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import {
  Col,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import * as serverconfig from '../serverconn'
import * as serversocketconfig from '../serversocketconfig'
import { MdShoppingCart } from 'react-icons/md';

import CurrencyFormat from 'react-currency-format';
import Highlighter from 'react-highlight-words';
import Websocket from 'react-websocket';
import { Link } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { NavLink } from 'react-router-dom'
import * as primarycolor from '../primarycolor'

var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;


const style = { background: '#0092ff', padding: '8px 0',borderRadius: '7px',justifyContent:'center', alignItems:'center',display: 'flex', };
const dateFormat = 'DD/MM/YYYY';
const yeardateFormat = 'YYYY';
const monthdateFormat = 'MM/YYYY';

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem=Form.Item;
const { Option } = Select;

var bizuserid= ''
var sacco= ''
var token= ''

//target sticky note
const TargetStickyNote = ({ title, description, onClose,date_one,date_two }) => {
  return (
    <Card style={{ width: 300, margin: 10 }}
    extra={<Button onClick={onClose} style={{ color: 'red' }} icon={<CloseOutlined />}>Close</Button>}

    >
      <p><strong>Target:</strong> {title}</p>
      <p><strong>Description:</strong> {description}</p>
      <p><strong>Duration:</strong> {date_one} - {date_two}</p>

    </Card>
  );
}


class Statistics_page extends React.Component {

  state = {
    currentmonth:moment().format(monthdateFormat).toString(),
    datarequested:true,
    
    total_inst_balance:0,
    restaurants:[],
    unpaid_invoices: [],
    riders:[],
    hubs:[],



  };


  callback =(key) =>{
    console.log(key);
    console.log(this.state.yearlymonthlysales)
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   axios.get(serverconfig.backendserverurl+`/api/app_profile/${1}`)
   .then(res => {
       this.setState({
        userprofile:res.data
       })

       this.setState({datarequested:false})
   })



   axios.get(serverconfig.backendserverurl+`/api/restaurants/`)
   .then(res => {
       this.setState({
        restaurants:res.data
       })
   })

   axios.get(serverconfig.backendserverurl+`/api/riders/`)
   .then(res => {
       this.setState({
        riders:res.data
       })
   })

   axios.get(serverconfig.backendserverurl+`/api/hubs/`)
   .then(res => {
       this.setState({
        hubs:res.data
       })
   })

   


   

  let form_data_1_1_1 = new FormData();
  form_data_1_1_1.append('dateone', moment().format(dateFormat).toString());
  form_data_1_1_1.append('datetwo', moment().format(dateFormat).toString());

  //Now send the post resquest
    axios.post(serverconfig.backendserverurl+'/customqueries/getCashFlowReport', form_data_1_1_1, {
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
  .then(res => {
      //get the extar data
    this.setState({total_inst_balance:Number(res.data.total_inst_balance)})

    this.setState({datarequested:false})


  })
  .catch(error => console.log(error)) 


  //GET UNPAID INVOICES
  let form_data = new FormData();
  axios.post(serverconfig.backendserverurl+`/customqueries/getunpaidinvoices`,form_data, {
    headers: {
      'content-type': 'multipart/form-data'
        }
      })
    .then(res => {
        this.setState({unpaid_invoices:JSON.parse(res.data.unpaidinvoices)})
    })


}

//CALCULATE TOTAL RECEIVABLE
calculateTotalReceivable=()=>{
  var total =0
  this.state.unpaid_invoices.map((item)=>{
    total+=Number(item.dueAmount)
  })
  return total;

}




render() {


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin size="large">
          <div className="content">
            <h3>eats.biz</h3>
          </div>
          </Spin>        
        </div>
      )
  
    }else{


  
        return(

          <div className="animated fadeIn">  

              <Row>

              <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
                <Card
                    //onClick={()=>{}}
                    hoverable
                    style={{ 
                      background:"white",
                      display: 'flex',
                      justifyContent:'center',
                      padding:4,
                      alignItems:'center' }}
                  > 

                      <h5 style={{fontWeight:'bolder',color:primarycolor.primarycolor}}>REGISTERED RESTAURANTS</h5>
                      <h1 style={{fontWeight:'bolder',color:primarycolor.primarycolor,display:'flex',alignContent:'center',justifyContent:'center'}}> {this.state.restaurants.length} </h1>

                  </Card>   
              </Col>


              <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
                <Card
                    //onClick={()=>{}}
                    hoverable
                    style={{ 
                      background:"white",
                      display: 'flex',
                      justifyContent:'center',
                      padding:4,
                      alignItems:'center' }}
                  > 

                      <h5 style={{fontWeight:'bolder',color:primarycolor.primarycolor}}>TOTAL RIDERS</h5>
                      <h1 style={{fontWeight:'bolder',color:primarycolor.primarycolor,display:'flex',alignContent:'center',justifyContent:'center'}}> {this.state.riders.length} </h1>

                  </Card>   
              </Col>


              <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
                <Card
                    //onClick={()=>{}}
                    hoverable
                    style={{ 
                      background:"white",
                      display: 'flex',
                      justifyContent:'center',
                      padding:4,
                      alignItems:'center' }}
                  > 

                      <h5 style={{fontWeight:'bolder',color:primarycolor.primarycolor}}>HUBS</h5>
                      <h1 style={{fontWeight:'bolder',color:primarycolor.primarycolor,display:'flex',alignContent:'center',justifyContent:'center'}}> {this.state.hubs.length} </h1>

                  </Card>   
              </Col>


              <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
                <Card
                    //onClick={()=>{}}
                    hoverable
                    style={{ 
                      background:"white",
                      display: 'flex',
                      justifyContent:'center',
                      padding:4,
                      alignItems:'center' }}
                  > 
                  
                     <h5 style={{fontWeight:'bolder',color:primarycolor.primarycolor}}><strong>TOTAL RECEIVABLE (INVOICES)</strong></h5>
                     <h1 style={{fontWeight:'bolder',color:primarycolor.primarycolor,display:'flex',alignContent:'center',justifyContent:'center'}}> <strong><CurrencyFormat value={this.calculateTotalReceivable()} displayType={'text'} thousandSeparator={true}  /></strong> </h1>


                  </Card>   
              </Col>

              </Row>  

        </div>

      )

       
    }
  
 
}
}

export default Statistics_page; 
