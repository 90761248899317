import React, { useState, useEffect, lazy } from "react";
import axios from "axios";
import { Slider, Statistic, Tabs, Modal } from "antd";
import {
  ArrowUpOutlined,
  MinusCircleFilled,
  ToolOutlined,
  ArrowDownOutlined,
  FileFilled,
  WalletFilled,
  SearchOutlined,
  FundViewOutlined,
  UserOutlined,
  OrderedListOutlined,
  AccountBookOutlined,
  CalculatorFilled,
  SwapOutlined,
  GroupOutlined,
  AccountBookFilled,
  DollarCircleOutlined,
  EditFilled,
  MessageFilled,
  DollarOutlined,
  CarFilled,
  LoadingOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import moment from "moment";
import { Line, Pie, Bar } from "react-chartjs-2";
import { withGetScreen } from "react-getscreen";
import {
  Tooltip,
  Popover,
  Table,
  Popconfirm,
  Input,
  Button,
  Collapse,
  Card,
  Avatar,
  Form,
  DatePicker,
  Select,
  Space,
  Typography,
  Divider,
  InputNumber,
  message,
  Spin,
} from "antd";
import { Col, Row } from "reactstrap";
import { CustomTooltips } from "@coreui/coreui-plugin-chartjs-custom-tooltips";
import { getStyle, hexToRgba } from "@coreui/coreui/dist/js/coreui-utilities";
import * as serverconfig from "../serverconn";
import * as serversocketconfig from "../serversocketconfig";
import { MdShoppingCart } from "react-icons/md";

import CurrencyFormat from "react-currency-format";
import Highlighter from "react-highlight-words";
import Websocket from "react-websocket";
import { Link } from "react-router-dom";
import ReconnectingWebSocket from "reconnecting-websocket";
import { NavLink } from "react-router-dom";
import * as primarycolor from "../primarycolor";

import OrdersList from "./OrdersReportView";
import OrdersView from "./OrdersView";

var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const style = {
  background: "#0092ff",
  padding: "8px 0",
  borderRadius: "7px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};
const dateFormat = "DD/MM/YYYY";
const yeardateFormat = "YYYY";
const monthdateFormat = "MM/YYYY";

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem = Form.Item;
const { Option } = Select;

var bizuserid = "";
var instance_id = "";
var token = "";

class Orders_Tab extends React.Component {
  state = {
    userprofile: {},
    orde_comp_visible:true,
    selected_key:'11000903',
  };

  callback = (key) => {
    console.log(key);
    console.log(this.state.yearlymonthlysales);
  };

  componentDidMount() {
    if (localStorage.getItem("instance_id")) {
      instance_id = localStorage.getItem("instance_id");
    } else {
      instance_id = "";
    }

    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      token = "";
    }

    if (localStorage.getItem("bizuserid")) {
      bizuserid = CryptoJS.AES.decrypt(
        localStorage.getItem("bizuserid"),
        "my-secret-key@1234"
      ).toString(CryptoJS.enc.Utf8);
    } else {
      bizuserid = "";
    }

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    axios
      .get(serverconfig.backendserverurl + `/api/accounts/${bizuserid}`)
      .then((res) => {
        this.setState({
          userprofile: res.data,
        });

        this.setState({ datarequested: false });
      });
  }

  render() {
    if (this.state.datarequested === true) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin indicator={antIcon} />
        </div>
      );
    } else {
      return (
        <div className="animated fadeIn">
          <Tabs defaultActiveKey="11000903" onChange={(val) => {
            console.log("changed to: "+val)
            this.setState({selected_key:val})
            this.setState({orde_comp_visible:true})
         
          }}>

            <TabPane tab="Order Report" key="11000903">
            {
              this.state.orde_comp_visible===true && this.state.selected_key==="11000903"?
              <OrdersList />
              :null}
            </TabPane>

            <TabPane tab="Pending" key="00003">
            {
              this.state.orde_comp_visible===true && this.state.selected_key==="00003"?

              <OrdersView order_status={"Pending"} />
              :null}

            </TabPane>

            <TabPane tab="Delivering" key="00001123">
            {
              this.state.orde_comp_visible===true && this.state.selected_key==="00001123"?

              <OrdersView order_status={"Delivering"} />
              :null}

            </TabPane>

            <TabPane tab="Delivered" key="003">
            {
              this.state.orde_comp_visible===true && this.state.selected_key==="003"?

              <OrdersView order_status={"Delivered"} />
              :null}

            </TabPane>

            <TabPane tab="Cancelled" key="000903">

            {
              this.state.orde_comp_visible===true && this.state.selected_key==="000903"?

              <OrdersView order_status={"Cancelled"} />
              :null}


            </TabPane>

          
          </Tabs>
        </div>
      );
    }
  }
}

export default Orders_Tab;
