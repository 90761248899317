import React from 'react';
import RestaurantDishForm from '../components/RestaurantDishForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Tabs,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,PlusCircleOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import RestaurantDishDetails from './RestaurantDishDetails'
import DishExtrasList from './DishExtrasListView'
import DishChoiceList from './DishChoicesListView'

import CurrencyFormat from 'react-currency-format';
import * as primarycolor from '../primarycolor'

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}
const { TabPane } = Tabs;

var token= ''

class RestaurantDishesList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    restaurant_dishes: [],
    datarequested:true,
  };


  componentDidMount(){
    this.compo_loaded()
  }


//component loaded
compo_loaded=()=>{
  this.setState({datarequested:true})

  var menuID=this.props.menuID
    
  if(localStorage.getItem("token")){
     token= localStorage.getItem("token")
  }else{
     token= ''
  }

  axios.defaults.headers={
    "Content-Type":"application/json",
    Authorization:`Token ${token}`
  }

  axios.get(serverconfig.backendserverurl+`/api/restaurant_dishes/?menu_category=${menuID}`)
  .then(res => {
      this.setState({
          restaurant_dishes:res.data
      })
      this.setState({datarequested:false})

  })

}



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};




  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'id',
        ...this.getColumnSearchProps('name'),
      },
     
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'id',
      },

      {
        title: 'Price',
        dataIndex: 'price',
        key: 'id',
        render: (text,record) =>
        <CurrencyFormat value={record.price} displayType={'text'} thousandSeparator={true}/>

      },
      {
        title: 'Discount',
        dataIndex: 'discount',
        key: 'id',
        render: (text,record) =>
        <CurrencyFormat value={record.discount} displayType={'text'} thousandSeparator={true}/>

      },


      {
        title: 'Online',
        dataIndex: 'online',
        key: 'id',
        render: (text,record) =>
          <p style={{display:'flex',flexDirection:'row'}}>
         <Switch
          checked={record.online}
          onChange={val=>{

            let form_data = new FormData();
            form_data.append('online',val);
            axios.put(serverconfig.backendserverurl+`/api/restaurant_dishes/${record.id}/`, form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
          .then(res =>  {
            this.setState({datarequested:false})
            this.compo_loaded()
            message.info("Updated")
        
          }   
        
          )
          .catch(error => console.log(error))
        
          }}
          checkedChildren="Yes"
          unCheckedChildren="No"
          />

        </p>
  
      },




   
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>

        <Popover content={<p>Click here to delete </p>} title="Delete">
         <Popconfirm title="Sure to delete?" onConfirm={
          () => {
            axios.delete(`${serverconfig.backendserverurl}/api/restaurant_dishes/${text}`).then(res =>{
              window.location.reload(false)
              message.info("successfully deleted") 
            }
            );
          }
        }>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>      
         </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="MENU DISHES" key="1">
            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.restaurant_dishes}
            bordered
            
            expandable={{
              expandedRowRender: (record) =>{
                  return (
                    <Tabs defaultActiveKey="2" >
                     
                    <TabPane tab="Choices" key="2" >
                      <DishChoiceList dishID={record.id}  onrefresh={this.compo_loaded}/>
                    </TabPane>

                    <TabPane tab="Extras" key="3" >
                      <DishExtrasList dishID={record.id} onrefresh={this.compo_loaded}/>
                    </TabPane>
                
                
                    <TabPane tab="Dish Details" key="1" >
                      <RestaurantDishDetails dishID={record.id} onrefresh={this.compo_loaded} />
                    </TabPane>


                    
                    </Tabs>
                   );
              } ,
              rowExpandable: (record) => true,
              onExpand:(condition,record)=>{
              },
              expandIcon: (props) => {
                const { expanded, onExpand } = props;

                 return (<span style={{ color: primarycolor.primarycolor, fontSize: '30px' }}
                 onClick={(e) => {
                  onExpand(props.record, e);
                }}
                 
                 ><PlusCircleOutlined /></span>)
                
              }

            }}
        
            />

             </Panel>
             </Collapse>
            <br />
            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Create New Dish" key="1">
              <RestaurantDishForm menuID={this.props.menuID}  onrefresh={this.compo_loaded} /> 
            </Panel>
            </Collapse>
        </div>
    )

    }

   
  }
}

export default RestaurantDishesList; 
