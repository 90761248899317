import React from 'react';
import { Form, Input, Button,Select,InputNumber,DatePicker,message,Card } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'

import {
  Col,
  Row,
} from 'reactstrap';
import  { Redirect } from 'react-router-dom'
var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';
var username=''
var token= ''


class InvoicePaymentForm extends React.Component {

  state = {
    productName:'',  
    unit:'',
    unitprice:'',
    productDescription:'',
    productSerial:'',
    productImage:null,   
    paymentmode:'',
    date:'',
    receiptno:0,
    invoicebalanceafter:0,
    cashflow_accounts_list:[],
    profile:{}

  }

  componentDidMount(){
    this.componen_loaded()

  }


  componen_loaded=()=>{

    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    if(localStorage.getItem("username")){
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
   }else{
      username=''
   }

    axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
    .then(res => {  
    this.setState({
      profile: res.data
    })

    this.setState({cashflow_accounts_list:res.data.cashflow_accounts_list!=null?JSON.parse(res.data.cashflow_accounts_list):[]})
    
    })


  }

  
  //submit button pressed
  onFinish=(event) =>{

    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('paymentmode', event.paymentmode);
    form_data.append('amount', event.amount);
    form_data.append('invoice_id', this.props.invoice_id);
    form_data.append('username', username);

    ///make a post request now 
      axios.post(serverconfig.backendserverurl+'/customqueries/createinvoicepayment', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>{
      if(res.data.message==="Payment receipt number already exists"){
        message.info(res.data.message)
      }else{

        message.info("Successful payment")
        //refresg list
        this.props.onRefresh()
        
      }
    })
    .catch(error => console.log(error))

    }

    handlepaymentmodeChange= (e) => {
       this.setState({paymentmode:e})
    }

    handledatechange= (date, dateString) => this.setState({ date: dateString});
    handlereceiptnoChange= (e) => this.setState({ receiptno: e.target.value});

  render(){
    return (
      <div>
        <Card>
        <Form
              name="productform"
              onFinish={(event) => this.onFinish(event)}
          >

            <FormItem 
                label="Date"
                name="date"
                rules={[
                  {
                    required: true,
                    message: 'Please Select Date',
                  },
                ]}
            >
              <DatePicker placeholder="date" format={dateFormat} onChange={this.handledatechange} />
            </FormItem>
            
          
            <FormItem 
                  label="Payment Mode"
                  name="paymentmode"
                  rules={[
                    {
                      required: true,
                      message: 'Please select payment mode',
                    },
                  ]}
                  >
                <Select 
                value={this.state.paymentmode} 
                onChange={(val)=>{this.setState({paymentmode:val})}}
                placeholder="Payment Mode" >
                {
                  this.state.cashflow_accounts_list.map((item)=>{
                    return (
                      <Option value={item.account}>{item.account}</Option>

                    )
                  })

                }

                </Select>
            </FormItem>

            <FormItem label="Amount"
              name="amount"
              rules={[
                {
                  required: true,
                  message: 'Please input amount',
                },
              ]}>
                <InputNumber
                  style={{ width: 150 }} 
                  defaultValue={0.0}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\x\s?|(,*)/g, '')}
                />    
                            
              </FormItem>
         

            <FormItem>
              <Button  type="primary" htmlType="submit">Make Payment</Button>
            </FormItem>
            </Form>
        </Card>

      </div>
    );
  }

}


export default InvoicePaymentForm;



