import React from 'react';
import AreaForm from '../components/AreaForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import AreaDetail from './AreaDetailView'

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}
const { TabPane } = Tabs;

var token= ''

class ClientList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    clients: [],
    datarequested:true,
  };


  componentDidMount(){
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/clients/`)
    .then(res => {
        this.setState({
            clients:res.data
        })
        this.setState({datarequested:false})

    })
}

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



  render() {
    const columns = [
      {
        title: 'SignUp Type',
        dataIndex: 'signup_type',
        key: 'id',
        ...this.getColumnSearchProps('signup_type'),
      },
      {
        title: 'First Name',
        dataIndex: 'first_name',
        key: 'id',
        ...this.getColumnSearchProps('first_name'),
      },
      {
        title: 'Second Name',
        dataIndex: 'second_name',
        key: 'id',
        ...this.getColumnSearchProps('second_name'),
      },
     
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'id',
        ...this.getColumnSearchProps('email'),

      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'id',
        ...this.getColumnSearchProps('phone'),

      },

      {
        title: 'Date Registered',
        dataIndex: 'date_creation',
        key: 'id',
      },
   

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>      
         </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="CUSTOMERS" key="1">
              <Table 
              columns={columns} 
              scroll={{ x: 1000 }}
              pagination={{showQuickJumper:true,showSizeChanger:true }}
              dataSource={this.state.clients}
              bordered
              size='small'
              />
            </Panel>
            </Collapse>
        </div>
    )

    }

   
  }
}

export default ClientList; 
