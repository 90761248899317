import React from 'react';
import { Form, Input, Button,Spin,Select, message,Popover,Popconfirm } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined,DeleteOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var userother_email=''

const { Option } = Select;

class SendEmailForm extends React.Component {

  state = {
    other_email:'' ,  
    datarequested:true,
    sending_mode:'restaurant',
    invoice_ID:this.props.invoice_ID,
    restaurant_obj:{},
    restaurant_id:this.props.restaurant_id,
    receivers_list:[]

  }


  componentDidMount(){   

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    var restaurantID=this.props.restaurant_id
    axios.get(`${serverconfig.backendserverurl}/api/restaurants/${restaurantID}`)
    .then(res => {  

        this.setState({restaurant_obj:res.data})
        this.setState({datarequested:false})
 
    })


  }


  //submit button pressed
  handleFormSubmit=(event) =>{

    if (this.state.sending_mode==="other" && this.state.receivers_list<=0){
      message.error("Receiver's list empty")

    }else if (this.state.sending_mode==="restaurant" && this.state.restaurant_obj.email===null){
      message.error("Please enter other email")

    }else if (this.isValidEmail(this.state.restaurant_obj.email)===false && this.state.sending_mode==="restaurant"){
      message.error("Invalid email address")

    } else{

      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('receivers_list',this.state.sending_mode==="restaurant"?JSON.stringify([this.state.restaurant_obj.email]):JSON.stringify(this.state.receivers_list));
      form_data.append('invoice_ID', this.state.invoice_ID);
  
      axios.post(serverconfig.backendserverurl+'/customqueries/email_invoice', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
      this.setState({datarequested:false})
      message.info(res.data.message)

      //refesh parent
      this.props.onRefresh()
  
      })
      .catch(error => console.log(error))

    }

  }


   isValidEmail=(email)=> {
    // Regular expression pattern for validating email addresses
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }


    //check if account already added
    isemail_added = (account, account_list) => {
      return account_list.some((item) => item === account);
    };

  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{
      return (
        <div>
        

          <FormItem label="Sending mode">
               <Select 
                placeholder="sending_mode" 
                value={this.state.sending_mode} 
                onChange={(val)=>{this.setState({sending_mode:val})}} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}    
                >
                  <Option value={'restaurant'}>{'Restaurant'}</Option>
                  <Option value={'other'}>{'Other'}</Option>
                </Select>
          </FormItem>
        
        
        {
          this.state.sending_mode==="other"?          
          <div style={{display:'flex',flexDirection:'column'}}>
          <div style={{display:'flex',flexDirection:'row'}}>
            <Form layout='inline'>
            <FormItem label="Email">    
              <Input
              
              placeholder="" 
              value={this.state.other_email}
              onChange={(val)=>{this.setState({other_email:val.target.value})}}
              type="email"
              
              />

            </FormItem>


            <FormItem>
            <Button type="primary" htmlType='button'
              onClick={()=>{

               if (this.isemail_added(this.state.other_email,this.state.receivers_list)===true){
                  message.error("Email already added exists")
                }else if (this.isValidEmail(this.state.other_email)===false){
                  message.error("Invalid email address")
                }else{
  
                  this.setState({receivers_list: [...this.state.receivers_list,this.state.other_email]});
                  message.info("Email added")
                  this.setState({other_email:''})
                }

              }}>
                Add to list 
              </Button>
              </FormItem>

            </Form>
             </div>

          {
            this.state.receivers_list.length>0?
            <div >
              {
                this.state.receivers_list.map((item)=>{
                  return (
                  <p>{item} &nbsp;&nbsp;
                  <Popover content={<p>Click here to delete email</p>} title="Delete">
                  <Popconfirm title="Sure to delete?" onConfirm={() => {
                    this.setState({ receivers_list:  [...this.state.receivers_list.filter(todo => todo
                      !==item)]});
                  }}>
                    <DeleteOutlined style={{color:'red'}}/>
                  </Popconfirm>
                  </Popover>
                  
                  </p>)
                })
              }


            </div>
            :
            null
          }


          </div>
          :
          <h4>Restaurant email: {this.state.restaurant_obj.email}</h4>
        }


      <FormItem>
        <Button  type="primary" 
        htmlType='button'
        onClick={(event) => this.handleFormSubmit(event)}
        
        >Send email</Button>
      </FormItem>

        </div>
      );
    }

  }

}


export default SendEmailForm;



