import React from 'react';
import {Form,Input,Button,Checkbox,message,Spin,Switch,Select,DatePicker} from 'antd';
import axios from 'axios';
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import PhoneInput from 'react-phone-number-input'

var CryptoJS = require("crypto-js");

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var token= ''
const dateFormat = 'DD/MM/YYYY';

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;

class  UserCreationForm extends React.Component {

  state = {
    isactive:false,
    ismanager:false,
    profile_pic:null,  
    datarequested:false,

    username:'',
    email:'',
    password1:'',
    password2:'',
    phone_contact:'',

    account_type:'adminstrator',
    restaurants:[],
    riders:[],
    restaurant_id:'',
    rider_id:''


  }


  formRef = React.createRef();

  componentDidMount() {

 
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/restaurants/`)
    .then(res => {
        this.setState({
          restaurants:res.data
        })
        this.setState({datarequested:false})
    })

    axios.get(serverconfig.backendserverurl+`/api/riders/`)
    .then(res => {
        this.setState({
          riders:res.data
        })
        this.setState({datarequested:false})
    })


  }


   validatePassword = () => {
    // Check if the password meets the requirements
    const hasUpperCase = /[A-Z]/.test(this.state.password1);
    const hasLowerCase = /[a-z]/.test(this.state.password1);
    const hasNonAlphabetic = /[^A-Za-z]/.test(this.state.password1);
    const isLengthValid = this.state.password1.length >= 8;

    // Display error message if any requirement is not met
    if (!(hasUpperCase && hasLowerCase && hasNonAlphabetic && isLengthValid)) {
    
      return false
    }else{
      return true
    }

   
  };




  handleprofile_picChange= (e) => this.setState({ profile_pic: e.target.files[0]});


    // on form submit heree
    onFinish = values => {

        if (this.state.account_type==="restaurant" && this.state.restaurant_id===""){
          message.error("Please select restaurant")
        }else if (this.state.account_type==="rider" && this.state.rider_id===""){
          message.error("Please select rider")

        }else{


        this.setState({datarequested:true})

        let form_data = new FormData();
        form_data.append('username', this.state.username);
        form_data.append('email', this.state.email,);
        form_data.append('password1', this.state.password1);
        form_data.append('password2', this.state.password2);
        form_data.append('is_active', this.state.isactive);
        form_data.append('branch_manager', this.state.ismanager);
        form_data.append('account_type', this.state.account_type);

        if(this.state.rider_id!=""){
          form_data.append('rider_id', this.state.rider_id);
        }

        if(this.state.restaurant_id!=""){
          form_data.append('restaurant_id', this.state.restaurant_id);
        }
        
        form_data.append('phone_contact', this.state.phone_contact);
        this.state.profile_pic==null?
        console.log("No profile file")
        :
        form_data.append('profile_pic', this.state.profile_pic, this.state.profile_pic?this.state.profile_pic.name:"");
  
        //Register the account first
        axios.post(serverconfig.backendserverurl+'/customqueries/createsystem_user',form_data)
        .then(res =>{
          message.success(res.data.message)
  
          this.setState({datarequested:false})
          window.location.reload(false)
  
        })
      .catch(error => console.log(error))

        }



    };


  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>  
                 
        </div>
      )

    }else{


        return (

          <div>
            <h3>Create new user account here</h3>
          <Form
            name="sigunp"
            onFinish={this.onFinish}
          >
  
          <Form.Item
              label="User Name"
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username"  value={this.state.username}  onChange={(val)=>{this.setState({username:val.target.value})}} />
            </Form.Item>
  
            <Form.Item
              label="E-mail"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
            >
              <Input type="mail" prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" value={this.state.email} onChange={(val)=>{this.setState({email:val.target.value})}}  />
  
            </Form.Item>
      
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true, message: 'Please input your password!',
                },

              ]}
              hasFeedback



            >
              <Input.Password 
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              value={this.state.password1}
              onChange={(val)=>{this.setState({password1:val.target.value})}}
              />
  
  
            </Form.Item>
  
      
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
      
                    return Promise.reject('The two passwords that you entered do not match!');
                  },
                }),
              ]}
            >
              <Input.Password 
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password" 
              value={this.state.password2}
              onChange={(val)=>{this.setState({password2:val.target.value})}}
              />
  
            </Form.Item>
  
            <Form.Item
              label="Is Active"

            >
                <Switch
                    checked={this.state.isactive}
                    onChange={val=>{this.setState({isactive:val})}}
                    checkedChildren="YES"
                    unCheckedChildren="NO"
                    />
              </Form.Item>
  
              <Form.Item
              label="Is Manager"

            >
                <Switch
                    checked={this.state.ismanager}
                    onChange={val=>{this.setState({ismanager:val})}}
                    checkedChildren="YES"
                    unCheckedChildren="NO"
                    />            
             </Form.Item>
  
            <Form.Item label="Profile Image"
              name="profile_pic">
                <Input  type="file" accept="image/png, image/jpeg" placeholder="Profile Image" onChange={this.handleprofile_picChange}/>
            </Form.Item> 


            <FormItem label= {"Phone" }   >
                <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.phone_contact} onChange={(val)=>{this.setState({phone_contact:val})}}/>
             </FormItem> 


              <FormItem 
                  label="Account Type"
                  name="account_type"
                  rules={[
                      {
                        required: true,
                        message: 'Please select',
                      },
                    ]}
                  >
                <Select 
                placeholder="Account Type" 
                value={this.state.account_type} 
                onChange={(val)=>{this.setState({account_type:val})}} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}    
                >
                  <Option value={'restaurant'}>{'Restaurant'}</Option>
                  <Option value={'rider'}>{'Rider'}</Option>
                  <Option value={"adminstrator"}>{"Adminstrator"}</Option>

                </Select>
            </FormItem>

          
             {
              this.state.account_type==="restaurant"?
              <FormItem  label="Restaurant">
              <Select 
              placeholder="Restaurant" 
              value={this.state.restaurant_id} 
              onChange={(val)=>{this.setState({restaurant_id:val})}} 
              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}    
              >
              {this.state.restaurants.map(
              (cat)=>(
                <Option value={cat.id}>{cat.name}</Option>
              ))}
              </Select>
             </FormItem>
              :
              null
             }       


            {
              this.state.account_type==="rider"?
              <FormItem  label="Rider">
              <Select 
              placeholder="Rider" 
              value={this.state.rider_id} 
              onChange={(val)=>{this.setState({rider_id:val})}} 
              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}    
              >
              {this.state.riders.map(
              (cat)=>(
                <Option value={cat.id}>{cat.name}</Option>
              ))}
              </Select>
             </FormItem>
              :
              null
             } 


            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create Account
              </Button>
            </Form.Item>
            
          </Form>

          </div>
        );

    }

  }

}


export default UserCreationForm;


