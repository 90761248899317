import React from "react";
import axios from "axios";
import {
  Table,
  Input,
  Button,
  Collapse,
  Popover,
  Popconfirm,
  Form,
  message,
  Tabs,
  Spin,
  Modal,
  Select,
  Result,
  DatePicker,
  Tooltip,
  Card,
  Divider,
  Switch
} from "antd";
import Highlighter from "react-highlight-words";
import Icon from "@ant-design/icons";
import {
  SearchOutlined,
  DeleteOutlined,
  FundViewOutlined,
  DownloadOutlined,
  PrinterOutlined,
  LoadingOutlined,
  EditFilled,
  CloseCircleOutlined,
  PlayCircleOutlined,
  CheckCircleFilled,
  PlusCircleOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import * as serverconfig from "../serverconn";
import CurrencyFormat from "react-currency-format";
import * as reactstrp from "reactstrap";
import ReactExport from "@ibrahimrahmani/react-export-excel";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { Col, Row } from "reactstrap";
import OrderUpdateForm from "./OrderUpdateForm";
import { MdMotorcycle } from "react-icons/md";
import * as primarycolor from '../primarycolor'
import '../../App.css'; // Create this file for custom styles

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

var CryptoJS = require("crypto-js");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem = Form.Item;
var token = "";
var bizuserid = "";

const { Search } = Input;
const { Option } = Select;
const { TextArea } = Input;


const dateFormat = "DD/MM/YYYY";
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;


const rowClassName = (record) => {
  return record.order_type === "Online Order" ? 'highlighted-row' : '';
};


class ReportToPrint extends React.Component {
  state = {
    companyprofile: {},
  };

  componentDidMount() {
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      token = "";
    }

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    axios
      .get(serverconfig.backendserverurl + `/api/app_profile/${1}`)
      .then((res) => {
        this.setState({
          companyprofile: res.data,
        });
      });
  }

  //clactulate totla of items
  calculateTotalofItems = () => {
    var total = 0;

    JSON.parse(this.props.selected_record.item_details).map((item) => {
      total += Number(item.total);
    });

    return total;
  };

  render() {
    return (
      <div style={{ padding: 20 }}>
        <Row>
          <Col xs="2" sm="2" lg="2">
            <img
              height="150"
              width="150"
              alt="Logo"
              src={this.state.companyprofile.company_logo}
            />
          </Col>
          <Col xs="6" sm="6" lg="6">
            <h3 style={{ fontWeight: "bolder" }}>
              {this.state.companyprofile.system_name}
            </h3>
            <h6 style={{ fontWeight: "bolder" }}>
              Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
            </h6>
            <h5 style={{ fontWeight: "bolder" }}>
              Email: {this.state.companyprofile.company_email}
            </h5>
          </Col>
        </Row>

        <reactstrp.Table bordered>
          <thead>
            <tr>
              <th>
                <h4 style={{ fontWeight: "bolder" }}>
                  DATE: {this.props.selected_record.date}
                </h4>
                <h4 style={{ fontWeight: "bolder" }}>
                  ORDER: {this.props.selected_record.ordernumber}
                </h4>
                <h4 style={{ fontWeight: "bolder" }}>
                  ITEMS: {this.props.selected_record.items_total}
                </h4>
                <h4 style={{ fontWeight: "bolder" }}>
                  STATUS: {this.props.selected_record.order_status}
                </h4>
                <h4 style={{ fontWeight: "bolder" }}>
                  RESTAURANT: {this.props.selected_record.restaurantname}
                </h4>
                <h4 style={{ fontWeight: "bolder" }}>
                  RIDER: {this.props.selected_record.assigned_ridername}
                </h4>
              </th>

              <th>
                <h4 style={{ fontWeight: "bolder" }}>
                  CUSTOMER NAME: {this.props.selected_record.client_name}
                </h4>
                <h4 style={{ fontWeight: "bolder" }}>
                CUSTOMER PHONE: {this.props.selected_record.client_phone}
                </h4>
                <h4 style={{ fontWeight: "bolder" }}>
                CUSTOMER EMAIL: {this.props.selected_record.client_email}
                </h4>
                <h4 style={{ fontWeight: "bolder" }}>
                  DELIVERY AREA: {this.props.selected_record.area}
                </h4>
              </th>

              <th>
                <h4 style={{ fontWeight: "bolder" }}>
                  DELIVERY FEES:
                  <CurrencyFormat
                    value={this.props.selected_record.delivery_charges}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </h4>

         

                <h4 style={{ fontWeight: "bolder" }}>
                  ITEMS COST:
                  <CurrencyFormat
                    value={this.props.selected_record.order_total}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </h4>

                <h4 style={{ fontWeight: "bolder" }}>
                  TOTAL:
                  <CurrencyFormat
                    value={
                      Number(this.props.selected_record.order_total) +
                      Number(this.props.selected_record.delivery_charges)
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </h4>

                <h4 style={{ fontWeight: "bolder" }}>
                  TOTAL PAID:
                  <CurrencyFormat
                    value={this.props.selected_record.total_paid}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </h4>
              </th>
            </tr>
          </thead>
        </reactstrp.Table>
        <br></br>

        <reactstrp.Table bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>Item</th>
              <th>Quantity</th>
              <th>Amount</th>
              <th>Total</th>
              <th>Choices</th>
              <th>Extras</th>
              <th>Packaging Fees</th>
            </tr>
          </thead>
          <tbody>
          {JSON.parse(this.props.selected_record.item_details).map((item) => (
              <tr>
                <td>{item.key}</td>
                <td>{item.item}</td>
                <td>{item.quantity}</td>
                <td>{item.price}</td>
                <td>{item.total}</td>
                <th>
                {
                  JSON.parse(item.choice_list).length>0?
                  <span>
                  <span>
                  {
                    JSON.parse(item.choice_list).map((x_itm)=>{
                      return ""+(x_itm.item)+", "
                    })       
                  }
                  </span>
                  </span>
                  :
                  null
                }



                </th>
                <th>
                {
                JSON.parse(item.extra_list).length>0?
                <span>
                <span>
                {
                  JSON.parse(item.extra_list).map((x_itm)=>{
                    return "( "+(x_itm.item)+","+(x_itm.price)+" ), "
                  })       
                }
                </span>
                </span>
                :
                null
              }

               </th>
               <th>{item.packaging_fees}</th>

              </tr>
            ))}
          </tbody>
        </reactstrp.Table>

      </div>
    );
  }
}

class ReportToPrint2 extends React.Component {
  state = {
    companyprofile: {},
  };

  componentDidMount() {
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      token = "";
    }

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    axios
      .get(serverconfig.backendserverurl + `/api/app_profile/${1}`)
      .then((res) => {
        this.setState({
          companyprofile: res.data,
        });
      });

  }



  //clactulate totla of items
  calculateTotalofItems_deliveryfees = () => {
    var total = 0;

    this.props.clientorders.map((item) => {
      total += Number(item.delivery_charges);
    });

    return total;
  };

  calculateTotalItems_cost = () => {
    var total = 0;

    this.props.clientorders.map((item) => {
      total += Number(item.order_total);
    });

    return total;
  };

  calculateTotalofItems_Paid = () => {
    var total = 0;
    this.props.clientorders.map((item) => {
      total += Number(item.total_paid);
    });

    return total;
  };



  render() {
    return (
      <div style={{padding:20}}>

        <Row>
          <Col xs="2" sm="2" lg="2">
            <img
              height="150"
              width="150"
              alt="Logo"
              src={this.state.companyprofile.company_logo}
            />
          </Col>
          <Col xs="6" sm="6" lg="6">
            <h3 style={{ fontWeight: "bolder" }}>
              {this.state.companyprofile.system_name}
            </h3>
            <h6 style={{ fontWeight: "bolder" }}>
              Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
            </h6>
            <h5 style={{ fontWeight: "bolder" }}>
              Email: {this.state.companyprofile.company_email}
            </h5>
          </Col>
        </Row>

        <h2
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bolder",
          }}
        >
          CLIENT ORDERS FROM {this.props.dateone} TO {this.props.datetwo}
        </h2>


        <reactstrp.Table bordered>
          <thead>

            <tr>

            {
              this.props.showorder_id?
              <th>Order ID</th>
              :
              null
            }


            {
                this.props.show_ordertype?
              <th>Order Type</th>
              :
              null
            }

            {
                this.props.show_orderstatus?

              <th>Status</th>
              :
              null
            }

            {
                this.props.show_orderdate?
              <th>Date (DD/MM/YYYY)</th>
              :
              null
            }


              {
                this.props.show_restaurant?
              <th>Restaurant</th>
              :null}


              {
               this.props.show_rider?
              <th>Assigned Rider</th>
              :null}

              {
               this.props.show_deliveryArea?
              <th>Delivery area</th>
              :null}

{
               this.props.show_deliveryFees?

              <th>Delivery Fees</th>
              :null}
              
              {
               this.props.show_customername?

              <th>Customer Name</th>
              :null}

              {
                this.props.show_customerphone?
 
              <th>Customer Phone</th>
              :null}

              {
                this.props.show_customeremail?
 
              <th>Customer Email</th>
              :null}


              {
                this.props.show_total?
 
              <th>Total</th>
              :null}

              {
                this.props.show_totalpaid?
 
              <th>Total Paid</th>
              :null}


            </tr>
          </thead>
          <tbody>
            {this.props.clientorders.map((item) => (
              <tr>

                {
                  this.props.showorder_id?
                <td>{item.ordernumber}</td>:null}
              
                {
                this.props.show_ordertype?

                <td>{item.order_type}</td>:null}
             

                {
                this.props.show_orderstatus?
                <td>{item.order_status}</td>:null}
               
               
                {
                this.props.show_orderdate?

                <td>{item.date}</td>:null}



                {
                this.props.show_restaurant?
                <td>{item.restaurantname}</td>
                :null}



                {
               this.props.show_rider?
                <td>{item.assigned_ridername}</td>:null}



                {
               this.props.show_deliveryArea?

                <td>{item.area}</td>:null}



                {
               this.props.show_deliveryFees?

                <td>
                  {
                    <CurrencyFormat
                      value={Number(item.delivery_charges)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                </td>:null}


                {
               this.props.show_customername?

                <td>{item.client_name}</td>:null}
               
                {
                this.props.show_customerphone?

                <td>{item.client_phone}</td>:null}


                {
                this.props.show_customeremail?

                <td>{item.client_email}</td>:null}

                
                {
                this.props.show_total?

                <td>
                  {
                    <CurrencyFormat
                      value={Number(item.order_total)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                </td>:null}



                {
                this.props.show_totalpaid?

                <td>
                  {
                    <CurrencyFormat
                      value={
                        Number(item.total_paid)
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                </td>
                :null}

              </tr>
            ))}

            <tr>
             {
               this.props.showorder_id?
              <td></td>:null}

              {
                this.props.show_ordertype?

              <td></td>:null}

              {
                this.props.show_orderstatus?

              <td></td>:null}

              {
                this.props.show_orderdate?

              <td></td>:null}

              {
                this.props.show_restaurant?

              <td></td>:null}


              {
               this.props.show_rider?
                <td></td>:null}

              {
               this.props.show_deliveryArea?
              <td></td>:null}


              {
               this.props.show_deliveryFees?
              <th>
                {
                  <CurrencyFormat
                    value={this.calculateTotalofItems_deliveryfees()}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
              </th>:null}


              {
               this.props.show_customername?

              <td></td>:null}

              {
                this.props.show_customerphone?
              <td></td>:null}


              {
                this.props.show_customeremail?
              <td></td>:null}


              {
                this.props.show_total?

              <th>
                {
                  <CurrencyFormat
                    value={this.calculateTotalItems_cost()}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
              </th>:null}
           

              {
               this.props.show_totalpaid?
              <th>
                {
                  <CurrencyFormat
                    value={this.calculateTotalofItems_Paid()}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
              </th>:null}

            </tr>
          </tbody>
        </reactstrp.Table>
      </div>
    );
  }
}

var token = "";
class OrdersList extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",
    clientorders: [],
    datarequested: true,
    selected_record: {},
    accounts: [],
    selected_agent: "",

    dateone: moment().format(dateFormat).toString(),
    datetwo: moment().format(dateFormat).toString(),
    order_status: "All",


    order_cancel_visible:false,
    cancelling_reason:'',
    selected_record:{},
    datasubmittedsuccessfully:false,
    
    order_assigned_rider:'',
    order_area:'',

    order_reconciliation_visible:false,
    paidAmount:0,
    reconciliation_status:'',
    userrights:{},


    show_all:true,
    showorder_id:true,
    show_ordertype:true,
    show_orderstatus:true,
    show_orderdate:true,
    show_restaurant:true,
    show_rider:true,
    show_deliveryArea:true,
    show_deliveryFees:true,
    show_customername:true,
    show_customerphone:true,
    show_customeremail:true,
    show_total:true,
    show_totalpaid:true,


  };

  componentDidMount(){

    this.component_load()
      
  }


   getYesterdayDate=()=> {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
  
    const formattedYesterday = `${yesterday.getDate()}/${yesterday.getMonth() + 1}/${yesterday.getFullYear()}`;
    return formattedYesterday;
  }
 

  component_load=()=>{
    this.setState({datarequested:true})

    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      token = "";
    }


    if (localStorage.getItem("bizuserid")) {
      bizuserid = CryptoJS.AES.decrypt(
        localStorage.getItem("bizuserid"),
        "my-secret-key@1234"
      ).toString(CryptoJS.enc.Utf8);
    } else {
      bizuserid = "";
    }


    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    
    var yest_date=this.getYesterdayDate()

    let form_data = new FormData();
    form_data.append("dateone", yest_date);
    form_data.append("datetwo", this.state.datetwo);
    form_data.append("order_status", this.state.order_status);
    if (this.state.dateone === "" || this.state.datetwo === "") {
      message.error("Please dates are missing");
    } else {
      this.setState({ datarequested: true });
      this.setState({ clientorders: [] });
      this.setState({ clientorders_placeholders: [] });

      //Now submit sale data to database
      axios
        .post(
          serverconfig.backendserverurl +
            "/customqueries/getclientorder_deliveriesreport",
          form_data,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.setState({ clientorders: JSON.parse(res.data.report) });
          this.setState({
            clientorders_placeholders: JSON.parse(res.data.report),
          });
        })
        .catch((error) => console.log(error));
    }

    //get agents
    axios
      .get(serverconfig.backendserverurl + `/api/accounts/?is_online=${true}`)
      .then((res) => {
        this.setState({
          accounts: res.data,
        });
      });


      axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
      .then(res => {
          console.log("data: "+res.data[0] )
          this.setState({
            userrights:res.data[0]
          })


          this.setState({datarequested:false})

      })


  }



  //clactulate totla of items
  calculateTotalofItems_deliveryfees = () => {
    var total = 0;

    this.state.clientorders.map((item) => {
      total += Number(item.delivery_charges);
    });

    return total;
  };

  calculateTotalItems_cost = () => {
    var total = 0;

    this.state.clientorders.map((item) => {
      total += Number(item.order_total);
    });

    return total;
  };

  calculateTotalofItems_Paid = () => {
    var total = 0;
    this.state.clientorders.map((item) => {
      total += Number(item.total_paid);
    });

    return total;
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };




  handleDownload = () => {
    const { clientorders } = this.state;
    
    // Define columns based on conditions
    const columns = [];
    if (this.state.showorder_id) columns.push({ label: "Order ID", value: "ordernumber" });
    if (this.state.show_ordertype) columns.push({ label: "Order Type", value: "order_type" });
    if (this.state.show_orderstatus) columns.push({ label: "Order Status", value: "order_status" });
    if (this.state.show_orderdate) columns.push({ label: "Date (DD/MM/YYYY)", value: "date" });
    if (this.state.show_restaurant) columns.push({ label: "Restaurant", value: "restaurantname" });
    if (this.state.show_rider) columns.push({ label: "Assigned Rider", value: "assigned_ridername" });
    if (this.state.show_deliveryArea) columns.push({ label: "Delivery Area", value: "area" });
    if (this.state.show_deliveryFees) columns.push({ label: "Delivery Fees", value: "delivery_charges" });
    if (this.state.show_customername) columns.push({ label: "Customer Name", value: "client_name" });
    if (this.state.show_customerphone) columns.push({ label: "Customer Phone", value: "client_phone" });
    if (this.state.show_customeremail) columns.push({ label: "Customer Email", value: "client_email" });
    if (this.state.show_total) columns.push({ label: "Total", value: "order_total" });
    if (this.state.show_totalpaid) columns.push({ label: "Total Paid", value: "total_paid" });

    // Create an array of arrays to be written to the Excel file
    const dataToWrite = clientorders.map(order =>
      columns.map(column => order[column.value])
    );

    // Add header row
    const headers = columns.map(column => column.label);
    dataToWrite.unshift(headers);

    // Create a worksheet and workbook
    const worksheet = XLSX.utils.aoa_to_sheet(dataToWrite);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');

    // Write the workbook to a file and save it
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, `eats.biz - Orders From ${this.state.dateone} To ${this.state.datetwo}.xlsx`);
  };


  render() {
    const columns = [
      {
        title: "Order ID",
        dataIndex: "ordernumber",
        key: "id",
        ...this.getColumnSearchProps("ordernumber"),

        render: (text, record) => (
          <div>
             <p>
            {text}
            </p>

             {
              record.order_status === "Cancelled"?
              <p style={{fontWeight:'bold'}}>
              {"CANCELLED/REJECTED FOR: "+record.cancelling_reason}
              </p>
              :
              null
            }
 
          </div>
        )  


      },
      {
        title: "Order Type",
        dataIndex: "order_type",
        key: "id",
        ...this.getColumnSearchProps("order_type"),
      },
   
      {
        title: "Status",
        dataIndex: "order_status",
        key: "id",
        ...this.getColumnSearchProps("order_status"),
        render: (text, record) => (
          <div>
          <p
            style={{
              color:
                record.order_status === "pending"
                  ? "orange"
                  : record.order_status === "Cancelled"
                  ? "red"
                  : "green",
            }}
          >
            {text}
          </p>

          {
            this.state.userrights.update_orders===true?

          <p style={{display:'flex',flexDirection:'row',}}>

          { 
             record.order_status==="Accepted" && record.order_type==="Online Order" && record.time_of_orderready_restaurant===null?
              <Tooltip title="Click To Confirm Order Ready" placement="top">
              <Popconfirm title="You are confirming order readiness. Are you sure?" 
                  onConfirm={
                  () => {
                    const t_time="HH:mm:ss"
                    this.setState({datarequested:true})

                    let form_data = new FormData();
                    form_data.append('order_id',record.id);
                    form_data.append('dispatch_time', moment().format(t_time).toString());
      
                    axios.post(serverconfig.backendserverurl+'/customqueries/confirm_orderready_by_restaurant', form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                      })
                    .then(res => 
                      
                      {
                      this.setState({datarequested:false})
                      message.info(res.data.message)

                      this.component_load()
      

                    })
                    .catch(error => console.log(error))
                  }
                }>

                 <PlayCircleOutlined style={{color:'green',fontSize: '30px',margin:2}} />

               </Popconfirm>
              </Tooltip>
              :
              null
           }


          { 
             record.order_status==="Pending" && record.order_type==="Phone Order"?
             <Tooltip title="Click To Confirm Pickup " placement="top">
               <Popconfirm title="You are confirming pickup. Are you sure?" 
                 onConfirm={
                  () => {
                    const t_time="HH:mm:ss"
                    this.setState({datarequested:true})

                    let form_data = new FormData();
                    form_data.append('order_id',record.id);
                    form_data.append('dispatch_time', moment().format(t_time).toString());
      
                    axios.post(serverconfig.backendserverurl+'/customqueries/confirm_orderpickup_by_restaurant', form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                      })
                    .then(res => {
                      message.info(res.data.message)

                      this.setState({datarequested:false})

                     this.component_load()

      
                    })
                    .catch(error => console.log(error))
    
                  }
                }>

                 <MdMotorcycle 
               
               style={{color:'green',fontSize: '30px',margin:2}} />

               </Popconfirm>
              </Tooltip>
              :
              null
            }



          { 
             record.order_status==="Accepted" && record.order_type==="Online Order"?
             <Tooltip title="Click To Confirm Pickup " placement="top">
               <Popconfirm title="You are confirming pickup. Are you sure?" 
                 onConfirm={
                  () => {
                    const t_time="HH:mm:ss"
                    this.setState({datarequested:true})

                    let form_data = new FormData();
                    form_data.append('order_id',record.id);
                    form_data.append('dispatch_time', moment().format(t_time).toString());
      
                    axios.post(serverconfig.backendserverurl+'/customqueries/confirm_orderpickup_by_restaurant', form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                      })
                    .then(res => {
                      message.info(res.data.message)

                      this.setState({datarequested:false})

                     this.component_load()

      
                    })
                    .catch(error => console.log(error))
    
                  }
                }>

                 <MdMotorcycle 
               
               style={{color:'green',fontSize: '30px',margin:2}} />

               </Popconfirm>
              </Tooltip>
              :
              null
            }


            

            {
             record.order_status==="Pending" || record.order_status==="Delivering" || record.order_status==="Accepted"?
             <Tooltip title="Click To Cancel Order " placement="top">
               <CloseCircleOutlined onClick={()=>{
                  this.setState({selected_record:record})
                  this.setState({order_cancel_visible:true})

               }}  style={{color:'#FF6347',fontSize: '30px',margin:2}} />
             </Tooltip>

              :
              null
            }


           {
             record.order_status==="Delivering"?
             <Tooltip title="Click To Reconcile Order " placement="top">
               <CheckCircleFilled onClick={()=>{
                  this.setState({selected_record:record})
                  this.setState({order_reconciliation_visible:true})

               }}  style={{color:'green',fontSize: '30px',margin:2}} />
             </Tooltip>
              :
              null
            }


          </p>
          :
          null}


          </div>


        ),
      },
      {
        title: "Date (DD/MM/YYYY)",
        dataIndex: "date",
        key: "id",
        ...this.getColumnSearchProps("date"),
      },

      {
        title: "Restaurant",
        dataIndex: "restaurantname",
        key: "id",
        ...this.getColumnSearchProps("restaurantname"),
      },
      {
        title: "Assigned Rider",
        dataIndex: "assigned_ridername",
        key: "id",
        ...this.getColumnSearchProps("assigned_ridername"),
      },


      {
        title: "Delivery Area",
        dataIndex: "area",
        key: "id",
      },
      {
        title: "Delivery Fees ",
        dataIndex: "delivery_charges",
        key: "id",
        render: (text) => (
          <CurrencyFormat
            value={text}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
      },

      {
        title: "Customer Name",
        dataIndex: "client_name",
        key: "id",
      },

      {
        title: "Customer Phone",
        dataIndex: "client_phone",
        key: "id",
      },

      {
        title: "Customer Email",
        dataIndex: "client_email",
        key: "id",
      },

      {
        title: "Total",
        dataIndex: "order_total",
        key: "id",
        render: (text) => (
          <CurrencyFormat
            value={text}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
      },

      {
        title: "Total Paid",
        dataIndex: "total_paid",
        key: "id",
        render: (text) => (
          <CurrencyFormat
            value={text}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
      },
    ];

    if (this.state.datarequested === true) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large">
            <div className="content">
              <h3>eats.biz</h3>
            </div>
          </Spin>
        </div>
      );
    } else {


      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
              <Card>
              <Result
              status="success"
              title="Action successful"
              extra={[
                <Button  type='primary' 
                onClick={()=>{
                  this.component_load()
                }}>Finish</Button>,
              ]}
              />
              </Card>
          </div>
        )

      }else{

        return (
          <div>
            <Collapse defaultActiveKey={["1"]} onChange={this.callback}>
              <Panel header="ALL ORDERS" key="1">

                  <div style={{overflowX:'scroll'}}>

                  <div style={{ display: "none" }}>
                  &nbsp;&nbsp;
                  <ReportToPrint2
                    dateone={this.state.dateone}
                    datetwo={this.state.datetwo}
                    clientorders={this.state.clientorders}
                    ref={(el) => (this.componentRef_2 = el)}

                    show_all={this.state.show_all}
                    showorder_id={this.state.showorder_id}
                    show_ordertype={this.state.show_ordertype}
                    show_orderstatus={this.state.show_orderstatus}
                    show_orderdate={this.state.show_orderdate}
                    show_restaurant={this.state.show_restaurant}
                    show_rider={this.state.show_rider}
                    show_deliveryArea={this.state.show_deliveryArea}
                    show_deliveryFees={this.state.show_deliveryFees}
                    show_customername={this.state.show_customername}
                    show_customerphone={this.state.show_customerphone}
                    show_customeremail={this.state.show_customeremail}
                    show_total={this.state.show_total}
                    show_totalpaid={this.state.show_totalpaid}

                  />
                </div>

                <br></br>
                <div style={{display:'flex',flexDirection:'column'}}>
                <h6 style={{color:primarycolor.primarycolor}}>Customize Order Columns to Print/Download</h6>

                <FormItem name="showall" label="" >
                          <Switch
                              checked={this.state.show_all}
                              onChange={val=>{
                                this.setState({show_all:val})
  
                                if (val===true){
                                  this.setState({showorder_id:true})
                                  this.setState({show_ordertype:true})
  
                                  this.setState({show_orderstatus:true})
                                  this.setState({show_orderdate:true})
                                  this.setState({show_restaurant:true})
  
                                  this.setState({show_rider:true})
                                  this.setState({show_deliveryArea:true})
                                  this.setState({show_deliveryFees:true})
                                  
                                  this.setState({show_customername:true})
                                  this.setState({show_customerphone:true})
                                  this.setState({show_customeremail:true})

                                  this.setState({show_total:true})
                                  this.setState({show_totalpaid:true})

                               
  
                                }else{
                                  this.setState({showorder_id:false})
                                  this.setState({show_ordertype:false})
  
                                  this.setState({show_orderstatus:false})
                                  this.setState({show_orderdate:false})
                                  this.setState({show_restaurant:false})
  
                                  this.setState({show_rider:false})
                                  this.setState({show_deliveryArea:false})
                                  this.setState({show_deliveryFees:false})
                                  
                                  this.setState({show_customername:false})
                                  this.setState({show_customerphone:false})
                                  this.setState({show_customeremail:false})
                                  this.setState({show_total:false})
                                  this.setState({show_totalpaid:false})


                                  }

                              }}
                              checkedChildren="CLICK TO CUSTOMIZE"
                              unCheckedChildren="CLICK FOR ALL"
                          />
                      </FormItem>

                </div>
                  
                  {
                    this.state.show_all===false?
                    <Form layout='inline'>
                 
                    <FormItem name="showorder_id" label="Show ID" >
                         <Switch
                             checked={this.state.showorder_id}
                             onChange={val=>{this.setState({showorder_id:val})}}
                             checkedChildren="YES"
                             unCheckedChildren="NO"
                         />
                     </FormItem>
   
                     <FormItem name="show_ordertype" label="Show Order Type" >
                         <Switch
                             checked={this.state.show_ordertype}
                             onChange={val=>{this.setState({show_ordertype:val})}}
                             checkedChildren="YES"
                             unCheckedChildren="NO"
                         />
                     </FormItem>
 
                     <FormItem name="show_orderstatus" label="Show Order Status" >
                         <Switch
                             checked={this.state.show_orderstatus}
                             onChange={val=>{this.setState({show_orderstatus:val})}}
                             checkedChildren="ON"
                             unCheckedChildren="OFF"
                         />
                     </FormItem>
 
                     <FormItem name="show_orderdate" label="Show Order Date" >
                         <Switch
                             checked={this.state.show_orderdate}
                             onChange={val=>{this.setState({show_orderdate:val})}}
                             checkedChildren="YES"
                             unCheckedChildren="NO"
                         />
                     </FormItem>
 
                     <FormItem name="show_restaurant" label="Show Restaurant" >
                         <Switch
                             checked={this.state.show_restaurant}
                             onChange={val=>{this.setState({show_restaurant:val})}}
                             checkedChildren="ON"
                             unCheckedChildren="OFF"
                         />
                     </FormItem>


                     <FormItem name="show_rider" label="Show Rider" >
                         <Switch
                             checked={this.state.show_rider}
                             onChange={val=>{this.setState({show_rider:val})}}
                             checkedChildren="YES"
                             unCheckedChildren="NO"
                         />
                     </FormItem>

  
                     <FormItem name="show_deliveryArea" label="Show Delivery Area" >
                         <Switch
                             checked={this.state.show_deliveryArea}
                             onChange={val=>{this.setState({show_deliveryArea:val})}}
                             checkedChildren="YES"
                             unCheckedChildren="NO"
                         />
                     </FormItem>

                     <FormItem name="show_deliveryFees" label="Show Delivery Fees" >
                         <Switch
                             checked={this.state.show_deliveryFees}
                             onChange={val=>{this.setState({show_deliveryFees:val})}}
                             checkedChildren="YES"
                             unCheckedChildren="NO"
                         />
                     </FormItem>


                     <FormItem name="show_customername" label="Show Customer Name" >
                         <Switch
                             checked={this.state.show_customername}
                             onChange={val=>{this.setState({show_customername:val})}}
                             checkedChildren="YES"
                             unCheckedChildren="NO"
                         />
                     </FormItem>


                     <FormItem name="show_customerphone" label="Show Customer Phone" >
                         <Switch
                             checked={this.state.show_customerphone}
                             onChange={val=>{this.setState({show_customerphone:val})}}
                             checkedChildren="YES"
                             unCheckedChildren="NO"
                         />
                     </FormItem>


                     <FormItem name="show_customeremail" label="Show Customer Email" >
                         <Switch
                             checked={this.state.show_customeremail}
                             onChange={val=>{this.setState({show_customeremail:val})}}
                             checkedChildren="YES"
                             unCheckedChildren="NO"
                         />
                     </FormItem>


                     <FormItem name="show_total" label="Show Total" >
                         <Switch
                             checked={this.state.show_total}
                             onChange={val=>{this.setState({show_total:val})}}
                             checkedChildren="YES"
                             unCheckedChildren="NO"
                         />
                     </FormItem>


                     <FormItem name="show_totalpaid" label="Show Total Paid" >
                         <Switch
                             checked={this.state.show_totalpaid}
                             onChange={val=>{this.setState({show_totalpaid:val})}}
                             checkedChildren="YES"
                             unCheckedChildren="NO"
                         />
                     </FormItem>


               </Form>
                    :
                    null

                  }
             


                <Divider></Divider>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        type="primary"
                        shape="round"
                        icon={<PrinterOutlined />}
                        size={this.state.size}
                      >
                        Print
                      </Button>
                    )}
                    content={() => this.componentRef_2}
                  />
                  &nbsp;&nbsp;
                {/*  <ExcelFile
                    filename={
                      "eats.biz -Orders From " +
                      this.state.dateone +
                      " To " +
                      this.state.datetwo
                    }
                    element={
                      <Button type="primary" icon={<DownloadOutlined />}>
                        Download Excel{" "}
                      </Button>
                    }
                  >

                    <ExcelSheet
                      data={this.state.clientorders}
                      name="Orders"
                    >
                     {this.state.showorder_id && <ExcelColumn label="Order ID" value="ordernumber" />}
                      {this.state.show_ordertype && <ExcelColumn label="Order Type" value="order_type" />}
                      {this.state.show_orderstatus && <ExcelColumn label="Order Status" value="order_status" />}
                      {this.state.show_orderdate && <ExcelColumn label="Date (DD/MM/YYYY)" value="date" />}
                      {this.state.show_restaurant && <ExcelColumn label="Restaurant" value="restaurantname" />}
                      {this.state.show_rider && <ExcelColumn label="Assigned Rider" value="assigned_ridername" />}
                      {this.state.show_deliveryArea && <ExcelColumn label="Delivery Area" value="area" />}
                      {this.state.show_deliveryFees && <ExcelColumn label="Delivery Fees" value="delivery_charges" />}
                      {this.state.show_customername && <ExcelColumn label="Customer Name" value="client_name" />}
                      {this.state.show_customerphone && <ExcelColumn label="Customer Phone" value="client_phone" />}
                      {this.state.show_customeremail && <ExcelColumn label="Customer Email" value="client_email" />}
                      {this.state.show_total && <ExcelColumn label="Total" value="order_total" />}
                      {this.state.show_totalpaid && <ExcelColumn label="Total Paid" value="total_paid" />}

                    </ExcelSheet>
                  </ExcelFile> */}

                <Button type="primary" icon={<DownloadOutlined />} onClick={this.handleDownload}>
                Download Excel
              </Button>





                </div>
  
                <br></br>
                <br></br>
  
                <Form layout="inline">
                  <FormItem label="Order Status">
                    <Select
                      placeholder="Order Status"
                      style={{ width: 200 }}
                      value={this.state.order_status}
                      onChange={(val) => {
                        this.setState({ order_status: val });
                      }}
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value={"All"}>All </Option>
                      <Option value={"Delivered"}>Delivered</Option>
                      <Option value={"Cancelled"}>Cancelled</Option>
                      <Option value={"Delivering"}>Delivering</Option>
                    </Select>
                  </FormItem>
  
                  <FormItem label="Date Range">
                    <RangePicker
                      onChange={(date, dateString) => {
                        this.setState({ dateone: dateString[0] });
                        this.setState({ datetwo: dateString[1] });
                      }}
                      format={dateFormat}
                      placeholder={['Start Date', 'End Date']}

                    />
                  </FormItem>
  
                  <FormItem>
                    <Button
                      onClick={() => {
                        let form_data = new FormData();
                        form_data.append("dateone", this.state.dateone);
                        form_data.append("datetwo", this.state.datetwo);
                        form_data.append("order_status", this.state.order_status);
  
                        if (
                          this.state.dateone === "" ||
                          this.state.datetwo === ""
                        ) {
                          message.error("Please dates are missing");
                        } else {
                          this.setState({ datarequested: true });
                          this.setState({ clientorders: [] });
                          this.setState({ clientorders_placeholders: [] });
  
                          //Now submit sale data to database
                          axios
                            .post(
                              serverconfig.backendserverurl +
                                "/customqueries/getclientorder_deliveriesreport",
                              form_data,
                              {
                                headers: {
                                  "content-type": "multipart/form-data",
                                },
                              }
                            )
                            .then((res) => {

                              this.setState({ datarequested: false });
                              this.setState({
                                clientorders: JSON.parse(res.data.report),
                              });
                              this.setState({
                                clientorders_placeholders: JSON.parse(
                                  res.data.report
                                ),
                              });



                            })
                            .catch((error) => console.log(error));
                        }
                      }}
                      type="primary"
                      htmlType="button"
                    >
                      Search
                    </Button>
                  </FormItem>
                </Form>
  
                <br></br>
  
                <Form layout="inline">
                  <FormItem label="Filter by Rider">
                    <Search
                      placeholder="Filter by Rider"
                      onChange={(value) => {
                        var val = value.target.value;
                        console.log(val);
                        if (val !== "" || val !== undefined) {
                          //set one is by NAME
                          const results_1 = this.state.clientorders.filter(
                            (request) => {
                              return String(request.assigned_ridername)
                                .toLowerCase()
                                .startsWith(val.toLowerCase());
                              // Use the toLowerCase() method to make it case-insensitive
                            }
                          );
  
                          if (results_1.length === 0) {
                            this.setState({
                              clientorders: this.state.clientorders_placeholders,
                            });
                          } else {
                            this.setState({ clientorders: results_1 });
                            console.log(results_1);
                          }
                        } else {
                          this.setState({
                            clientorders: this.state.clientorders_placeholders,
                          });
                        }
                      }}
                      style={{
                        width: 200,
                        margin: 5,
                      }}
                    />
                  </FormItem>
  
                  <FormItem label="Filter by Restaurant">
                    <Search
                      placeholder="Filter by Restaurant"
                      onChange={(value) => {
                        var val = value.target.value;
                        console.log(val);
                        if (val === "" || val === undefined || val === null) {
                          this.setState({
                            clientorders: this.state.clientorders_placeholders,
                          });
                        }
  
                        if (val !== "" || val !== undefined) {
                          //set one is by NAME
                          const results_1 = this.state.clientorders.filter(
                            (request) => {
                              return String(request.restaurantname)
                                .toLowerCase()
                                .startsWith(val.toLowerCase());
                              // Use the toLowerCase() method to make it case-insensitive
                            }
                          );
  
                          if (results_1.length === 0) {
                            this.setState({
                              clientorders: this.state.clientorders_placeholders,
                            });
                          } else {
                            this.setState({ clientorders: results_1 });
                            console.log(results_1);
                          }
                        } else {
                          this.setState({
                            clientorders: this.state.clientorders_placeholders,
                          });
                        }
                      }}
                      style={{
                        width: 200,
                        margin: 5,
                      }}
                    />
                  </FormItem>


                  <FormItem label="Filter by Order Type">
                    <Search
                      placeholder="Filter by Order Type"
                      onChange={(value) => {
                        var val = value.target.value;
                        console.log(val);
                        if (val === "" || val === undefined || val === null) {
                          this.setState({
                            clientorders: this.state.clientorders_placeholders,
                          });
                        }
  
                        if (val !== "" || val !== undefined) {
                          //set one is by NAME
                          const results_1 = this.state.clientorders.filter(
                            (request) => {
                              return String(request.order_type)
                                .toLowerCase()
                                .includes(val.toLowerCase());
                              // Use the toLowerCase() method to make it case-insensitive
                            }
                          );
  
                          if (results_1.length === 0) {
                            this.setState({
                              clientorders: this.state.clientorders_placeholders,
                            });
                          } else {
                            this.setState({ clientorders: results_1 });
                            console.log(results_1);
                          }
                        } else {
                          this.setState({
                            clientorders: this.state.clientorders_placeholders,
                          });
                        }
                      }}
                      style={{
                        width: 200,
                        margin: 5,
                      }}
                    />
                  </FormItem>



                  <FormItem label="Filter by Delivery Area">
                    <Search
                      placeholder="Filter by Delivery Area"
                      onChange={(value) => {
                        var val = value.target.value;
                        console.log(val);
                        if (val === "" || val === undefined || val === null) {
                          this.setState({
                            clientorders: this.state.clientorders_placeholders,
                          });
                        }
  
                        if (val !== "" || val !== undefined) {
                          //set one is by NAME
                          const results_1 = this.state.clientorders.filter(
                            (request) => {
                              return String(request.area)
                                .toLowerCase()
                                .includes(val.toLowerCase());
                              // Use the toLowerCase() method to make it case-insensitive
                            }
                          );
  
                          if (results_1.length === 0) {
                            this.setState({
                              clientorders: this.state.clientorders_placeholders,
                            });
                          } else {
                            this.setState({ clientorders: results_1 });
                            console.log(results_1);
                          }
                        } else {
                          this.setState({
                            clientorders: this.state.clientorders_placeholders,
                          });
                        }
                      }}
                      style={{
                        width: 200,
                        margin: 5,
                      }}
                    />
                  </FormItem>



                </Form>
                <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>ORDERS : {this.state.clientorders.length}</th>
  
                      <th>
                        TOTAL DELIVERY:{"  UGX "}
                        {
                          <CurrencyFormat
                            value={this.calculateTotalofItems_deliveryfees()}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        }
                      </th>
                      <th>
                        ITEMS COST :{" UGX "}
                        {
                          <CurrencyFormat
                            value={this.calculateTotalItems_cost()}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        }
                      </th>
                      <th>
                        TOTAL :{" UGX "}
                        {
                          <CurrencyFormat
                            value={
                              Number(this.calculateTotalItems_cost()) +
                              Number(this.calculateTotalofItems_deliveryfees())
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        }
                      </th>
                      <th>
                        TOTAL PAID :{" UGX "}
                        {
                          <CurrencyFormat
                            value={this.calculateTotalofItems_Paid()}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        }
                      </th>
                    </tr>
                  </thead>
                </reactstrp.Table>
                <br></br>
  
                <Table
                  columns={columns}
                  scroll={{ x: 1000 }}
                  pagination={{ showQuickJumper: true, showSizeChanger: true }}
                  dataSource={this.state.clientorders}
                  bordered
                  expandable={{
                    expandedRowRender: (record) => {
                      return (
                        <div
                          style={{
                            margin: 0,
                          }}
                        >
                          <div style={{ display: "none" }}>
                            &nbsp;&nbsp;
                            <ReportToPrint
                              selected_record={record}
                              ref={(el) => (this.componentRef = el)}
                            />
                          </div>
  
                          <ReactToPrint
                            trigger={() => (
                              <Button
                                type="primary"
                                shape="round"
                                icon={<PrinterOutlined />}
                                size={this.state.size}
                              >
                                Print Order
                              </Button>
                            )}
                            content={() => this.componentRef}
                          />
  
                          <br></br>
                          <br></br>
                          <Tabs>


                            {
                              this.state.userrights.update_orders===true?
                              <TabPane tab="Order Update" key={'03'}>
                              <OrderUpdateForm 
                                order_obj={record}
                                restaurant_id={record.restaurant}
                                onRefresh={this.component_load}
                              />
                            </TabPane>  
                            :
                            null
                            }  

                            <TabPane tab="Items" key="01">

                              <reactstrp.Table bordered>
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Item</th>
                                    <th>Quantity</th>
                                    <th>Amount</th>
                                    <th>Total</th>
                                    <th>Choices</th>
                                    <th>Extras</th>
                                    <th>Packaging fees</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {JSON.parse(record.item_details).map((item) => (
                                    <tr>
                                      <td>{item.key}</td>
                                      <td>{item.item}</td>
                                      <td>{item.quantity}</td>
                                      <td>{item.price}</td>
                                      <td>{item.total}</td>
                                      <th>
                                      {
                                        JSON.parse(item.choice_list).length>0?
                                        <span>
                                        <span>
                                        {
                                          JSON.parse(item.choice_list).map((x_itm)=>{
                                            return ""+(x_itm.item)+", "
                                          })       
                                        }
                                        </span>
                                        </span>
                                        :
                                        null
                                      }


                                      </th>
                                      <th>
                                      {
                                      JSON.parse(item.extra_list).length>0?
                                      <span>
                                      <span>
                                      {
                                        JSON.parse(item.extra_list).map((x_itm)=>{
                                          return "( "+(x_itm.item)+","+(x_itm.price)+" ), "
                                        })       
                                      }
                                      </span>
                                      </span>
                                      :
                                      null
                                    }

                                    </th>
                                    <th>{item.packaging_fees}</th>

                                    </tr>
                                  ))}
                                </tbody>
                              </reactstrp.Table>

                            </TabPane>

                            <TabPane tab="Time Stamps" key="02">
                              <p>Time Of Order Acceptance by Restaurant: {record.time_of_orderacceptance_restaurant}</p>
  
                              <p>Time Of Rider Dispatch : {record.time_of_riderdispatch_restaurant}</p>
                              <p>Time Of Dispatch Accepted : {record.time_of_dispatchaccepted_rider}</p>
  
                              <p>Time Of Order Ready : {record.time_of_orderready_restaurant}</p>
                              <p>Time Of Order Picked : {record.time_of_orderpicked_rider}</p>
                              <p>Time Of Order Delivered : {record.time_of_orderdelivered_rider}</p>
                              <p>Time Of Reconciliation : {record.time_of_orderreconciled_restaurant}</p>
  
                            </TabPane>
                            
                          </Tabs>
                        </div>
                      );
                    },
                    rowExpandable: (record) => true,
                    onExpand: (condition, record) => {},
                    expandIcon: (props) => {
                      const { expanded, onExpand } = props;
      
                       return (<span style={{ color: primarycolor.primarycolor, fontSize: '30px' }}
                       onClick={(e) => {
                        onExpand(props.record, e);
                      }}
                       
                       ><PlusCircleOutlined /></span>)
                      
                    }

                  }}
                  size='small'
                  rowClassName={rowClassName}

                />

                </div>

              </Panel>
            </Collapse>
  

            <Modal   
            visible={this.state.order_cancel_visible}
            title="Cancel Order"
            onCancel={()=>{
              this.setState({order_cancel_visible:false})
  
            }}
        
            footer={[
              <Button key="back" onClick={()=>{
                this.setState({order_cancel_visible:false})
  
              }}>
                Cancel
              </Button>,
              <Button key="back" type="primary" 
                onClick={()=>{
  
                  if (this.state.cancelling_reason===""){
                    message.error("Please enter a reason")
  
                  }else{
                    this.setState({datarequested:true})
  
                    let form_data = new FormData();
                    form_data.append('order_id',this.state.selected_record.id);
                    form_data.append('cancelling_reason',this.state.cancelling_reason);
      
                    axios.post(serverconfig.backendserverurl+'/customqueries/cancelorder_byadmin', form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                      })
                    .then(res => {
                      this.setState({datarequested:false})
                       this.component_load() 

                    })
                    .catch(error => console.log(error))
              
                    this.setState({order_cancel_visible:false})
                  }
  
              }}>
                Confirm
              </Button>
  
              ]}
              >
  
          <FormItem label="Reason For Cancelling">
                <TextArea
                      placeholder="Reason ."
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      value={this.state.cancelling_reason}
                      onChange={(val)=>{this.setState({cancelling_reason:val.target.value})}}
                    />
          </FormItem>
  
          </Modal> 




          <Modal   
            visible={this.state.order_reconciliation_visible}
            title="Order Reconciliation Form"
            onCancel={()=>{
              this.setState({order_reconciliation_visible:false})
            }}
        
            footer={[
              <Button key="back" onClick={()=>{
                this.setState({order_reconciliation_visible:false})
  
              }}>
                Cancel
              </Button>,
              <Button key="back" type="primary" 
                onClick={()=>{
  
                  if (this.state.reconciliation_status===""){
                    message.error("Please make achoice")
                  }else{
                    this.setState({datarequested:true})
                    const t_time="HH:mm:ss"
                    var total=Number(this.state.selected_record.order_total)+Number(this.state.selected_record.delivery_charges)
  
                    let form_data = new FormData();
                    form_data.append('order_id',this.state.selected_record.id);
                    form_data.append('paidAmount',total);
                    form_data.append('reconciliation_status',this.state.reconciliation_status);
  
                    form_data.append('dispatch_time', moment().format(t_time).toString());
                    axios.post(serverconfig.backendserverurl+'/customqueries/confirm_orderreconciliation', form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                      })
                    .then(res => {
                      this.setState({datarequested:false})

                      message.info(res.data.message)
                      this.component_load()
                 
                    })
                    .catch(error => console.log(error))
  
                    this.setState({order_reconciliation_visible:false})
                  }
  
              }}>
                Submit 
              </Button>
  
              ]}
              >
  
             <FormItem label="Reconciled">
                    <Select 
                  placeholder="choice" 
                  value={this.state.reconciliation_status} 
                  onChange={(val)=>{
                    this.setState({reconciliation_status:val})
                  }} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}    
                  >
                    <Option value={'yes'}> {'Yes'}</Option>
                    <Option value={'no'}> {'No'}</Option>
                  </Select>
  
             </FormItem>
         
          </Modal> 

          </div>
        );

      }

    }
  }
}

export default OrdersList;
