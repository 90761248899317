import React from 'react';
import { Form, Input, Button,Spin,Select,DatePicker,Image,Switch } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

var CryptoJS = require("crypto-js");
const dateFormat = 'DD/MM/YYYY';

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var username=''

const { TextArea } = Input;

class PromoDetails extends React.Component {

  state = {
    head:'' ,  
    description:'',
    datarequested:false,
    active:false,
    image:null,
    image_file:'',
    date_created:moment().format(dateFormat).toString(),
    date_of_expiry:'',

  }

  componentDidMount(){
    this.component_reloaded()

  }


  component_reloaded=()=>{
    this.setState({datarequested:true})

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("username")){
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    }else{
      username=''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    var promoID=this.props.promoID

    axios.get(`${serverconfig.backendserverurl}/api/promos/${promoID}`)
    .then(res => {  

        this.setState({image_file:res.data.image})

        this.setState({head:res.data.head})
        this.setState({description:res.data.description})
        this.setState({active:res.data.active})
        this.setState({date_created:res.data.date_created})
        this.setState({date_of_expiry:res.data.date_of_expiry})

        this.setState({datarequested:false})
    })




  }




  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})
    var promoID=this.props.promoID

    let form_data = new FormData();
    form_data.append('head', this.state.head);
    form_data.append('description', this.state.description);
    form_data.append('active', this.state.active);

    this.state.image==null?
    console.log("No image file")
    :
    form_data.append('image', this.state.image,this.state.image?this.state.image.name:"");

    form_data.append('user', username);
    form_data.append('date_created',this.state.date_created);
    form_data.append('date_of_expiry',this.state.date_of_expiry);

    axios.put(serverconfig.backendserverurl+`/api/promos/${promoID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => {
    this.setState({datarequested:false})
    this.component_reloaded()

    //reload parent
    this.props.onRefresh()

  }    

  )
  .catch(error => console.log(error))

  }
  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Head"
        
      >
        <Input name="head" 
         placeholder="Put a head here."
         value={this.state.head} 
         onChange={(val)=>{this.setState({head:val.target.value})}} />
      </FormItem>

      <FormItem label="Description"
      
      >
        <TextArea 
         name="Description" 
         placeholder="Put a description here."
         value={this.state.description} 
         onChange={(val)=>{this.setState({description:val.target.value})}}
         autoSize={{ minRows: 2, maxRows: 6 }}
         />
      </FormItem>

      <FormItem name="active" label="Active" >
        <Switch
          checked={this.state.active}
          onChange={val=>{this.setState({active:!this.state.active})}}
          checkedChildren="YES"
          unCheckedChildren="NO"
          />
      </FormItem>

      <FormItem
        
      
       label={"Date Of Expiry ("+this.state.date_of_expiry+")"}>
          <DatePicker onChange={(date, dateString)=>{this.setState({ date_of_expiry: dateString})}} format={dateFormat} />
       </FormItem>
     

       <div style={{display:'flex',flexDirection:'row'}}>
      <FormItem label="Image"
      
      >
          <Input name="image" type="file" accept="image/png, image/jpeg" placeholder="Image" 
          
          onChange={(e) =>{
          if(e.target.files[0]){
            this.setState({
              image_file: URL.createObjectURL(e.target.files[0])
            })
          }
          this.setState({ image: e.target.files[0]})

          } }/>
      </FormItem> 

      {this.state.image!=null || this.state.image_file!=""?
        <Image
        width={50}
        height={50}
        src={this.state.image_file}    
        />
        :
        null
        }
      </div>


      <FormItem>
        <Button  type="primary" htmlType="submit">Update</Button>
      </FormItem>
       </Form>
        </div>
      );
    }

  }

}


export default PromoDetails;



