import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Modal,Divider,Tabs
  ,Card,Form,Select,InputNumber,Drawer,Descriptions,Popconfirm,message } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,
  EditOutlined,PrinterFilled,
  ShoppingCartOutlined,
  AppstoreAddOutlined,
  DeleteOutlined,
  FundViewOutlined,
  PlusOutlined,
  WalletFilled,
  DollarCircleFilled,
  FileTextFilled
} from '@ant-design/icons';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
} from 'reactstrap';
import PhoneInput from 'react-phone-number-input'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom'

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import ReactToPrint from 'react-to-print';
import * as reactstrp from 'reactstrap';
import moment from 'moment';

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;
const dateFormat = 'DD/MM/YYYY';

var token= ''
var bizuserid=''

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{},
    date:moment().format(dateFormat).toString(),

  };

  componentDidMount(){
    axios.get(serverconfig.backendserverurl + `/api/app_profile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })
  }


  render() {
    return (
      <div style={{padding:20}}>
        <div style={{display:'flex', flexDirection:'',justifyContent: 'space-between', margin:3}}>
           
           <div style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly'}}>
           <img
              height="200"
              width="200"
              alt="Logo"
              src={this.state.companyprofile.company_logo}
            />

            <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
             
              <h6 style={{ fontWeight: "bolder" }}>
                Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
              </h6>
              <h5 style={{ fontWeight: "bolder" }}>
                Email: {this.state.companyprofile.company_email}
              </h5>

            </div>

           </div>

           <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>

            <h2
              style={{
                display: "flex",
                justifyContent: 'flex-end',
                alignItems: "center",
                fontWeight: "bolder",
              }}
            >
              INVOICE PAYMENT RECEIPT
            </h2>
            </div>

        </div> 
        <Divider></Divider>


        <div style={{display:'flex', flexDirection:'',justifyContent: 'space-between', margin:3}}>
          <div>
           <h4 style={{ fontWeight: "bolder" }} >
            {" RECEIPT TO: " + this.props.clientdetails.name}
          </h4>
          <h4 style={{ fontWeight: "bolder" }} >
            {this.props.clientdetails.address}
          </h4>
          </div>


          <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
          <h4 style={{ fontWeight: "bolder",alignSelf:'flex-end' }} >
          {"INVOICE NO: " + this.props.invoiceobj.invoice_no}
          </h4>
          <h4 style={{ fontWeight: "bolder",alignSelf:'flex-end' }} >
           {" DATE: " + this.props.invoiceobj.date}
          </h4>

          <h4 style={{ fontWeight: "bolder",alignSelf:'flex-end' }} >
           {" TYPE: " + this.props.invoiceobj.invoice_type}
          </h4>

          <h4 style={{ fontWeight: "bolder",alignSelf:'flex-end' }} >
           {" DETAILS: " + this.props.invoiceobj.details}
          </h4>

       
        
          </div>

        </div>

        <Divider></Divider>


        <reactstrp.Table bordered>
                  <thead>
                 
                    <tr>
                      <th><h3>AMOUNT PAID</h3></th>
                      <th><h3>UGX <CurrencyFormat value={this.props.selected_payment.amount} displayType={'text'} thousandSeparator={true}   /></h3></th>
                    </tr>

                    <tr>
                      <th><h3>INVOICE TOTAL PAID</h3></th>
                      <th><h3>UGX <CurrencyFormat value={this.props.invoicetotalpayment} displayType={'text'} thousandSeparator={true}   /></h3></th>

                    </tr>

                    <tr>
                      <th><h3>INVOICE TOTAL BALANCE</h3></th>
                      <th><h3>UGX <CurrencyFormat value={Number(this.props.invoiceobj.totalAmount)-Number(this.props.invoicetotalpayment)} displayType={'text'} thousandSeparator={true}   /></h3></th>

                    </tr>

                    <tr>
                      <th><h3>PAYMENT CHANNEL</h3></th>
                      <th><h3>{this.props.selected_payment.payment_account}</h3> </th>
                    </tr>

                  </thead>
                  <tbody>
                  
                </tbody>
            </reactstrp.Table>

            <Divider></Divider>
              <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
                <h4 style={{ fontWeight: "bolder",alignSelf:'flex-end' }}>MOTOR CITY IMPORTS LTD</h4>
                <h4 style={{ fontWeight: "bolder",alignSelf:'flex-end' }}>BANK ACCOUNT: STANBIC - 9030009507611 </h4>
                <h4 style={{ fontWeight: "bolder",alignSelf:'flex-end' }} >
                MTN: 570379 
                </h4>
                <h4 style={{ fontWeight: "bolder",alignSelf:'flex-end' }} >
                AIRTEL: 4360801 
                </h4>

              </div>


          <br></br>
        <div>
        { ReactHtmlParser(this.props.termsandcons) }
        </div>

      
    </div>     

    );
  }
}


class invoice_paymentsList extends React.Component {
  
  formRef = React.createRef();
  state = {
    searchText: '',
    searchedColumn: '',
    invoices: [],
    visible:false,
    editvisible:false,
    selectedid:"",
    invoicedetails:{},
    viewpaymentmodal:false,
    othercharges:[],
    receiptitems:[],
    invoicetotalpayment:0,
    invoice_payments:[],
    client:'',
    date: '',
    notes: '',
    estimatenumber: '',
    termsandcons:'',
    estimatetype:'',
    clientdetails:{},
    showprintermodal:false,
    totalTax:0,
    totalothercharges:0,
    receiptbalance:0,
    receipttotal:0,
    netAmount:0,
    duedate:'',
    invoicenumber:'',
    tax:0,
    discount:0,
    discounttype:'',
    discountAmnt:0,
    netAmount:0,
    otherchargesvisible:false,
    emailmodalvisible:false,
    companyprofile:{},
    emailfile:null,
    invoicetype:'',
    pono:'',
    dueAmount:0,
    amountPaid:0,
    invoicedetails:{},
    invoicetotalpayment:0,
    vatpercentage:0,
    productdescription:"",
    itemnumber:0,
    unpaidinvoices:[],   
    paidinvoices:[],    
    showcostofproductionmodal:false,
    costamount:0,
    selected_payment:{},
    invoiceobj:{},
    userrights:{},

  };



  componentDidMount(){
    this.compo_loaded()
    }

compo_loaded=()=>{

  if(localStorage.getItem("token")){
    token= localStorage.getItem("token")
    bizuserid=localStorage.getItem("token")
 }else{
    token= ''
    bizuserid=''
 }

 axios.defaults.headers={
   "Content-Type":"application/json",
   Authorization:`Token ${token}`
 }


 axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
 .then(res => {  
      this.setState({
        companyprofile: res.data
      })
  })


  const invoiceID=this.props.invoiceID;

  axios.get(`${serverconfig.backendserverurl}/api/invoices/${invoiceID}`)
  .then(res => { 
    this.setState({invoiceobj:res.data})


    //make query to get client details
    axios.get(`${serverconfig.backendserverurl}/api/restaurants/${res.data.restaurant}`)
    .then(res => {  
        this.setState({
          clientdetails:res.data
        })
    })

  })


  //getting the invoice total payment now
  axios.get(`${serverconfig.backendserverurl}/api/invoice_payments/?invoice=${invoiceID}`)
  .then(res => {  
    this.setState({invoice_payments:res.data})

    const totalPaid =res.data.reduce((totalPaid, item) => totalPaid + item.amount, 0);
    this.setState({
      invoicetotalpayment:totalPaid
    })

  })


  axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
  .then(res => {
     console.log("data: "+res.data[0] )
      this.setState({
        userrights:res.data[0]
      })
  })




}



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



  render() {
 
    return(
        <div>

            <reactstrp.Table bordered>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Payment mode</th>
                    <th>Receipt No</th>
                    <th>Print</th>
                    {
                      this.state.userrights.update_invoices===true?
                      <th>Delete</th>
                      :
                      null
                    }
                  </tr>
                </thead>
                <tbody>
                  {this.state.invoice_payments.map((item) => (
                    <tr>
                      <td>{item.date}</td>
                      <td>{item.amount}</td>
                      <td>{item.payment_account}</td>
                      <td>{item.journal_no}</td>

                      <td>
                      <Popover content={<p>Click to print</p>} title="Print">               
                      <PrinterFilled onClick={()=>{
                        this.setState({selected_payment:item})
                        this.setState({showprintermodal:true})
                    
                      }} style={{color:'green',fontSize: '30px',margin:2}} />    
                      </Popover> 
                      </td>

                      {
                      this.state.userrights.update_invoices===true?
                      <th>
                      <Popover content={<p>Click here to delete </p>} 
                      title="Delete">
                        <Popconfirm title="Sure to delete?" onConfirm={
                          () => {
                          let form_data = new FormData();
                          form_data.append('payment_id', item.id);
                      
                          axios.post(serverconfig.backendserverurl+'/customqueries/delete_invoice_payment', form_data, {
                            headers: {
                              'content-type': 'multipart/form-data'
                            }
                          })
                          .then(res =>{
                            message.info(res.data.message)
                            this.compo_loaded()
                    
                          })
                        .catch(error => console.log(error))
                      
                        }
                      }>
                        <DeleteOutlined style={{color:'red'}}/>
                      </Popconfirm>
                      </Popover>

                      </th>
                      :
                      null
                      }
                    </tr>
                  ))}
                </tbody>
              </reactstrp.Table>


             <div style={{display: "none"}}  >
            &nbsp;&nbsp;
            <ReceiptToPrint
            receipttotal={this.state.receipttotal} 
            date={this.state.date}
            selected_payment={this.state.selected_payment}
            invoiceobj={this.state.invoiceobj}
            clientdetails={this.state.clientdetails}
            invoicetotalpayment={this.state.invoicetotalpayment}

            ref={el => (this.componentRef = el)} /> 
             </div>


            <Modal
                
                visible={this.state.showprintermodal}
                title="Printer Request"
                width={200}
                onCancel={(val)=>{this.setState({showprintermodal:false})}}  
                footer={[
                  <Button key="back" onClick={(val)=>{this.setState({showprintermodal:false})}}>
                    Cancel
                  </Button>,
                  <ReactToPrint
                    trigger={() =>  
                    <Button onClick={(val)=>{this.setState({showprintermodal:false})}} type="primary">Yes</Button>        
                  }
                  content={() => this.componentRef}
                  onAfterPrint={(val)=>{this.setState({showprintermodal:false})}}

                  />
                  ]}
                  >
                  <h4>Print?</h4>
                    
              </Modal>

        </div>
    )
  }
}

export default invoice_paymentsList; 
