import React from 'react';
import { Route } from 'react-router-dom';
import QuickMenu from './views/containers/QuickMenu'
import Dashboard from './views/containers/DashBoard'
import DataUploadForm from './views/components/DataUploadForm'
import AccountDetail from './views/containers/AccountDetailView'
import DataUploadForm_Json from './views/components/DataUploadForm_Json'

const SaccoBaseRouter = () =>(
     <div>
        <Route exact path='/' component={Dashboard}  />
        <Route exact path='/datauploads/' component={DataUploadForm}  />
        <Route exact path='/useraccounts/:accountID' component={AccountDetail} />
        <Route exact path='/datauploads_json/' component={DataUploadForm_Json}  />
    </div>
);

export default SaccoBaseRouter;


