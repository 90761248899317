import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,Form,message,Spin,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import RestaurantMultiBranchForm from '../components/RestaurantMultiBranchForm';
 
import RestaurantMultiBranchDetails from './RestaurantMultiBranchDetails';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Search } = Input;
const FormItem=Form.Item;


function callback(key) {
  console.log(key);
}
const { TabPane } = Tabs;

var token= ''

class RestaurantsMultibranchList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    restaurant_multibranch: [],
    restaurants_placeholders: [],

    datarequested:true,
    restaurant_dishes:[]
  };


  componentDidMount(){
    
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/restaurant_dishes/`)
    .then(res => {
        this.setState({
          restaurant_dishes:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/restaurant_multibranch/`)
    .then(res => {
        this.setState({
            restaurant_multibranch:res.data
        })

        this.setState({
          restaurants_placeholders:res.data
      })
        this.setState({datarequested:false})

    })
}

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};




  render() {
    const columns = [
    
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'id',
        render: (text,record) =><div>
          <p style={{fontWeight:'bolder'}}>{record.name}</p>
          <p>{record.about}</p>
        </div>,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'id',
        ...this.getColumnSearchProps('email'),
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'id',
        ...this.getColumnSearchProps('phone'),
      },

      {
        title: 'Online Status',
        dataIndex: 'online_order_link',
        key: 'id',
        render: (text,record) =>
        <div>
           <p color='green'>
          Menu Available Online: {record.active?"Yes":"No"}
        </p>
        <p>
          Online Link: {record.online_order_link}
        </p>

        </div>
      
      },

      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>

        <Popover content={<p>Click here to delete </p>} title="Delete">
         <Popconfirm title="Sure to delete?" onConfirm={
          () => {
            axios.delete(`${serverconfig.backendserverurl}/api/restaurant_multibranch/${text}`).then(res =>{
              window.location.reload(false)
              message.info("successfully deleted") 
            }
            );
          }
        }>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>      
        </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="MULTIBRANCH RESTAURANTS" key="1">

            <FormItem label="Search Restaurant">
            <Search
                placeholder="Restaurant"
                onChange={(value) => {
                  var val=value.target.value
                  console.log(val)
                  if (val==='' || val===undefined || val===null){
                    this.setState({restaurant_multibranch:this.state.restaurants_placeholders});

                  }


                  if (val !== '' || val !== undefined) {
                    //set one is by NAME
                    const results_1 = this.state.restaurant_multibranch.filter((request) => {
                      return String(request.name).toLowerCase().startsWith(val.toLowerCase());
                      // Use the toLowerCase() method to make it case-insensitive
                    });
              
                    if (results_1.length === 0) {
                      this.setState({restaurant_multibranch:this.state.restaurants_placeholders});

                    } else {
                      this.setState({restaurant_multibranch:results_1});
                      console.log(results_1)
                    }
              
                  } else {
                    this.setState({restaurant_multibranch:this.state.restaurants_placeholders});
                  }
              
                }}
                style={{
                  width: 200,
                  margin:5
                }}
               /* onClick={()=>{
                  this.setState({restaurant_multibranch:this.state.restaurants_placeholders})

                }}*/
                
                />
            </FormItem>

            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.restaurant_multibranch}
            bordered
            
            expandable={{
              expandedRowRender: (record) =>{
                  return (
                    <Tabs defaultActiveKey="1" >
                    <TabPane tab="Restaurant Details" key="1" >
                      <RestaurantMultiBranchDetails restaurantID={record.id}/>
                    </TabPane>
                    </Tabs>
                   );
              } ,
              rowExpandable: (record) => true,
              onExpand:(condition,record)=>{
              },
            }}
        
            />

             </Panel>
             </Collapse>
            <br />
            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Create New Multi Branch Restaurant" key="1">
              <RestaurantMultiBranchForm /> 
            </Panel>
            </Collapse>
        </div>
    )

    }

   
  }
}

export default RestaurantsMultibranchList; 
