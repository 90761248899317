import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Input,message,Spin} from 'antd';
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';
const FormItem=Form.Item;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class UgDistrictDetail extends React.Component{

    state={
        district: {},
        district_name:'' ,  
        region:'',
        datarequested:true,
    }

    componentDidMount(){
      this.comp_reloaded()
      
    }

    comp_reloaded=()=>{
      this.setState({datarequested:true})


      if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
      }else{
        token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      const districtID=this.props.districtID;
      axios.get(`${serverconfig.backendserverurl}/api/ugdistricts/${districtID}`)
      .then(res => {  
          this.setState({
            district: res.data

          })

          this.setState({district_name:res.data.district_name})
          this.setState({region:res.data.region})
          this.setState({datarequested:false})
      })


    }

  
    //submit button pressed
  handleFormSubmit=() =>{
    this.setState({datarequested:true})

    const districtID=this.props.districtID;

    let form_data = new FormData();
    form_data.append('district_name', this.state.district_name);
    form_data.append('region', this.state.region);

    axios.put(`${serverconfig.backendserverurl}/api/ugdistricts/${districtID}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
    })
    .then(res => {
      this.setState({datarequested:false})
      message.info('Successfully updated ')

      //refresh list
      this.props.onRefresh()
    }) 
    .catch(error => console.log(error))
  }


    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>                 </div>
            )
      
          }else{
            return(
                <div>
                    <Row>
                    <Col xs="12" sm="6" lg="4">
                    <Card title={this.state.district.district_name}>
                        <p>Region: {this.state.district.region}</p>
                    </Card>
                    </Col>
                    <Col span={2}>
                 
                    </Col>
                    <Col xs="12" sm="6" lg="4">
                   
                    </Col>
    
                    </Row>
                    <br/>
    
                    <Row>
                    <Col xs="12" sm="6" lg="4">
                    <Card 
                    style={{padding:10}}
                    hoverable>
                        <h4>District Update</h4>
                        <Form>
                        <FormItem label="District Name"
                        >
                          <Input name="district_name"  placeholder="Put a name here." value={this.state.district_name} onChange={(val)=>{this.setState({district_name:val.target.value})}} />
                        </FormItem>

                        <FormItem label="Region" 
                        >
                          <Input name="region"  placeholder="Region" value={this.state.region} onChange={(val)=>{this.setState({region:val.target.value})}} />
                        </FormItem>
                        
                            <FormItem>
                            <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update</Button>
                            </FormItem>
                        </Form>
    
                    </Card>
    
                    </Col>
    
                    </Row>
    
                    <br/>
                </div>
            )
        }
    }
}

export default UgDistrictDetail; 