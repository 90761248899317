import React from 'react';
import RestaurantForm from '../components/RestaurantForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,Form,message,Spin,Tabs,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,PlusCircleOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import RestaurantDetails from './RestaurantDetails'
import RestaurantMenuCategoryList from './RestaurantMenuCategoryListView'
import PromosList from './PromosListView'
import MyContext from './../../MyContext';
import * as primarycolor from '../primarycolor'
var CryptoJS = require("crypto-js");


const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Search } = Input;
const FormItem=Form.Item;


function callback(key) {
  console.log(key);
}
const { TabPane } = Tabs;
var token= ''
var bizuserid = "";


class RestaurantList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    restaurants: [],
    restaurants_placeholders: [],

    datarequested:true,
    restaurant_dishes:[],
    userrights:{},

  };


  componentDidMount(){
    this.component_loaded()
   
}


component_loaded=()=>{
  this.setState({datarequested:true})

  if(localStorage.getItem("token")){
    token= localStorage.getItem("token")
  }else{
    token= ''
  }


 if (localStorage.getItem("bizuserid")) {
  bizuserid = CryptoJS.AES.decrypt(
    localStorage.getItem("bizuserid"),
    "my-secret-key@1234"
  ).toString(CryptoJS.enc.Utf8);
} else {
  bizuserid = "";
}


 axios.defaults.headers={
   "Content-Type":"application/json",
   Authorization:`Token ${token}`
 }


 axios.get(serverconfig.backendserverurl+`/api/restaurant_dishes/`)
 .then(res => {
     this.setState({
       restaurant_dishes:res.data
     })
 })


 axios.get(serverconfig.backendserverurl+`/api/restaurants/`)
 .then(res => {

     this.setState({
       restaurants:res.data
     })

     this.setState({
       restaurants_placeholders:res.data
   })
    // this.setState({datarequested:false})

 })


 axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
 .then(res => {
     console.log("data: "+res.data[0] )
     this.setState({
       userrights:res.data[0]
     })


     this.setState({datarequested:false})

 })



}



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



//get restauramt menus and dihes
calculate_dish = (res_id) => {
  // Filter the list based on the searchRestaurant
  const filteredRestaurants = this.state.restaurant_dishes.filter(restaurant => Number(restaurant.restaurant) === Number(res_id));
  // Count the number of items in the filtered list
  const itemCount = filteredRestaurants.length;
  return itemCount;
};




  render() {
    const columns = [
    {
      title: 'ID',
      dataIndex: 'system_ID',
      key: 'id',
      ...this.getColumnSearchProps('system_ID'),
    },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'id',
        render: (text,record) =><div>
          <p style={{fontWeight:'bolder'}}>{record.name}</p>
          <p>{record.about}</p>
          <p>Dishes: {this.calculate_dish(record.id)}</p>
        </div>,
      },
      {
        title: 'Date',
        dataIndex: 'date_ofcreation',
        key: 'id',
        ...this.getColumnSearchProps('date_ofcreation'),
      },
      
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'id',
        ...this.getColumnSearchProps('category'),
      },

      {
        title: 'Hub',
        dataIndex: 'hub_name',
        key: 'id',
        ...this.getColumnSearchProps('hub_name'),
      },

      {
        title: 'Area',
        dataIndex: 'area_name',
        key: 'id',
        ...this.getColumnSearchProps('area_name'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'id',
        ...this.getColumnSearchProps('email'),
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'id',
        ...this.getColumnSearchProps('phone'),
      },

      {
        title: 'Phone Orders Active',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <div style={{display:'flex',flexDirection:'column'}}>
                        {
                    this.state.userrights.update_restaurants===true?

        <Switch
          checked={record.phoneorders_on}
          onChange={val=>{

            let form_data = new FormData();
            form_data.append('phoneorders_on',val);
            form_data.append('active',record.active);

            axios.put(serverconfig.backendserverurl+`/api/restaurants/${record.id}/`, form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
          .then(res =>  {
            this.setState({datarequested:false})
            this.component_loaded()
            message.info("Updated")
        
          }   
        
          )
          .catch(error => console.log(error))
        
          }}
          checkedChildren="Yes"
          unCheckedChildren="No"
          />
          :null}

        </div>
      
      },


      {
        title: 'Menu Online',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
          <span>
      {
            this.state.userrights.update_restaurants===true?

        <Switch
          checked={record.active}
          onChange={val=>{

            let form_data = new FormData();
            form_data.append('active',val);
            form_data.append('phoneorders_on',record.phoneorders_on);
            form_data.append('temporarily_closed_online',record.temporarily_closed_online);

            axios.put(serverconfig.backendserverurl+`/api/restaurants/${record.id}/`, form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
          .then(res =>  {
            this.setState({datarequested:false})
            this.component_loaded()
            message.info("Updated")
        
          }   
        
          )
          .catch(error => console.log(error))
        
          }}
          checkedChildren="Yes"
          unCheckedChildren="No"
          />
          : <span></span>}

          </span>
         

      },
      {
        title: 'Temporarily closed online',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>

          <span>
   {
            this.state.userrights.update_restaurants===true?

        <Switch
          checked={record.temporarily_closed_online}
          onChange={val=>{

            let form_data = new FormData();
            form_data.append('active',record.active);
            form_data.append('phoneorders_on',record.phoneorders_on);
            form_data.append('temporarily_closed_online',val);

            axios.put(serverconfig.backendserverurl+`/api/restaurants/${record.id}/`, form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
          .then(res =>  {
            this.setState({datarequested:false})
            this.component_loaded()
            message.info("Updated")
        
          }   
        
          )
          .catch(error => console.log(error))
        
          }}
          checkedChildren="Yes"
          unCheckedChildren="No"
          />
          :
          <span></span>}
          </span>
       

      },


      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>

      {
       this.state.userrights.delete_restaurants===true?
        <Popover content={<p>Click here to delete </p>} title="Delete">
         <Popconfirm title="Sure to delete?" onConfirm={
          () => {
            axios.delete(`${serverconfig.backendserverurl}/api/restaurants/${text}`).then(res =>{
             this.component_loaded()
              message.info("successfully deleted") 
            }
            );
          }
        }>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        :
        null}


        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>      
        </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="RESTAURANTS" key="1">

            <FormItem label="Search Restaurant">
            <Search
                placeholder="Restaurant Name"
                onChange={(value) => {
                  var val=value.target.value
                  console.log(val)
                  if (val==='' || val===undefined || val===null){
                    this.setState({restaurants:this.state.restaurants_placeholders});
                  }

                  if (val !== '' || val !== undefined) {
                    //set one is by NAME
                    const results_1 = this.state.restaurants.filter((request) => {
                      return String(request.name).toLowerCase().includes(val.toLowerCase());
                      // Use the toLowerCase() method to make it case-insensitive
                    });
              
                    if (results_1.length === 0) {
                      this.setState({restaurants:this.state.restaurants_placeholders});

                    } else {
                      this.setState({restaurants:results_1});
                      console.log(results_1)
                    }
              
                  } else {
                    this.setState({restaurants:this.state.restaurants_placeholders});
                  }
              
                }}
                style={{
                  width: 200,
                  margin:5
                }}
               /* onClick={()=>{
                  this.setState({restaurants:this.state.restaurants_placeholders})

                }}*/
                
                />
            </FormItem>

            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.restaurants}
            bordered
            
            expandable={{
              expandedRowRender: (record) =>{
                  return (
                    <Tabs defaultActiveKey="2" >
                  
                  {
                    this.state.userrights.update_restaurants===true?

                    <TabPane tab="Restaurant Menu Categories" key="2" >
                      <RestaurantMenuCategoryList restaurant_obj={record} restaurantID={record.id}/>
                    </TabPane>
                    :
                    null}
                  
                  {
                    this.state.userrights.update_restaurants===true?
                    <TabPane tab="Restaurant Details" key="1" >
                      <RestaurantDetails restaurantID={record.id}  onrefresh={this.component_loaded} />
                    </TabPane>
                  :
                  null}
                    
                  
                    {
                    this.state.userrights.update_restaurants===true?
                    <TabPane tab="Promos" key="3" >
                      <PromosList restaurantID={record.id}/>
                    </TabPane>
                      :
                      null}
                    
                    </Tabs>
                   );
              } ,
              rowExpandable: (record) => true,
              onExpand:(condition,record)=>{
              },
              expandIcon: (props) => {
                const { expanded, onExpand } = props;

                 return (<span style={{ color: primarycolor.primarycolor, fontSize: '30px' }}
                 onClick={(e) => {
                  onExpand(props.record, e);
                }}
                 
                 ><PlusCircleOutlined /></span>)
                
              }


            }}
            size='small'
            />

             </Panel>
             </Collapse>
            <br />


            {
              this.state.userrights.create_restaurants===true?
              <Collapse defaultActiveKey={['0']} onChange={callback}>
              <Panel header="Create New Restaurant" key="1">
                <RestaurantForm onrefresh={this.component_loaded} /> 
              </Panel>
              </Collapse>
              :
              null
            }


        </div>
    )

    }
   
  }
}

RestaurantList.contextType = MyContext;

export default RestaurantList; 
