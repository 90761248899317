import React from 'react';
import PromoForm from '../components/PromoForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Switch,Popconfirm,message,Spin,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import PromoDetails from './PromoDetails'

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}
const { TabPane } = Tabs;

var token= ''

class PromosList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    promos: [],
    datarequested:true,
  };


  componentDidMount(){
    this.compo_reloaded()
}

compo_reloaded=()=>{
  this.setState({datarequested:true})

  var restaurantID=this.props.restaurantID
  
  if(localStorage.getItem("token")){
     token= localStorage.getItem("token")
  }else{
     token= ''
  }

  axios.defaults.headers={
    "Content-Type":"application/json",
    Authorization:`Token ${token}`
  }

  axios.get(serverconfig.backendserverurl+`/api/promos/?restaurant=${restaurantID}`)
  .then(res => {
      this.setState({
          promos:res.data
      })
      this.setState({datarequested:false})

  })



}


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



  render() {
    const columns = [
      {
        title: 'Head',
        dataIndex: 'name',
        key: 'id',
        render: (text,record) =><div>
          <p style={{fontWeight:'bolder'}}>{record.head}</p>
          <p>{record.description}</p>
        </div>
      },

      {
        title: 'Online',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <div style={{display:'flex',flexDirection:'column'}}>
    
        <Switch
          checked={record.active}
          onChange={val=>{

            let form_data = new FormData();
            form_data.append('active',val);

            axios.put(serverconfig.backendserverurl+`/api/promos/${record.id}/`, form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
          .then(res =>  {
            this.setState({datarequested:false})
            this.compo_reloaded()
            message.info("Updated")
          })
          .catch(error => console.log(error))
        
          }}
          checkedChildren="Yes"
          unCheckedChildren="No"
          />

        </div>
      
      }
       ,
       {
        title: 'Date Of Creation',
        dataIndex: 'date_created',
        key: 'id',
      },
       {
        title: 'Date Of Expiry',
        dataIndex: 'date_of_expiry',
        key: 'id',
      },

      {
        title: 'Created By',
        dataIndex: 'user',
        key: 'id',
      },

      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>

        <Popover content={<p>Click here to delete </p>} title="Delete">
         <Popconfirm title="Sure to delete?" onConfirm={
          () => {
            axios.delete(`${serverconfig.backendserverurl}/api/promos/${text}`).then(res =>{
              window.location.reload(false)
              message.info("successfully deleted") 
            }
            );
          }
        }>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>      
         </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="PROMOS" key="1">
            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.promos}
            bordered
            
            expandable={{
              expandedRowRender: (record) =>{
                  return (
                    <Tabs defaultActiveKey="1" >
                    <TabPane tab="Promo Details" key="1" >
                      <PromoDetails promoID={record.id}  onRefresh={this.compo_reloaded}/>

                    </TabPane>
                    </Tabs>
                   );
              } ,
              rowExpandable: (record) => true,
              onExpand:(condition,record)=>{
              },
            }}
            />
             </Panel>
             </Collapse>
            <br />

            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Create New Promo" key="1">
              <PromoForm restaurantID={this.props.restaurantID} onRefresh={this.compo_reloaded}  /> 
            
            </Panel>
            </Collapse>

        </div>
    )

    }

   
  }
}

export default PromosList; 
