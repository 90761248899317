import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Popconfirm,Popover,
  Collapse,Avatar,Drawer,Descriptions,message,Modal,Spin,Switch,Tooltip,Card,Tabs,Divider} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,FundOutlined,LogoutOutlined,FundViewOutlined,DeleteOutlined,LockFilled,LockOutlined,LoadingOutlined,MessageFilled } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { Form,Select,Checkbox,Upload } from 'antd';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import UserCreationForm from '../components/UserCreationForm'
import {
  Col,
  Row,
} from 'reactstrap';
const { TabPane } = Tabs;


var CryptoJS = require("crypto-js");
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const { Panel } = Collapse;
const FormItem=Form.Item;

function callback(key) {
  console.log(key);
}

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});



var sacco= ''
var username=''
var token= ''

class AccountList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    dashboard_users: [],
    portal_users:[],

    rightsmodalvisible:false,
    rights: {},
    selectedid:'',
    datarequested:true,

    view_orders:false,

    update_orders:false,
    
    create_restaurants:false,
    view_restaurants:false,
    update_restaurants:false,
    delete_restaurants:false,
    
    create_areas:false,
    view_areas:false,
    update_areas:false,
    delete_areas:false,
    
    create_riders:false,
    view_riders:false,
    update_riders:false,
    delete_riders:false,
    
    view_clients:false,
    
    create_invoices:false,
    view_invoices:false,
    update_invoices:false,
    delete_invoices:false,

    create_otherpayments:false,
    view_otherpayments:false,
    update_otherpayments:false,
    delete_otherpayments:false,
    
    view_financialreports:false,
    update_journals:false,
    delete_journals:false,
    
    view_statistics:false,
    view_sentemails:false
    

  };


  componentDidMount(){
    this.comp_onload()
}


  comp_onload=()=>{
    this.setState({datarequested:true})

    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
    
    axios.get(serverconfig.backendserverurl+`/api/accounts/`)
    .then(res => {
      console.log(res.data)

        this.setState({
          dashboard_users:res.data
        })

        this.setState({datarequested:false})
    })


  }




  displaypasswordupdatemodal=(id)=>{
    this.setState({showpasswordupdatemodal:true})
    this.setState({selectedid:id})
  }
  
  closepasswordupdatemodal=()=>{
    this.setState({showpasswordupdatemodal:false})
  }



  validatePassword = (password1) => {
    // Check if the password meets the requirements
    const hasUpperCase = /[A-Z]/.test(password1);
    const hasLowerCase = /[a-z]/.test(password1);
    const hasNonAlphabetic = /[^A-Za-z]/.test(password1);
    const isLengthValid = password1.length >= 8;

    // Display error message if any requirement is not met
    if (!(hasUpperCase && hasLowerCase && hasNonAlphabetic && isLengthValid)) {
    
      return false
    }else{
      return true
    }

   
  };




  
  //////////////////////////////////////
  ///// change password here
  handlePasswordChange=(values)=>{

    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('password1', values.new_password1);
    form_data.append('password2', values.new_password2);
    form_data.append('userid', this.state.selectedid);
  
    //Now send the post resquest
      axios.post(serverconfig.backendserverurl+'/customqueries/updatecompanyuserpassword', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
      this.setState({datarequested:false})

      message.info(res.data.message);
    }
    )
    .catch(error => console.log(error)) 

  }



  ///////////////////////////////////////////
  //getting simple user account rights
  showAccountRights=(text) =>{
    this.setState({ rightsmodalvisible: true });

    //make api call to get the user rights
    axios.get(`${serverconfig.backendserverurl}/api/accountrights?user=${text}`)
    .then(res => {  
        this.setState({
            rights: res.data[0]
        })
        this.setState({view_orders:res.data[0].view_orders})

        this.setState({update_orders:res.data[0].update_orders})
        this.setState({create_restaurants:res.data[0].create_restaurants})
        this.setState({view_restaurants:res.data[0].view_restaurants})
        this.setState({update_restaurants:res.data[0].update_restaurants})
        this.setState({delete_restaurants:res.data[0].delete_restaurants})
        this.setState({create_areas:res.data[0].create_areas})
        this.setState({view_areas:res.data[0].view_areas})
        this.setState({update_areas:res.data[0].update_areas})
        this.setState({delete_areas:res.data[0].delete_areas})
        this.setState({create_riders:res.data[0].create_riders})
        this.setState({view_riders:res.data[0].view_riders})
        this.setState({update_riders:res.data[0].update_riders})
        this.setState({delete_riders:res.data[0].delete_riders})

        this.setState({view_clients:res.data[0].view_clients})

        this.setState({create_invoices:res.data[0].create_invoices})
        this.setState({view_invoices:res.data[0].view_invoices})
        this.setState({update_invoices:res.data[0].update_invoices})
        this.setState({delete_invoices:res.data[0].delete_invoices})

        this.setState({create_otherpayments:res.data[0].create_otherpayments})
        this.setState({view_otherpayments:res.data[0].view_otherpayments})
        this.setState({update_otherpayments:res.data[0].update_otherpayments})
        this.setState({delete_otherpayments:res.data[0].delete_otherpayments})


        this.setState({view_financialreports:res.data[0].view_financialreports})
        this.setState({update_journals:res.data[0].update_journals})
        this.setState({delete_journals:res.data[0].delete_journals})
        this.setState({view_statistics:res.data[0].view_statistics})
        this.setState({view_sentemails:res.data[0].view_sentemails})


      })

  }




  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ rightsmodalvisible: false });
  };


  //make an update now on the user rights
  updaterights=values=>{



    let form_data = new FormData();
    form_data.append('view_orders', this.state.view_orders)

    form_data.append('update_orders',this.state.update_orders)
    form_data.append('create_restaurants',this.state.create_restaurants)
    form_data.append('view_restaurants',this.state.view_restaurants)
    form_data.append('update_restaurants',this.state.update_restaurants)
    form_data.append('delete_restaurants',this.state.delete_restaurants)
    form_data.append('create_areas',this.state.create_areas)
    form_data.append('view_areas',this.state.view_areas)
    form_data.append('update_areas',this.state.update_areas)
    form_data.append('delete_areas',this.state.delete_areas)
    form_data.append('create_riders',this.state.create_riders)
    form_data.append('view_riders',this.state.view_riders)
    form_data.append('update_riders',this.state.update_riders)
    form_data.append('delete_riders',this.state.delete_riders)

    form_data.append('view_clients',this.state.view_clients)

    form_data.append('create_invoices',this.state.create_invoices)
    form_data.append('view_invoices',this.state.view_invoices)
    form_data.append('update_invoices',this.state.update_invoices)
    form_data.append('delete_invoices',this.state.delete_invoices)

    form_data.append('create_otherpayments',this.state.create_otherpayments)
    form_data.append('view_otherpayments',this.state.view_otherpayments)
    form_data.append('update_otherpayments',this.state.update_otherpayments)
    form_data.append('delete_otherpayments',this.state.delete_otherpayments)


    form_data.append('view_financialreports',this.state.view_financialreports)
    form_data.append('update_journals',this.state.update_journals)
    form_data.append('delete_journals',this.state.delete_journals)
    form_data.append('view_statistics',this.state.view_statistics)
    form_data.append('view_sentemails',this.state.view_sentemails)



    //Now send the post resquest
    axios.put(`${serverconfig.backendserverurl}/api/accountrights/${this.state.rights.id}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res =>{
      this.comp_onload()

      message.info("User Rights Updated")
      this.setState({rightsmodalvisible:false})


    })
    .catch(error => console.log(error))

  }




  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    
    const columns = [
      {
        title: 'Account Type',
        dataIndex: 'account_type',
        key: 'id',
        ...this.getColumnSearchProps('account_type'),
      },
    
      {
        title: 'Profile',
        dataIndex: 'profile_pic',
        key: 'profile_pic',
        render: text => <Avatar
        size={60}
        icon={<UserOutlined />}
        alt="User Pic"
        src={text}
        />,
      },
      {
        title: 'User Name',
        dataIndex: 'username',
        key: 'id',
        ...this.getColumnSearchProps('username'),
      },
    
      {
        title: 'Phone',
        dataIndex: 'phone_contact',
        key: 'id',
        ...this.getColumnSearchProps('phone_contact'),
      },
      {
        title: 'User Active',
        dataIndex: 'is_active',
        key: 'id',
        render: (text,record) =>
        <p>
          {
            record.is_active?
            "Yes"
            :
            "No"
          }
        </p>
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'id',
      },
      {
        title: 'Restaurant',
        dataIndex: 'restaurant_id_name',
        key: 'id',
      },
      {
        title: 'Rider',
        dataIndex: 'rider_id_name',
        key: 'id',
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/useraccounts/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>

        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to edit,view user rights</p>} title="Edit,View rights">
         <UserOutlined onClick={(event) => this.showAccountRights(text)} style={{color:'blue'}}/>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click to change password</p>} title="Password Change">
          <LockFilled style={{color:'#154360'}} onClick={() => this.displaypasswordupdatemodal(text)}/>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Tooltip title="Send Password Reset Email " placement="top">
        <Popconfirm title="Sure to reset password?" onConfirm={
          () => {
            this.setState({datarequested:true})

            let form_data = new FormData();
            form_data.append('user_id', record.id)
            //Now send the post resquest
            axios.post(`${serverconfig.backendserverurl}/customqueries/sendPasswordRestEmail`, form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
              })
            .then(res =>{
              this.setState({datarequested:false})

              message.info(res.data.message)
              this.comp_onload()

            })
            .catch(error => console.log(error))

          }
        }>
          <MessageFilled style={{color:'#154360'}} />
         </Popconfirm>
        </Tooltip>


        &nbsp;&nbsp;&nbsp;
        <Tooltip title="Logout User " placement="top">
        <Popconfirm title="Sure to Logout User?" onConfirm={
          () => {
            this.setState({datarequested:true})

            let form_data = new FormData();
            form_data.append('userid', record.id)
            //Now send the post resquest
            axios.post(`${serverconfig.backendserverurl}/customqueries/systemlogout`, form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
              })
            .then(res =>{
              this.setState({datarequested:false})

              message.info(res.data.message)
              this.comp_onload()

            })
            .catch(error => console.log(error))

          }
        }>
          <LogoutOutlined style={{color:'#154360'}} />
         </Popconfirm>
        </Tooltip>



        </p>,
      },
      
    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      return(
        <div>
        
          <Table 
            scroll={{ x: 1000 }}
            columns={columns}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.dashboard_users} 
            bordered
            size='small'
            />
            <br></br>

          <Card>
          <UserCreationForm />
          </Card>
        

            <Drawer
                title={this.state.rights.username+" "+"Account Rights"}
                width={520}
                placement="right"
                closable={false}
                onClose={this.onresetdrawerClose}
                visible={this.state.rightsmodalvisible}
              >
                    <h3 style={{color:'red'}}>Edit User Rights</h3>

                    <Form  
                      onFinish={this.updaterights}        
                      >
                      <FormItem name="view_orders" label="View Orders" >
                          <Switch
                              checked={this.state.view_orders}
                              onChange={(val)=>{this.setState({view_orders:!this.state.view_orders})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <FormItem name="update_orders" label="Update Orders" >
                          <Switch
                              checked={this.state.update_orders}
                              onChange={(val)=>{this.setState({update_orders:!this.state.update_orders})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem> 

                        <Divider></Divider>
 

                      <FormItem name="create_restaurants" label="Create Restaurants" >
                          <Switch
                              checked={this.state.create_restaurants}
                              onChange={(val)=>{this.setState({create_restaurants:!this.state.create_restaurants})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <FormItem name="view_restaurants" label="View Restaurants" >
                          <Switch
                              checked={this.state.view_restaurants}
                              onChange={(val)=>{this.setState({view_restaurants:!this.state.view_restaurants})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <FormItem name="update_restaurants" label="Update Restaurants" >
                          <Switch
                              checked={this.state.update_restaurants}
                              onChange={(val)=>{this.setState({update_restaurants:!this.state.update_restaurants})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <FormItem name="delete_restaurants" label="Delete Restaurants" >
                          <Switch
                              checked={this.state.delete_restaurants}
                              onChange={(val)=>{this.setState({delete_restaurants:!this.state.delete_restaurants})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <Divider></Divider>



                      <FormItem name="create_areas" label="Create Areas" >
                          <Switch
                              checked={this.state.create_areas}
                              onChange={(val)=>{this.setState({create_areas:!this.state.create_areas})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <FormItem name="view_areas" label="View Areas" >
                          <Switch
                              checked={this.state.view_areas}
                              onChange={(val)=>{this.setState({view_areas:!this.state.view_areas})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <FormItem name="update_areas" label="Update Areas" >
                          <Switch
                              checked={this.state.update_areas}
                              onChange={(val)=>{this.setState({update_areas:!this.state.update_areas})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  
                      
                      <FormItem name="delete_areas" label="Delete Areas" >
                          <Switch
                              checked={this.state.delete_areas}
                              onChange={(val)=>{this.setState({delete_areas:!this.state.delete_areas})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <Divider></Divider>


                      <FormItem name="create_riders" label="Create Riders" >
                          <Switch
                              checked={this.state.create_riders}
                              onChange={(val)=>{this.setState({create_riders:!this.state.create_riders})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <FormItem name="view_riders" label="View Riders" >
                          <Switch
                              checked={this.state.view_riders}
                              onChange={(val)=>{this.setState({view_riders:!this.state.view_riders})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <FormItem name="update_riders" label="Update Riders" >
                          <Switch
                              checked={this.state.update_riders}
                              onChange={(val)=>{this.setState({update_riders:!this.state.update_riders})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  
                      
                      <FormItem name="delete_riders" label="Delete Riders" >
                          <Switch
                              checked={this.state.delete_riders}
                              onChange={(val)=>{this.setState({delete_riders:!this.state.delete_riders})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem> 
                      <Divider></Divider>
 


                      <FormItem name="view_clients" label="View Clients" >
                          <Switch
                              checked={this.state.view_clients}
                              onChange={(val)=>{this.setState({view_clients:!this.state.view_clients})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <Divider></Divider>



                      <FormItem name="create_invoices" label="Create Invoices" >
                          <Switch
                              checked={this.state.create_invoices}
                              onChange={(val)=>{this.setState({create_invoices:!this.state.create_invoices})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  
                      
                      <FormItem name="view_invoices" label="View Invoices" >
                          <Switch
                              checked={this.state.view_invoices}
                              onChange={(val)=>{this.setState({view_invoices:!this.state.view_invoices})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <FormItem name="update_invoices" label="Update Invoices" >
                          <Switch
                              checked={this.state.update_invoices}
                              onChange={(val)=>{this.setState({update_invoices:!this.state.update_invoices})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  



                      <FormItem name="delete_invoices" label="Delete Invoices" >
                          <Switch
                              checked={this.state.delete_invoices}
                              onChange={(val)=>{this.setState({delete_invoices:!this.state.delete_invoices})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  


                      <Divider></Divider>



                      <FormItem name="create_otherpayments" label="Create Other Payments" >
                          <Switch
                              checked={this.state.create_otherpayments}
                              onChange={(val)=>{this.setState({create_otherpayments:!this.state.create_otherpayments})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <FormItem name="view_otherpayments" label="View Other Payments" >
                          <Switch
                              checked={this.state.view_otherpayments}
                              onChange={(val)=>{this.setState({view_otherpayments:!this.state.view_otherpayments})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <FormItem name="update_otherpayments" label="Update Other Payments" >
                          <Switch
                              checked={this.state.update_otherpayments}
                              onChange={(val)=>{this.setState({update_otherpayments:!this.state.update_otherpayments})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <FormItem name="delete_otherpayments" label="Delete Other Payments" >
                          <Switch
                              checked={this.state.delete_otherpayments}
                              onChange={(val)=>{this.setState({delete_otherpayments:!this.state.delete_otherpayments})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <Divider></Divider>



                      <FormItem name="view_financialreports" label="View Financial Reports" >
                          <Switch
                              checked={this.state.view_financialreports}
                              onChange={(val)=>{this.setState({view_financialreports:!this.state.view_financialreports})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  


                      <FormItem name="update_journals" label="Update Journals" >
                          <Switch
                              checked={this.state.update_journals}
                              onChange={(val)=>{this.setState({update_journals:!this.state.update_journals})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <FormItem name="delete_journals" label="Delete Journals" >
                          <Switch
                              checked={this.state.delete_journals}
                              onChange={(val)=>{this.setState({delete_journals:!this.state.delete_journals})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>  

                      <Divider></Divider>

                      
                      <FormItem name="view_statistics" label="View Statistics" >
                          <Switch
                              checked={this.state.view_statistics}
                              onChange={(val)=>{this.setState({view_statistics:!this.state.view_statistics})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem> 


                      <Divider></Divider>

                      
                    <FormItem name="view_sentemails" label="View Sent Emails" >
                        <Switch
                            checked={this.state.view_sentemails}
                            onChange={(val)=>{this.setState({view_sentemails:!this.state.view_sentemails})}}
                            checkedChildren="YES"
                            unCheckedChildren="NO"
                            />
                    </FormItem> 


                      <FormItem>
                        <Button htmlType="submit" type="primary">Update</Button>
                      </FormItem>
                      
                    </Form>
              </Drawer>


              <Modal              
                visible={this.state.showpasswordupdatemodal}
                title="Password Change Form"
                width={600}
                onCancel={this.closepasswordupdatemodal}

                footer={[
                  <Button key="back" onClick={this.closepasswordupdatemodal}>
                    Cancel
                  </Button>
                  ]}
                  >
              <Form
              onFinish={this.handlePasswordChange}        
              name="passwordreset"
              className="login-form">
        
              <Form.Item
                  name="new_password1"
                  label="New Password One"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password 
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password one"
                  />


                </Form.Item>

          
                <Form.Item
                  name="new_password2"
                  label="Confirm New Password"
                  dependencies={['new_password1']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('new_password1') === value) {
                          return Promise.resolve();
                        }
          
                        return Promise.reject('The two passwords that you entered do not match!');
                      },
                    }),
                  ]}
                >
                  <Input.Password 
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Confirm" />

                </Form.Item>

              <Form.Item >
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Confirm Change
                  </Button>
              </Form.Item>
            </Form>
                    
            </Modal>
        </div>
    )
    }
   
  }
}

export default AccountList; 
