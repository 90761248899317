import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Input,message,Spin,Select} from 'antd';
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';
const FormItem=Form.Item;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;

var token= ''

class HubDetail extends React.Component{

    state={
        name:'' ,  
        short_name:'',
        datarequested:true,
        ugdistricts:[],
        district:''
    }

    componentDidMount(){
      this.compo_reloaded()
    }

    compo_reloaded=()=>{

      this.setState({datarequested:true})

        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        axios.defaults.headers={
          "Content-Type":"application/json",
          Authorization:`Token ${token}`
        }

        const hubID=this.props.hubID;
        axios.get(`${serverconfig.backendserverurl}/api/hubs/${hubID}`)
        .then(res => {  
          
            this.setState({name:res.data.name})
            this.setState({short_name:res.data.short_name})
            this.setState({district:res.data.district})

            this.setState({datarequested:false})
        })

        axios.get(serverconfig.backendserverurl+`/api/ugdistricts/`)
        .then(res => {
            this.setState({
              ugdistricts:res.data
            })
    
            this.setState({datarequested:false})
        })


    }

  
    //submit button pressed
  handleFormSubmit=() =>{
    this.setState({datarequested:true})

    const hubID=this.props.hubID;

    let form_data = new FormData();
    form_data.append('name', this.state.name);
    form_data.append('short_name', this.state.short_name);
    form_data.append('district', this.state.district);


    axios.put(`${serverconfig.backendserverurl}/api/hubs/${hubID}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
    })
    .then(res =>{

      this.setState({datarequested:false})
      message.info('Successfully updated ID')

      this.compo_reloaded()  
      this.props.onRefresh()


    })
    .catch(error => console.log(error))
  }


    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>                 </div>
            )
      
          }else{
            return(
                <div>
                    
                    <Card 
                    style={{padding:10}}
                    hoverable>
                        <h4>Hub Update</h4>
                        <Form>
                        <FormItem label="Name"
                        >
                          <Input name="name"  placeholder="Put a name here." value={this.state.name} onChange={(val)=>{this.setState({name:val.target.value})}} />
                        </FormItem>

                        <FormItem label="Short Name" 
                        >
                          <Input name="short_name"  placeholder="short_name" value={this.state.short_name} onChange={(val)=>{this.setState({short_name:val.target.value})}} />
                        </FormItem>

                        <FormItem label="District">
                          <Select 
                          placeholder="district" 
                          value={this.state.district} 
                          onChange={(val)=>{this.setState({district:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}    
                          >
                          {this.state.ugdistricts.map(
                          (cat)=>(
                            <Option value={cat.district_name}>{cat.district_name}</Option>
                          ))}
                          </Select>
                        </FormItem>
                    
                        <FormItem>
                        <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update</Button>
                        </FormItem>
                        </Form>
    
                    </Card>
    
                </div>
            )
        }
    }
}

export default HubDetail; 