import React from 'react';
import { Form, Input, Button,Spin,Switch,Image,Select,message } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;

var token= ''
var username=''
const { TextArea } = Input;

class RestaurantDishDetails extends React.Component {

  state = {
    name:'' ,  
    description:'',
    datarequested:false,
    online:false,
    price:0,
    discount:0,

    image:null,
    image_file:'',
    date_ofcreation:moment().format(dateFormat).toString(),
    no_offreechoices:0,
    packaging_fees:0



  }


  componentDidMount(){
    
    this.component_loaded()

  }

//compoent loaded
  component_loaded=()=>{


    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

   if(localStorage.getItem("username")){
     username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
  
  }else{
     username=''
  }

   axios.defaults.headers={
     "Content-Type":"application/json",
     Authorization:`Token ${token}`
   }
  
   var dishID=this.props.dishID
   axios.get(`${serverconfig.backendserverurl}/api/restaurant_dishes/${dishID}`)
   .then(res => {  
       this.setState({image_file:res.data.image})

       this.setState({name:res.data.name})
       this.setState({description:res.data.description})
   
       this.setState({price:res.data.price})
       this.setState({online:res.data.online})
       this.setState({discount:res.data.discount})

       this.setState({date_ofcreation:res.data.date})
       this.setState({no_offreechoices:res.data.no_offreechoices})
       this.setState({packaging_fees:res.data.packaging_fees})

       this.setState({datarequested:false})

   })


  }



  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})
    var dishID=this.props.dishID

    let form_data = new FormData();
    form_data.append('name', this.state.name);
    form_data.append('description', this.state.description);
    form_data.append('price',this.state.price);
    form_data.append('online',this.state.online);
    form_data.append('discount',this.state.discount);
    form_data.append('created_by', username);
    form_data.append('date',this.state.date_ofcreation);
    form_data.append('no_offreechoices',this.state.no_offreechoices);
    form_data.append('packaging_fees', this.state.packaging_fees);

    this.state.image==null?
    console.log("No image file")
    :
    form_data.append('image', this.state.image,this.state.image?this.state.image.name:"");

    axios.put(serverconfig.backendserverurl+`/api/restaurant_dishes/${dishID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>  {
    this.setState({datarequested:false})
    this.component_loaded()
    message.info("Dish updated")

    //refresh dishes 
    this.props.onrefresh()
  }   

  )
  .catch(error => console.log(error))

  }

  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Dish Name"
      
      >
        <Input name="name" 
         placeholder="Put a name here."
         value={this.state.name} 
         onChange={(val)=>{this.setState({name:val.target.value})}} />
      </FormItem>


      <FormItem
        label="Summary"
       
      >

        <TextArea 
         name="Summary" 
         placeholder="Put a description here."
         value={this.state.description} 
         onChange={(val)=>{this.setState({description:val.target.value})}}
         autoSize={{ minRows: 2, maxRows: 6 }}
         />

      </FormItem>


      <FormItem label="Price"
      
      >
        <Input name="price" 
         placeholder="Put a price here."
         value={this.state.price} 
         onChange={(val)=>{this.setState({price:val.target.value})}} 
         type='number'
         />
      </FormItem>


      <FormItem label="New Discounted Price">
        <Input name="discount" 
         placeholder="New Discounted Price."
         value={this.state.discount} 
         onChange={(val)=>{this.setState({discount:val.target.value})}} 
         type='number'
         />
      </FormItem>


      <FormItem name="online" label="Online" >
      <Switch
          checked={this.state.online}
          onChange={val=>{this.setState({online:!this.state.online})}}
          checkedChildren="YES"
          unCheckedChildren="NO"
          />
      </FormItem>


      <div style={{display:'flex',flexDirection:'row'}}>
      <FormItem label="Image">
          <Input name="image" type="file" accept="image/png, image/jpeg" placeholder="Image" 
          
          onChange={(e) =>{
          if(e.target.files[0]){
            this.setState({
              image_file: URL.createObjectURL(e.target.files[0])
            })
          }
          this.setState({ image: e.target.files[0]})

          } }/>
      </FormItem> 

      {this.state.image!=null || this.state.image_file!=""?
        <Image
        width={50}
        height={50}
        src={this.state.image_file}    
        />
        :
        null
        }

      </div>

      <FormItem label="No Of Free Choices">
        <Input name="no_offreechoices" 
         placeholder="No Of Free Choices"
         value={this.state.no_offreechoices} 
         onChange={(val)=>{this.setState({no_offreechoices:val.target.value})}} 
         type='number'
         />
      </FormItem>


        <FormItem label="Packaging Fees">
        <Input name="packaging_fees" 
         placeholder="packaging_fees"
         value={this.state.packaging_fees} 
         onChange={(val)=>{this.setState({packaging_fees:val.target.value})}} />
        </FormItem>


      <FormItem>
        <Button  type="primary" htmlType="submit">Update</Button>
      </FormItem>
       </Form>
        </div>
      );
    }

  }

}


export default RestaurantDishDetails;



