import React from 'react';
import RiderForm from '../components/RiderForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Tabs,Switch,Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import * as serversocketconfig from "../serversocketconfig";

import RiderDetails from './RiderDetails'
import moment from 'moment';
import * as primarycolor from '../primarycolor'
import ReconnectingWebSocket from "reconnecting-websocket";


var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}
const { TabPane } = Tabs;

var token= ''
var bizuserid = "";
const dateFormat = 'DD/MM/YYYY';
var riderrefresh_socket=new ReconnectingWebSocket(serversocketconfig.socketurl+'/ws/riderrefresh_socket/');


class RiderList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    riders: [],
    datarequested:true,
    userrights:{},
    date:new Date().toLocaleDateString('en-US', { weekday: 'long' }),


  };

  componentDidMount(){

    //reset notes
      //observer the socket changes now
      riderrefresh_socket.onopen = () => {
        console.log("onopen for riderrefresh_socket on inhouseform");
      // connection opened
      };
  
      riderrefresh_socket.onmessage = async (e) => {
        this.comp_loaded()
      };


      this.comp_loaded()
  }


  comp_loaded=()=>{
   // this.setState({datarequested:true})
      
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
    }else{
      token= ''
    }

    if (localStorage.getItem("bizuserid")) {
      bizuserid = CryptoJS.AES.decrypt(
        localStorage.getItem("bizuserid"),
        "my-secret-key@1234"
      ).toString(CryptoJS.enc.Utf8);
    } else {
      bizuserid = "";
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/riders/`)
    .then(res => {
        this.setState({
            riders:res.data
        })
        this.setState({datarequested:false})

    })

    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
      console.log("data: "+res.data[0] )
        this.setState({
          userrights:res.data[0]
        })
    })

  }

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};


 isValidJSON=(str)=> {
  try {
    const parsed = JSON.parse(str);
    return Array.isArray(parsed);
  } catch (e) {
    return false;
  }
}


    //check if area already added
    isday_off = (date, list) => {
      return list.some((item) => item === date);
    };



  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'system_ID',
        key: 'id',
        ...this.getColumnSearchProps('system_ID'),
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'id',
        ...this.getColumnSearchProps('name'),
      },
     
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'id',
      },

      {
        title: 'Available',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
          <span>

          {
          this.isValidJSON(record.scheduled_offdays)===true ?
          this.isday_off(this.state.date,JSON.parse(record.scheduled_offdays))===true?

          <Tooltip title="Rider is off today " placement="top">

           {
              this.state.userrights.update_riders===true?
            <Switch
            checked={record.available}
            onChange={val=>{

              let form_data = new FormData();
              form_data.append('available',val);
              form_data.append('support_rider',record.support_rider);

              axios.put(serverconfig.backendserverurl+`/api/riders/${record.id}/`, form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
              })
            .then(res =>  {
              this.setState({datarequested:false})
              this.comp_loaded()
              message.info("Updated")
          
            }   
          
            )
            .catch(error => console.log(error))
          
            }}
            checkedChildren="Yes"
            unCheckedChildren="No"
            />:null
            }

            </Tooltip>
            
            :
            <span>
            {
            this.state.userrights.update_riders===true?
         <Switch
          checked={record.available}
          onChange={val=>{

            let form_data = new FormData();
            form_data.append('available',val);
            form_data.append('support_rider',record.support_rider);

            axios.put(serverconfig.backendserverurl+`/api/riders/${record.id}/`, form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
          .then(res =>  {
            this.setState({datarequested:false})
            this.comp_loaded()
            message.info("Updated")
        
          }   
        
          )
          .catch(error => console.log(error))
        
          }}
          checkedChildren="Yes"
          unCheckedChildren="No"
          />:null
          }

                </span>
                :
                <span>
        {
            this.state.userrights.update_riders===true?
         <Switch
          checked={record.available}
          onChange={val=>{

            let form_data = new FormData();
            form_data.append('available',val);
            form_data.append('support_rider',record.support_rider);

            axios.put(serverconfig.backendserverurl+`/api/riders/${record.id}/`, form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
          .then(res =>  {
            this.setState({datarequested:false})
            this.comp_loaded()
            message.info("Updated")
        
          }   
        
          )
          .catch(error => console.log(error))
        
          }}
          checkedChildren="Yes"
          unCheckedChildren="No"
          />:null
          }

        </span>

      }

      </span>

      },

      {
        title: 'Support Rider',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
          <span>
             {
                    this.state.userrights.update_riders===true?
        
                     <Switch
                  checked={record.support_rider}
                  onChange={val=>{
        
                    let form_data = new FormData();
                    form_data.append('available',record.available);
                    form_data.append('support_rider',val);
        
                    axios.put(serverconfig.backendserverurl+`/api/riders/${record.id}/`, form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                    })
                  .then(res =>  {
                    this.setState({datarequested:false})
                    this.comp_loaded()
                    message.info("Updated")
                
                  }   
                
                  )
                  .catch(error => console.log(error))
                
                  }}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  />
                  :null}
       
          </span>

      },

      
      {
        title: 'Assigned Hub',
        dataIndex: 'assigned_hub_name',
        key: 'id',
      },

      {
        title: 'Exclusive Restaurant',
        dataIndex: 'exclusive_restaurant_name',
        key: 'id',
      },
      {
        title: 'Off Days',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
          <p style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
           
           {
            this.isValidJSON(record.scheduled_offdays)===true?
            JSON.parse(record.scheduled_offdays)
            .map((item)=>{
              return ( <span style={{display:'flex',color:item===this.state.date?'red':primarycolor.primarycolor}}>{item}</span>)
            })
            :
            null
            }

          </p>
      },



      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>

      {
          this.state.userrights.delete_riders===true?
        <Popover content={<p>Click here to delete </p>} title="Delete">
         <Popconfirm title="Sure to delete?" onConfirm={
          () => {
            axios.delete(`${serverconfig.backendserverurl}/api/riders/${text}`).then(res =>{
              this.comp_loaded()
              message.info("successfully deleted") 
            }
            );
          }
        }>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>:null}
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>      
         </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="RIDERS" key="1">
            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.riders}
            bordered
            
            expandable={{
              expandedRowRender: (record) =>{
                  return (
                    <Tabs defaultActiveKey="1" >
                    
                    {
                      this.state.userrights.update_riders===true?
                    <TabPane tab="Rider details" key="1" >
                      <RiderDetails  onRefresh={this.comp_loaded} riderID={record.id} />
                    </TabPane>
                    :null
                    }


                    </Tabs>
                   );
              } ,
              rowExpandable: (record) => true,
              onExpand:(condition,record)=>{
              },
            }}
            size='small'

       
            />

             </Panel>
             </Collapse>
            <br />
            <br />

            {
              this.state.userrights.create_riders===true?
              <Collapse defaultActiveKey={['0']} onChange={callback}>
              <Panel header="Create New Rider" key="1">
                <RiderForm onRefresh={this.comp_loaded} /> 
              </Panel>
              </Collapse>
            :null
            }

        </div>
    )

    }

   
  }
}

export default RiderList; 
