import React from 'react';
import { Form, Input, Button,Spin,Image,Switch,Select, message } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;

var token= ''
var username=''


class RestaurantForm extends React.Component {

  state = {
    name:'' ,  
    about:'',
    datarequested:false,
    ugdistricts:[],
    district:'',
    category:'',
    restaurant_categories:[],

    logo:null,
    logo_file:'',
    email:'',
    phone:'',

    area:'',
    hub:'',

    hubs:[],
    areas:[],

    address:'',
    map_url:'',

    opening_time:0,
    closing_time:0,

    phoneorders_on:false,
    date_ofcreation:moment().format(dateFormat).toString(),

    service_fee_perorder:0,
    weekly_subscription:0,
    rider_management_fee:0,

    restaurant_banner:null,
    restaurant_banner_file:'',

    mtn_momopay_code:'',
    airtelpay_code:''

  }


  componentDidMount(){
    this.comp_loaded()
  
  }


  comp_loaded=()=>{

    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

   if(localStorage.getItem("username")){
     username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
  
  }else{
     username=''
  }

   axios.defaults.headers={
     "Content-Type":"application/json",
     Authorization:`Token ${token}`
   }

   axios.get(serverconfig.backendserverurl+`/api/ugdistricts/`)
   .then(res => {
       this.setState({
         ugdistricts:res.data
       })
      // this.setState({datarequested:false})
   })

   axios.get(serverconfig.backendserverurl+`/api/areas/`)
   .then(res => {
       this.setState({
         areas:res.data
       })
   })

   axios.get(serverconfig.backendserverurl+`/api/hubs/`)
   .then(res => {
       this.setState({
         hubs:res.data
       })
   })

   axios.get(serverconfig.backendserverurl+`/api/restaurant_categories/`)
   .then(res => {
       this.setState({
         restaurant_categories:res.data
       })
       this.setState({datarequested:false})
   })


  }


  //submit button pressed
  handleFormSubmit=(event) =>{

    if (this.state.phone===""){
      message.info("Please enter contact")
    }else{
        this.setState({datarequested:true})

        let form_data = new FormData();
        form_data.append('name', this.state.name);
        form_data.append('about', this.state.about);
        form_data.append('district', this.state.district);
        form_data.append('date_ofcreation',this.state.date_ofcreation);
        form_data.append('category', this.state.category);

        form_data.append('email', this.state.email);
        form_data.append('phone', this.state.phone);

        form_data.append('area', this.state.area);
        form_data.append('hub', this.state.hub);
        form_data.append('address', this.state.address);
        form_data.append('map_url', this.state.map_url);
        form_data.append('created_by', username);

        form_data.append('phoneorders_on', this.state.phoneorders_on);

        form_data.append('closing_time', this.state.closing_time);
        form_data.append('opening_time', this.state.opening_time);

        form_data.append('service_fee_perorder', this.state.service_fee_perorder);
        form_data.append('weekly_subscription', this.state.weekly_subscription);
        form_data.append('rider_management_fee', this.state.rider_management_fee);


        this.state.logo==null?
        console.log("No logo file")
        :
        form_data.append('logo', this.state.logo,this.state.logo?this.state.logo.name:"");

        this.state.restaurant_banner==null?
        console.log("No restaurant_banner file")
        :
        form_data.append('restaurant_banner', this.state.restaurant_banner,this.state.restaurant_banner?this.state.restaurant_banner.name:"");

        form_data.append('mtn_momopay_code', this.state.mtn_momopay_code);
        form_data.append('airtelpay_code', this.state.airtelpay_code);


        axios.post(serverconfig.backendserverurl+'/api/restaurants/', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(res =>{
          message.info("Success")
          this.setState({phone:''})
          this.setState({datarequested:false})

          this.comp_loaded()

          //reload parent
           this.props.onrefresh()


        }
        )
        .catch(error => console.log(error))

    }


  }
  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Restaurant Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="name" 
         placeholder="Put a name here."
         value={this.state.name} 
         onChange={(val)=>{this.setState({name:val.target.value})}} />
      </FormItem>


      <FormItem label="About"
        name="about"
        rules={[
          {
            required: true,
            message: 'Please input about',
          },
        ]}
      >
        <TextArea 
         name="about" 
         placeholder="Put a about here."
         value={this.state.about} 
         onChange={(val)=>{this.setState({about:val.target.value})}}
         autoSize={{ minRows: 2, maxRows: 6 }}
         />
      </FormItem>


      <FormItem label="Category"
      name="category"
      rules={[
        {
          required: true,
          message: 'Please select',
        },
      ]}
      >
            <Select 
            placeholder="category" 
            value={this.state.category} 
            onChange={(val)=>{this.setState({category:val})}} 
            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}    
            >
            {this.state.restaurant_categories.map(
            (cat)=>(
              <Option value={cat.category_name}>{cat.category_name}</Option>
            ))}
            </Select>
      </FormItem>


      <div style={{display:'flex',flexDirection:'row'}}>

      <FormItem label="Logo">
          <Input name="companylogo" type="file" accept="image/png, image/jpeg" placeholder="Company logo" 
          
          onChange={(e) =>{
          if(e.target.files[0]){
            this.setState({
              logo_file: URL.createObjectURL(e.target.files[0])
            })
          }
          this.setState({ logo: e.target.files[0]})

          } }/>
      </FormItem> 

      {this.state.logo!=null || this.state.logo_file!=""?
        <Image
        width={50}
        height={50}
        src={this.state.logo_file}    
        />
        :
        null
        }

      </div>


      <div style={{display:'flex',flexDirection:'row'}}>

        <FormItem label="Banner">
            <Input name="banner" type="file" accept="image/png, image/jpeg" placeholder="Banner" 
            
            onChange={(e) =>{
            if(e.target.files[0]){
              this.setState({
                restaurant_banner_file: URL.createObjectURL(e.target.files[0])
              })
            }
            this.setState({ restaurant_banner: e.target.files[0]})

            } }/>
        </FormItem> 

        {this.state.restaurant_banner!=null || this.state.restaurant_banner_file!=""?
          <Image
          width={50}
          height={50}
          src={this.state.restaurant_banner_file}    
          />
          :
          null
          }

        </div>


      <FormItem
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input email',
          },
        ]}
      
      label="Company Email">
          <Input name="companyemail" type="email"  placeholder="company@email.com" value={this.state.email} onChange={(val)=>{this.setState({email:val.target.value})}} />
      </FormItem> 


      <FormItem label="Phone">
          <PhoneInput
            countrySelectProps={{ unicodeFlags: true }}
            defaultCountry="UG"
            placeholder="Enter Phone "
            value={this.state.phone} onChange={(val)=>{this.setState({phone:val})}}/>
      </FormItem> 


      <FormItem label="Area"
      
      name="area"
      rules={[
        {
          required: true,
          message: 'Please select',
        },
      ]}
      
      >
            <Select 
            placeholder="Area" 
            value={this.state.area} 
            onChange={(val)=>{this.setState({area:val})}} 
            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}    
            >
            {this.state.areas.map(
            (cat)=>(
              <Option value={cat.id}>{cat.name}</Option>
            ))}
            </Select>
      </FormItem>


      <FormItem label="Hub"
      
      name="hub"
      rules={[
        {
          required: true,
          message: 'Please select',
        },
      ]}
      
      >
            <Select 
            placeholder="Hub" 
            value={this.state.hub} 
            onChange={(val)=>{this.setState({hub:val})}} 
            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}    
            >
            {this.state.hubs.map(
            (cat)=>(
              <Option value={cat.id}>{cat.name}</Option>
            ))}
            </Select>
      </FormItem>


      <FormItem 
        label="District"
        name="district"
        rules={[
          {
            required: true,
            message: 'Please select',
          },
        ]}
      >
            <Select 
            placeholder="district" 
            value={this.state.district} 
            onChange={(val)=>{this.setState({district:val})}} 
            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}    
            >
            {this.state.ugdistricts.map(
            (cat)=>(
              <Option value={cat.district_name}>{cat.district_name}</Option>
            ))}
            </Select>
      </FormItem>

      <FormItem label="Google Address"
        name="address"
        rules={[
          {
            required: false,
            message: 'Please input address',
          },
        ]}
      >
        <Input name="address" 
         placeholder="Put a address here."
         value={this.state.address} 
         onChange={(val)=>{this.setState({address:val.target.value})}} />
      </FormItem>


      <FormItem label="Google Map Link"
        name="map_url"
        rules={[
          {
            required: false,
            message: 'Please input map_url',
          },
        ]}
      >
        <Input name="map_url" 
         placeholder="Google Map Link."
         value={this.state.map_url} 
         onChange={(val)=>{this.setState({map_url:val.target.value})}} />
      </FormItem>


      <FormItem 
        label="Opening Time"
        name="opening_time"
        rules={[
          {
            required: true,
            message: 'Please select',
          },
        ]}
      >
            <Select 
            placeholder="Opening Time" 
            value={this.state.opening_time} 
            onChange={(val)=>{this.setState({opening_time:val})}} 
            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}    
            >
              <Option value={0}> 12:00 AM (Mid night)</Option>
              <Option value={1}>1:00 AM</Option>
              <Option value={2}>2:00 AM</Option>
              <Option value={3}>3:00 AM</Option>
              <Option value={4}>4:00 AM</Option>
              <Option value={5}>5:00 AM</Option>
              <Option value={6}>6:00 AM</Option>
              <Option value={7}>7:00 AM
</Option>
              <Option value={8}>8:00 AM
</Option>
              <Option value={9}>9:00 AM
</Option>
              <Option value={10}>10:00 AM
</Option>
              <Option value={11}>11:00 AM
</Option>
              <Option value={12}>12:00 PM (Noon)
</Option>
              <Option value={13}>1:00 PM
</Option>
              <Option value={14}>2:00 PM
</Option>
              <Option value={15}>3:00 PM
</Option>
              <Option value={16}>4:00 PM

</Option>
              <Option value={17}>5:00 PM
</Option>
              <Option value={18}>6:00 PM
</Option>
              <Option value={19}>7:00 PM</Option>
              <Option value={20}>8:00 PM</Option>
              <Option value={21}>9:00 PM </Option>
              <Option value={22}>10:00 PM</Option>
              <Option value={23}>11:00 PM</Option>

            </Select>
      </FormItem>


      <FormItem 
        label="Closing Time"
        name="closing_time"
        rules={[
          {
            required: true,
            message: 'Please select',
          },
        ]}
      >
            <Select 
            placeholder="Closing Time" 
            value={this.state.closing_time} 
            onChange={(val)=>{this.setState({closing_time:val})}} 
            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}    
            >
              <Option value={0}> 12:00 AM (Mid night)</Option>
              <Option value={1}>1:00 AM</Option>
              <Option value={2}>2:00 AM</Option>
              <Option value={3}>3:00 AM</Option>
              <Option value={4}>4:00 AM</Option>
              <Option value={5}>5:00 AM</Option>
              <Option value={6}>6:00 AM</Option>
              <Option value={7}>7:00 AM
</Option>
              <Option value={8}>8:00 AM
</Option>
              <Option value={9}>9:00 AM
</Option>
              <Option value={10}>10:00 AM
</Option>
              <Option value={11}>11:00 AM
</Option>
              <Option value={12}>12:00 PM (Noon)
</Option>
              <Option value={13}>1:00 PM
</Option>
              <Option value={14}>2:00 PM
</Option>
              <Option value={15}>3:00 PM
</Option>
              <Option value={16}>4:00 PM

</Option>
              <Option value={17}>5:00 PM
</Option>
              <Option value={18}>6:00 PM
</Option>
              <Option value={19}>7:00 PM</Option>
              <Option value={20}>8:00 PM</Option>
              <Option value={21}>9:00 PM </Option>
              <Option value={22}>10:00 PM</Option>
              <Option value={23}>11:00 PM</Option>

            </Select>
      </FormItem>


      <FormItem name="phoneorders_on" label="Phone Orders On" >
              <Switch
                  checked={this.state.phoneorders_on}
                  onChange={val=>{this.setState({phoneorders_on:!this.state.phoneorders_on})}}
                  checkedChildren="YES"
                  unCheckedChildren="NO"
                  />
      </FormItem>

        
     
        <h3>Charges section</h3>
        <FormItem label="ServiceFee PerOrder">
        <Input name="service_fee_perorder" 
         placeholder="service_fee_perorder"
         value={this.state.service_fee_perorder} 
         onChange={(val)=>{this.setState({service_fee_perorder:val.target.value})}} />
        </FormItem>

        <FormItem label="Weekly Subscription">
        <Input name="weekly_subscription" 
         placeholder="weekly_subscription"
         value={this.state.weekly_subscription} 
         onChange={(val)=>{this.setState({weekly_subscription:val.target.value})}} />
        </FormItem>

        <FormItem label="Rider Management Fee">
        <Input name="rider_management_fee" 
         placeholder="rider_management_fee"
         value={this.state.rider_management_fee} 
         onChange={(val)=>{this.setState({rider_management_fee:val.target.value})}} />
        </FormItem>

        <h3>Payments</h3>

        <FormItem label="Mtn MomoPay Code">
        <Input name="mtn_momopay_code" 
         placeholder="mtn_momopay_code"
         value={this.state.mtn_momopay_code} 
         onChange={(val)=>{this.setState({mtn_momopay_code:val.target.value})}} />
        </FormItem>


        <FormItem label="AirtelPay code">

        <Input name="airtelpay_code" 
         placeholder="airtelpay_code"
         value={this.state.airtelpay_code} 
         onChange={(val)=>{this.setState({airtelpay_code:val.target.value})}} />
        </FormItem>


      <FormItem>
        <Button  type="primary" htmlType="submit">Create</Button>
      </FormItem>
       </Form>
        </div>
      );
    }

  }

}


export default RestaurantForm;



