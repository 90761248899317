import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Input,message,Spin,Select,Table,Popover,Popconfirm} from 'antd';
import { UserOutlined,LoadingOutlined,DeleteOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as primarycolor from '../primarycolor'

  var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;

var token= ''
var username=''

class HubZoneDetails extends React.Component{

    state={
        price:0,
        datarequested:true,
        areas:[],
        district:'',
        zones:[],
        selected_area:'',
    }

     //check string
     isString = (value) => {
      return typeof value === 'string' || value instanceof String;
    };

    componentDidMount(){
      this.compo_reloaded()
    }


    //capitalize fistrt letter
    capitalizeFirstLetter=(word)=> {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }




    compo_reloaded=()=>{
      this.setState({datarequested:true})


      if(localStorage.getItem("username")){
        username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
     
     }else{
        username=''
     }

        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        axios.defaults.headers={
          "Content-Type":"application/json",
          Authorization:`Token ${token}`
        }

        var stringArray=this.props.zone_obj.zone_list

        this.setState({zones:JSON.parse(stringArray)})

        this.setState({price:this.props.zone_obj.price})

        axios.get(serverconfig.backendserverurl+`/api/areas/`)
        .then(res => {
            this.setState({
              areas:res.data
            })
    
            this.setState({datarequested:false})
        })



    }


    //check if area already added
    isareaAlreadyExists = (area, list) => {
      return list.some((item) => item === area);
    };


    
    //submit button pressed
  handleFormSubmit=() =>{
    this.setState({datarequested:true})
    const zoneID=this.props.zone_obj.id;

    let form_data = new FormData();
    form_data.append('zone_list', JSON.stringify(this.state.zones));
    form_data.append('user', username);
    form_data.append('price', this.state.price);

    axios.put(`${serverconfig.backendserverurl}/api/hub_zones/${zoneID}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
    })
    .then(res =>{
      this.setState({datarequested:false})
      message.info('Successfully updated')

      this.compo_reloaded()

      this.props.onRefresh()
    

    })  
    .catch(error => console.log(error))
  }


    render(){


        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>          
               </div>
            )
      
          }else{
            return(
                <div>
                    
                    <Card 
                    style={{padding:10}}
                    hoverable>

                        <h4>Update Zone</h4>
                        <Form>
                       
                        <FormItem label="Price" >
                          <Input name="price"
                           type='number' 
                           placeholder="Price" 
                           value={this.state.price} 
                           onChange={(val)=>{this.setState({price:val.target.value})}} />
                        </FormItem>



                      {/**Created selected areas list */}
                      {
                          this.state.areas.length>0?
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignSelf: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                        
                            {
                                this.state.areas.map((ar)=>{
                                  return (
                                    <span style={{display:'flex',
                                    justifyContent:'center',
                                    width:200,
                                    border: `1px solid ${primarycolor.primarycolor}`,
                                    margin:1,
                                    backgroundColor:this.isareaAlreadyExists(ar.name,this.state.zones)===true?'yellow':'#fff'

                                    }}

                                    onClick={()=>{
                                      if (this.isareaAlreadyExists(ar.name,this.state.zones)===true){
                                        this.setState({ zones:  [...this.state.zones.filter(todo => todo
                                          !==ar.name)]});
                                        
                                        message.info("Removed")
                                      }else{
                                        this.setState({zones: [...this.state.zones,ar.name]});
                                        message.info("Added")
                                      }

                                    }}
                                    >
                                      {this.capitalizeFirstLetter(ar.name)}

                                    </span>

                                  )
                                })
                          }

                          </div>

                        :
                        null
                        }









                          <br></br>
                          <br></br>

                          <div style={{display:'flex',justifyContent:'flex-end',alignItems:'flex-end',alignSelf:'flex-end'}}>
                          <FormItem >
                          <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update Zone</Button>
                          </FormItem>

                          </div>
                        </Form>
    
                    </Card>
    
                </div>
            )
        }
    }
}

export default HubZoneDetails; 