import React from 'react';
import { Form, Input, Button,Spin,Select } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;


var token= ''
var sacco= ''


class CurrencyConverterForm extends React.Component {

  state = {
    currency_name:'' ,  
    description:'',
    datarequested:false,
    currency_one:'',
    currency_one_value:0,
    currency_two:'',
    currency_two_value:0,
    currencies:[]

  }


  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/currencies/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            currencies:res.data
        })
        this.setState({datarequested:false})

    })

  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('currency_one', this.state.currency_one);
    form_data.append('currency_two', this.state.currency_two);
    form_data.append('currency_one_value', this.state.currency_one_value);
    form_data.append('currency_two_value', this.state.currency_two_value);

      axios.post(serverconfig.backendserverurl+'/api/currency_comparisons/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))
  }

  

  handlecurrency_nameChange= (e) => this.setState({ currency_name: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ description: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
    
    <FormItem label="Currency One"
          name="currency_one"
          rules={[
            {
              required: true,
              message: 'Please select currency',
            },
          ]}           
        >
            <Select  name="currency" placeholder="Currency"  value={this.state.currency_one} onChange={(val)=>{this.setState({currency_one:val})}} >
       
             {
              this.state.currencies.map((item)=>{
                return (<Option  value={item.currency_name}>{item.currency_name}</Option>)
              })
             }

            </Select>
        </FormItem>

        <FormItem label="Currency One Value"
        name="currency_one_value"
        rules={[
          {
            required: true,
            message: 'Please input value',
          },
        ]}    
      >
        <Input name="currency_one" type='number'  placeholder="Put value here" value={this.state.currency_one_value} onChange={(val)=>{this.setState({currency_one_value:val.target.value})}} />
      </FormItem>

        {/*currency two*/}

        <FormItem label="Currency Two"
          name="currency_two"
          rules={[
            {
              required: true,
              message: 'Please select currency',
            },
          ]}           
        >
            <Select  name="currency" placeholder="Currency"  value={this.state.currency_two} onChange={(val)=>{this.setState({currency_two:val})}} >
       
             {
              this.state.currencies.map((item)=>{
                return (<Option  value={item.currency_name}>{item.currency_name}</Option>)
              })
             }

            </Select>
        </FormItem>

        <FormItem label="Currency Two Value"
        name="currency_two_value"
        rules={[
          {
            required: true,
            message: 'Please input value',
          },
        ]}    
      >
        <Input name="currency_two" type='number'  placeholder="Put value here" value={this.state.currency_two_value} onChange={(val)=>{this.setState({currency_two_value:val.target.value})}} />
      </FormItem>


      <FormItem>
        <Button  type="primary" htmlType="submit">{this.props.btnText}</Button>
      </FormItem>
    </Form>
        </div>
      );

    }

   
  }

}


export default CurrencyConverterForm;



