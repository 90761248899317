import React from 'react';
import { Form, Input, Button,Spin,Image,Switch,Select, message } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;

var token= ''
var username=''


class RiderForm extends React.Component {

  state = {
    name:'' ,  
    about:'',
    datarequested:false,
    restaurants:[],
    exclusive_restaurant:'',
    category:'',

    logo:null,
    logo_file:'',
    email:'',
    phone:'',

    area:'',
    assigned_hub:'',

    hubs:[],

    address:'',
    map_url:'',

    opening_time:0,
    closing_time:0,

    available:false,
    pickup_on:false,
    date_ofcreation:moment().format(dateFormat).toString()

  }


  componentDidMount(){
    this.comp_reloaded()
  }



  comp_reloaded=()=>{

    this.setState({datarequested:true})

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("username")){
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
   }else{
      username=''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/restaurants/`)
    .then(res => {
        this.setState({
          restaurants:res.data
        })
       // this.setState({datarequested:false})
    })

   
    axios.get(serverconfig.backendserverurl+`/api/hubs/`)
    .then(res => {
        this.setState({
          hubs:res.data
        })
        this.setState({datarequested:false})

    })

  }



  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    if (this.state.phone===""){
      message.info("Please enter contact")
    }else{

        let form_data = new FormData();
        form_data.append('name', this.state.name);

        if (this.state.exclusive_restaurant!=""){
          form_data.append('exclusive_restaurant', this.state.exclusive_restaurant);
        }

        form_data.append('date_created',this.state.date_ofcreation);
        form_data.append('phone', this.state.phone);
        form_data.append('created_by', username);
        form_data.append('available', this.state.available);

        if (this.state.assigned_hub!=""){
          form_data.append('assigned_hub', this.state.assigned_hub);
        }

        axios.post(serverconfig.backendserverurl+'/api/riders/', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(res =>{
          this.setState({datarequested:false})
          this.comp_reloaded()

          //refresh
          this.props.onRefresh()

        }     
      
        )
        .catch(error => console.log(error))

    }


  }
  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Rider Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="name" 
         placeholder="Put a name here."
         value={this.state.name} 
         onChange={(val)=>{this.setState({name:val.target.value})}} />
      </FormItem>



      <FormItem label="Phone">
          <PhoneInput
            countrySelectProps={{ unicodeFlags: true }}
            defaultCountry="UG"
            placeholder="Enter Phone"
            value={this.state.phone} onChange={(val)=>{this.setState({phone:val})}}/>
      </FormItem> 


      <FormItem label="Assigned Hub"
        name="assigned_hub"
        rules={[
          {
            required: false,
            message: 'Please select',
          },
        ]}
      
      >
            <Select 
            placeholder="assigned_hub" 
            value={this.state.assigned_hub} 
            onChange={(val)=>{this.setState({assigned_hub:val})}} 
            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}    
            >
            {this.state.hubs.map(
            (cat)=>(
              <Option value={cat.id}>{cat.name}</Option>
            ))}
            </Select>
      </FormItem>


      <FormItem 
        label="Exclusive Restaurant"
        name="exclusive_restaurant"
        rules={[
          {
            required: false,
            message: 'Please select',
          },
        ]}
      >
            <Select 
            placeholder="Exclusive Restaurant" 
            value={this.state.exclusive_restaurant} 
            onChange={(val)=>{this.setState({exclusive_restaurant:val})}} 
            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}    
            >
            {this.state.restaurants.map(
            (cat)=>(
              <Option value={cat.id}>{cat.name}</Option>
            ))}
            </Select>
      </FormItem>

      <FormItem name="available" label="Available" >
              <Switch
                  checked={this.state.available}
                  onChange={val=>{this.setState({available:!this.state.available})}}
                  checkedChildren="YES"
                  unCheckedChildren="NO"
                  />
      </FormItem>

      <FormItem>
        <Button  type="primary" htmlType="submit">Create</Button>
      </FormItem>
       </Form>
        </div>
      );
    }

  }

}


export default RiderForm;



