import React,{ useRef } from 'react';
import axios from 'axios'
import { Table, Input,Card, Button,Collapse,Tooltip,Spin,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined,DownloadOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
import '../tablestyle.css'; // Create this CSS file for custom styles
import ReactExport from "@ibrahimrahmani/react-export-excel";
import LedgerAccountDetails from "./LedgerAccountDetails";

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { TabPane } = Tabs;


const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


var token= ''
var sacco= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{}
    };
  
    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })
  
    }


    calculateTotalBalance=(list)=>{
      var total =0
      list.map((item)=>{
        total+=Number(item.balance)
      })
      return total.toFixed(2);
    }
    

    render() {
      return (
        <div style={{padding:20}}>
        <Row> 
                <Col xs="2" sm="2" lg="2">
                  <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.system_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                
                  </Col>

       </Row>

           <h2 style={{justifyContent:'center',display:'flex',alignSelf:'center'}}>{"BALANCE SHEET REPORT FROM "+this.props.dateone+" TO "+this.props.datetwo }</h2>
            <br></br>
            <h4 style={{justifyContent:'center',display:'flex',alignSelf:'center',color:'blue'}}>ASSETS</h4>
           
            {
              this.props.balancesheet_report_assets.map((catgry)=>{
                return (
                  <div>
                    <h3>{catgry.chatofaccounts_subcategory}</h3>

                    {
                      catgry.report.length>0?
                      <div>
                        <reactstrp.Table bordered>
                          <thead>
                            <tr>
                              <th>Account</th>
                              <th>Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              catgry.report.map((item)=>(
                                <tr>
                                <td>{item.account}</td>
                                <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                                </tr>
                              ))
                            }

                            <tr>
                            <td>Total</td>
                            <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalBalance(catgry.report)} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>

                            </tbody>
                           </reactstrp.Table> 
                      </div>
                      :
                      null
                    }
                  </div>
                );

              })
            }

           <reactstrp.Table bordered>
              <thead>
                <tr>
                  <th><h1 style={{color:'blue'}}>TOTAL ASSETS</h1></th>
                  <th className="align-right"><h1 style={{color:'blue',fontWeight:'bolder'}}><CurrencyFormat value={this.props.AllTotals.total_balances_asset} displayType={'text'} thousandSeparator={true}/></h1></th>
                </tr>
              </thead>
          </reactstrp.Table>


          <h4 style={{justifyContent:'center',display:'flex',alignSelf:'center',color:'blue'}}>FINANCED BY</h4>
          <h5 style={{justifyContent:'center',display:'flex',alignSelf:'center'}}>Equity</h5>
          {
              this.props.balancesheet_report_equity.map((catgry)=>{
                return (
                  <div>
                    <h3>{catgry.chatofaccounts_subcategory}</h3>

                    {
                      catgry.report.length>0?
                      <div>
                        <reactstrp.Table bordered>
                          <thead>
                            <tr>
                              <th>Account</th>
                              <th>Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              catgry.report.map((item)=>(
                                <tr>
                                <td>{item.account}</td>
                                <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                                </tr>
                              ))
                            }

                            <tr>
                            <td>Total</td>
                            <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalBalance(catgry.report)} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>

                            </tbody>
                           </reactstrp.Table> 
                      </div>
                      :
                      null
                    }
                  </div>
                );

              })
            }
         <reactstrp.Table bordered>
              <thead>
                <tr>
                  <th><h2 style={{color:'green'}}>TOTAL EQUITY</h2></th>
                  <th className="align-right"><h2 style={{color:'green',fontWeight:'bolder'}}><CurrencyFormat value={this.props.AllTotals.total_balances_equity} displayType={'text'} thousandSeparator={true}/></h2></th>
                </tr>
              </thead>
          </reactstrp.Table>

          <h5 style={{justifyContent:'center',display:'flex',alignSelf:'center'}}>Liability</h5>
          {
              this.props.balancesheet_report_liability.map((catgry)=>{
                return (
                  <div>
                    <h3>{catgry.chatofaccounts_subcategory}</h3>

                    {
                      catgry.report.length>0?
                      <div>
                        <reactstrp.Table bordered>
                          <thead>
                            <tr>
                              <th>Account</th>
                              <th>Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              catgry.report.map((item)=>(
                                <tr>
                                <td>{item.account}</td>
                                <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                                </tr>
                              ))
                            }

                            <tr>
                            <td>Total</td>
                            <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalBalance(catgry.report)} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>

                            </tbody>
                           </reactstrp.Table> 
                      </div>
                      :
                      null
                    }
                  </div>
                );

              })
            }
         <reactstrp.Table bordered>
              <thead>
                <tr>
                  <th><h2 style={{color:'green'}}>TOTAL LIABILITY</h2></th>
                  <th className="align-right"><h2 style={{color:'green',fontWeight:'bolder'}}><CurrencyFormat value={this.props.AllTotals.total_balances_liability} displayType={'text'} thousandSeparator={true}/></h2></th>
                </tr>
              </thead>
          </reactstrp.Table>
          <br></br>


          <reactstrp.Table bordered>
              <thead>
                <tr>
                  <th><h1 style={{color:'blue'}}>TOTAL EQUITY & LIABILITY</h1></th>
                  <th className="align-right"><h1 style={{color:'blue',fontWeight:'bolder'}}><CurrencyFormat value={this.props.AllTotals.total_balances_equity+this.props.AllTotals.total_balances_liability} displayType={'text'} thousandSeparator={true}/></h1></th>
                </tr>
              </thead>
          </reactstrp.Table>
            
        </div>
      );
    }
  }


class BalanceSheetDateRangeView extends React.Component {

  state = {
   datarequested:true,
   dateone:moment().format(dateFormat).toString(),
   datetwo:moment().format(dateFormat).toString(),

   balancesheet_report_assets:[],

   balancesheet_report_liability:[],
   balancesheet_report_equity:[],
   AllTotals:{},

   ledeger_details_model_visible:false,
   selected_record:{}


  };


  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        sacco= ''
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    //get balance sheet
    let form_data = new FormData();
    form_data.append('dateone', this.state.dateone);
    form_data.append('datetwo', this.state.datetwo);
    form_data.append('sacco', sacco);
    //Now submit sale data to database
    axios.post(serverconfig.backendserverurl+'/customqueries/get_balancesheet_daterange', form_data,{
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
    .then(res =>{
      this.setState({balancesheet_report_assets:JSON.parse(res.data.balancesheet_report_assets)})
      this.setState({balancesheet_report_liability:JSON.parse(res.data.balancesheet_report_liability)})
      this.setState({balancesheet_report_equity:JSON.parse(res.data.balancesheet_report_equity)})

      this.setState({AllTotals:res.data.AllTotals})

      this.setState({datarequested:false})

    }).catch(error => console.log(error)) 
    
    //datarequested
    //this.setState({datarequested:false})

  
}


calculateTotalBalance=(list)=>{
  var total =0
  list.map((item)=>{
    total+=Number(item.balance)
  })
  return total.toFixed(2);
}


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {
    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
         <div style={{display: "none"}}>
             &nbsp;&nbsp;
            <ReportToPrint
            dateone={this.state.dateone}
            datetwo={this.state.datetwo}

            balancesheet_report_assets={this.state.balancesheet_report_assets}
            balancesheet_report_equity={this.state.balancesheet_report_equity}
            balancesheet_report_liability={this.state.balancesheet_report_liability}
            AllTotals={this.state.AllTotals}
            ref={el => (this.componentRef = el)} /> 

          </div>

          <div style={{overflowX:'auto'}}>
              
          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={"BALANCE SHEET REPORT FROM "+this.state.dateone+" TO "+this.state.datetwo} key="1">
 
            <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start',flexDirection:'row'}}>
            <ReactToPrint
            trigger={() => 
            <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
            Print
            </Button>
            }
            content={() => this.componentRef}
            />

          &nbsp;&nbsp;
          <Form layout="inline">
          <FormItem label="Date">
              <RangePicker onChange={
              (date, dateString) =>{
                this.setState({ dateone: dateString[0]});
                this.setState({ datetwo: dateString[1]});
              }
            } format={dateFormat} />

          </FormItem>


          <FormItem>
                <Button
                 onClick={()=>{
                  this.setState({datarequested:true})
                  //get balance sheet
                  let form_data = new FormData();
                  form_data.append('dateone', this.state.dateone);
                  form_data.append('datetwo', this.state.datetwo);

                  form_data.append('sacco', sacco);
                  //Now submit sale data to database
                  axios.post(serverconfig.backendserverurl+'/customqueries/get_balancesheet_daterange', form_data,{
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                  })
                  .then(res =>{
                    this.setState({balancesheet_report_assets:JSON.parse(res.data.balancesheet_report_assets)})
                    this.setState({balancesheet_report_liability:JSON.parse(res.data.balancesheet_report_liability)})
                    this.setState({balancesheet_report_equity:JSON.parse(res.data.balancesheet_report_equity)})

                    this.setState({AllTotals:res.data.AllTotals})

                    this.setState({datarequested:false})

                  }).catch(error => console.log(error))   

                }} type="primary" htmlType="button">Search</Button>
                </FormItem> 

          </Form>

            </div>

            <br></br>
            <h4 style={{justifyContent:'center',display:'flex',alignSelf:'center',color:'blue'}}>ASSETS</h4>
           
            {
              this.state.balancesheet_report_assets.map((catgry)=>{
                return (
                  <div>
                    <h3>{catgry.chatofaccounts_subcategory}</h3>

                    {
                      catgry.report.length>0?
                      <div>
                        <reactstrp.Table bordered>
                          <thead>
                            <tr>
                              <th>Account</th>
                              <th>Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              catgry.report.map((item)=>(
                                <Tooltip title="Click to view details" placement="top">
                                <tr onClick={()=>{
                                  this.setState({selected_record:item})
                                  this.setState({ledeger_details_model_visible:true})
                                  }}>
                                <td>{item.account}</td>
                                <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                                </tr>
                                </Tooltip>

                              ))
                            }

                            <tr>
                            <td>Total</td>
                            <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalBalance(catgry.report)} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>

                            </tbody>
                           </reactstrp.Table> 
                      </div>
                      :
                      null
                    }
                  </div>
                );

              })
            }

           <reactstrp.Table bordered>
              <thead>
                <tr>
                  <th><h1 style={{color:'blue'}}>TOTAL ASSETS</h1></th>
                  <th className="align-right"><h1 style={{color:'blue',fontWeight:'bolder'}}><CurrencyFormat value={this.state.AllTotals.total_balances_asset} displayType={'text'} thousandSeparator={true}/></h1></th>
                </tr>
              </thead>
          </reactstrp.Table>


          <h4 style={{justifyContent:'center',display:'flex',alignSelf:'center',color:'blue'}}>FINANCED BY</h4>
          <h5 style={{justifyContent:'center',display:'flex',alignSelf:'center'}}>Equity</h5>
          {
              this.state.balancesheet_report_equity.map((catgry)=>{
                return (
                  <div>
                    <h3>{catgry.chatofaccounts_subcategory}</h3>

                    {
                      catgry.report.length>0?
                      <div>
                        <reactstrp.Table bordered>
                          <thead>
                            <tr>
                              <th>Account</th>
                              <th>Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              catgry.report.map((item)=>(
                                <Tooltip title="Click to view details" placement="top">
                                <tr onClick={()=>{
                                  this.setState({selected_record:item})
                                  this.setState({ledeger_details_model_visible:true})
                                  }}>
                                                                    <td>{item.account}</td>
                                <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                                </tr>
                                </Tooltip>
                              ))
                            }

                            <tr>
                            <td>Total</td>
                            <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalBalance(catgry.report)} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>

                            </tbody>
                           </reactstrp.Table> 
                      </div>
                      :
                      null
                    }
                  </div>
                );

              })
            }
         <reactstrp.Table bordered>
              <thead>
                <tr>
                  <th><h2 style={{color:'green'}}>TOTAL EQUITY</h2></th>
                  <th className="align-right"><h2 style={{color:'green',fontWeight:'bolder'}}><CurrencyFormat value={this.state.AllTotals.total_balances_equity} displayType={'text'} thousandSeparator={true}/></h2></th>
                </tr>
              </thead>
          </reactstrp.Table>

          <h5 style={{justifyContent:'center',display:'flex',alignSelf:'center'}}>Liability</h5>
          {
              this.state.balancesheet_report_liability.map((catgry)=>{
                return (
                  <div>
                    <h3>{catgry.chatofaccounts_subcategory}</h3>

                    {
                      catgry.report.length>0?
                      <div>
                        <reactstrp.Table bordered>
                          <thead>
                            <tr>
                              <th>Account</th>
                              <th>Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              catgry.report.map((item)=>(
                                <Tooltip title="Click to view details" placement="top">
                                <tr onClick={()=>{
                                  this.setState({selected_record:item})
                                  this.setState({ledeger_details_model_visible:true})
                                  }}>
                                 <td>{item.account}</td>
                                <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                                </tr>
                                </Tooltip>
                              ))
                            }

                            <tr>
                            <td>Total</td>
                            <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalBalance(catgry.report)} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>

                            </tbody>
                           </reactstrp.Table> 
                      </div>
                      :
                      null
                    }
                  </div>
                );

              })
            }
         <reactstrp.Table bordered>
              <thead>
                <tr>
                  <th><h2 style={{color:'green'}}>TOTAL LIABILITY</h2></th>
                  <th className="align-right"><h2 style={{color:'green',fontWeight:'bolder'}}><CurrencyFormat value={this.state.AllTotals.total_balances_liability} displayType={'text'} thousandSeparator={true}/></h2></th>
                </tr>
              </thead>
          </reactstrp.Table>
          <br></br>


          <reactstrp.Table bordered>
              <thead>
                <tr>
                  <th><h1 style={{color:'blue'}}>TOTAL EQUITY & LIABILITY</h1></th>
                  <th className="align-right"><h1 style={{color:'blue',fontWeight:'bolder'}}><CurrencyFormat value={(this.state.AllTotals.total_balances_equity+this.state.AllTotals.total_balances_liability).toFixed(2)} displayType={'text'} thousandSeparator={true}/></h1></th>
                </tr>
              </thead>
          </reactstrp.Table>

          </Panel>
          </Collapse>

        </div>



        {
        this.state.ledeger_details_model_visible===true?

        <Modal    
              visible={this.state.ledeger_details_model_visible}
              title="Account Details View"
              onCancel={()=>{this.setState({ledeger_details_model_visible:false})}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({ledeger_details_model_visible:false})}}>
                  Cancel
                </Button>
                ]}
                width={1000}
                >
                    <LedgerAccountDetails
                    dateone={this.state.dateone}
                    datetwo={this.state.datetwo}
                    account={this.state.selected_record.account}
                    />
                  
            </Modal>
              :
              null
            }

        </div>

    )

    }

  }
}

export default BalanceSheetDateRangeView; 
